import React, { useState } from "react";
import { Col, Row } from 'antd';
import intl from 'react-intl-universal';
// import { userInfo } from '@/service/user';
import { STORE_USER } from "@/stores/createStores";
import { useStore } from "@/hooks/useStore";
import { observer } from 'mobx-react';
import { Button } from "antd";
import { userCenter } from '@/service/user';

import './index.less';

const Account = observer(() => {
    const user = useStore(STORE_USER);

    // const [user, setUser] = useState({
    //     email: '',
    //     membership: '',
    //     refer_link: ''
    // });

    // const fetchData = async() => {
    //     const res = await userInfo();
    //     console.log(">>", res)
    //     if (res && res.data) {
    //         setUser(res.data);
    //     }
    // }

    // fetchData();

    const gotoUserCenter = async () => {
        const res = await userCenter();
        if (res && res.data) {
            window.location.href = res.data["redirect"];
        }
    }

    return (
        <div className='account'>
           <div className='banner'>
                <div className='container'>
                    <div className='title'>
                        {intl.get("account.title")}
                    </div>                    
                    <div className='description'>
                        {user && user.membership &&
                        <Row>
                             <Col span={6}>
                                 <b>{intl.get("account.level")}:</b>                                 
                            </Col>
                             <Col>{user.membership}</Col>
                         </Row>                         
                        }

                        {user && user.email &&
                        <Row>
                            <Col span={6}><b>{intl.get("account.email")}:</b></Col>
                            <Col>{user.email}</Col>
                        </Row>                         
                        }

                        

                        {user && user.refer_link &&
                        <Row>
                             <Col span={6}><b>{intl.get("account.referlink")}:</b></Col>
                             <Col>{user.refer_link}</Col>
                         </Row>                         
                        }

                        <Button className='button' onClick={gotoUserCenter}>{intl.get('account.accountManage')}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Account