const expirationOption = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
            shadowStyle: {
                color: 'rgba(108, 74, 255, 0.15)'
            }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderWidth: '0',
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
        },
        shadowColor: 'rgba(0, 0, 0, 0.4)',
    },
    xAxis: {
        type: 'category',
        axisLine: {
            lineStyle: {
                color: 'gray',
                opacity: 0.5,
                width: 1,
            },
        },
        axisLabel: {
            textStyle: {
                color: '#99989D',
            },
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            textStyle: {
                color: '#99989D',
            },
        },
        splitLine: {
            lineStyle: {
                color: ['gray'],
                opacity: 0.2,
            },
        }
    },
    dataset: {
        dimensions: ['exp-date', 'ExpDaytotal'],
        source: [],
    },
    grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top:'5%',
        containLabel: true,
    },
    series: [
        {
            type: 'bar',
            barMaxWidth: 37,
            // label: {
            //     show: true,
            //     position: 'inside'
            // },
            itemStyle: {
                color: 'rgba(108, 74, 255, 1)',
            },
        },
    ],
};

export default expirationOption;