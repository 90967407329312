import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router";
import intl from 'react-intl-universal';
import { useStore } from "@/hooks/useStore";
import { STORE_USER } from "@/stores/createStores";
import { gotoSignIn } from "@/utils/gotoSignIn";
import './index.less';


const Content = ({ textList, img, imgEn, href, stop }) => {
    const [visible, setVisible] = useState(false);
    const navigator = useNavigate();
    const user = useStore(STORE_USER);
    const [useZh, setUseZh] = useState(!window.localStorage.language || window.localStorage.language === 'zh_CN')

    // 根据中英文改变图片
    useEffect(() => {
        if (window.localStorage.language === 'en_US') {
            setUseZh(false)
        } else {
            setUseZh(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.localStorage.language])

    return (
        <div className='content' onClick={stop}>
            <div className='content-text'>
                {textList.map((v, index) => <div key={index}>
                    {v.title && <div className='title'>
                        <span className='spot'>•</span>
                        <span>{v.title}</span>
                    </div>}

                    <div className='description' style={!v.title ? { margin: '10px 0 5px', paddingLeft: 13 } : {}}>
                        <span>{v.description}</span>
                    </div>
                </div>)}
                <Button className='button' onClick={() => { user.level ? navigator(href) : gotoSignIn() }}>{intl.get('home.products.button')}</Button>
            </div>

            <div className='content-img' onClick={() => setVisible(!visible)}>
                <img src={useZh ? img : imgEn} alt='' />
                <div className='enlarge'></div>
            </div>

            <Modal
                className='img-modal'
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}
                width={1200}
                style={{ padding: 0 }}
            >
                <img src={useZh ? img : imgEn} alt='' />
            </Modal>
        </div>
    )
}

export default Content;