const getMaxOfArray = (numArray) => {
    return Math.max.apply(null, numArray);
}

const getMinOfArray = (numArray) => {
    return Math.min.apply(null, numArray);
}

const totalOption = (list = []) => {
    const closeList = list.map(v => v.close.toFixed(2));
    const gexList = list.map(v => v.gex.toFixed(1));
    const dateList = list.map(v => v.date);
    let gexMinMax = [];

    gexMinMax[0] = Math.ceil(getMaxOfArray(gexList));
    gexMinMax[1] = Math.ceil(getMaxOfArray(closeList));
    gexMinMax[2] = Math.floor(getMinOfArray(gexList));
    gexMinMax[3] = Math.floor(getMinOfArray(closeList));

    return {
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderWidth: '0',
            textStyle: {
                color: 'rgba(255, 255, 255, 0.85)',
            },
            shadowColor: 'rgba(0, 0, 0, 0.4)',
            formatter(params) {
                var relVal = params[0].name;
                for (var i = 0, l = params.length; i < l; i++) {
                    relVal += '<br/>' + params[i].marker + '<span style="display: inline-block; min-width: 120px">' + params[i].seriesName + ' : <span style="float: right">' + Number(params[i].value).toFixed(i + 1) + '</span></span>'
                }
                return relVal;
            }
        },
        axisPointer: {
            lineStyle: {
                type: 'solid',
                color: 'gray'
            }
        },
        color: ['rgba(108, 74, 255, 1)', 'rgba(197, 171, 153, 1)'],
        xAxis: {
            type: 'category',
            axisLine: {
                lineStyle: {
                    color: 'gray',
                    opacity: 0.5,
                    width: 1,
                },
            },
            axisLabel: {
                textStyle: {
                    color: '#99989D',
                },
            },
            data: dateList
        },
        yAxis: [
            {
                name: 'GEX',
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#99989D',
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: ['gray'],
                        opacity: 0.2,
                    },
                },
                min: (1.1 * gexMinMax[2] - 0.1 * gexMinMax[0]).toFixed(0),
                max: (1.1 * gexMinMax[0] - 0.1 * gexMinMax[2]).toFixed(0),
                interval: parseInt(((1.1 * gexMinMax[0] - 0.1 * gexMinMax[2]).toFixed(0) - (1.1 * gexMinMax[2] - 0.1 * gexMinMax[0]).toFixed(0)) / 5)
            },
            {
                name: 'Close Price',
                position: 'right',
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#99989D',
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: ['gray'],
                        opacity: 0.2,
                    },
                },
                min: (1.1 * gexMinMax[3] - 0.1 * gexMinMax[1]).toFixed(0),
                max: (1.1 * gexMinMax[1] - 0.1 * gexMinMax[3]).toFixed(0),
                interval: parseInt(((1.1 * gexMinMax[1] - 0.1 * gexMinMax[3]).toFixed(0) - (1.1 * gexMinMax[3] - 0.1 * gexMinMax[1]).toFixed(0)) / 5)
            }
        ],
        legend: {
            itemHeight: 3,
            itemWidth: 11,
            icon: 'rect',
            left: 0,
            textStyle: { color: '#99989D', fontSize: 14 }
        },
        grid: {
            left: 0,
            right: 0,
            bottom: 0,
            containLabel: true,
        },
        series: [
            {
                name: 'GEX',
                type: 'line',
                symbol: 'none',
                lineStyle: {
                    color: 'rgba(108, 74, 255, 1)',
                    width: 1,
                },
                emphasis: {
                    lineStyle: {
                        width: 1,
                    },
                },
                data: gexList,
            },
            {
                name: 'Close Price',
                type: 'line',
                symbol: 'none',
                lineStyle: {
                    color: 'rgba(197, 171, 153, 1)',
                    width: 1,
                },
                yAxisIndex: 1,
                emphasis: {
                    lineStyle: {
                        width: 1,
                    },
                },
                data: closeList,
            },
        ],
    }
};

export default totalOption;