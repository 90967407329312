import { Button, DatePicker, Input, Row, Col, Table, Tooltip, Result } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import { anomalousFlowListData } from "@/service/unusualFlow";
import ReactECharts from 'echarts-for-react';
import Empty from '../empty';
import intl from 'react-intl-universal';
import { HttpGet } from "../../../../utils/request";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import './index.less';

/* TODO 
url = http://35.231.151.49:8888/products#unusualflow
API = v3/anomalousflow from Kai
e.g. http://35.231.151.49:8081/api/v3/anomalousflow?ticker=QQQ&date=2023-01-29&prevWindow=40&pctThreshold=0p7&subseqWindow=10&hitThreshold=0p03
*/

// 空状态
const initialList = [];
const initialChartList = {
    list: [],
    ticker: '',
}
const chartStyle = {
    height: 500,
    width: '100%'
}

const getMaxOfArray = (numArray) => {
    return Math.max.apply(null, numArray);
}

const getMinOfArray = (numArray) => {
    return Math.min.apply(null, numArray);
}

// component 
const UnusualFlow = ({ NProgress }) => {
    // ticker
    // const [ticker, setTicker] = useState('AAPL');
    const [tickerSelected, setTickerSelected] = useState('');
    // const [flow, setFlow] = useState("");
    const [frequency, setFrequency] = useState('daily');  //weekly, ticker

    const [prevWindow, setPrevWindow] = useState(40);
    const [subseqWindow, setSubseqWindow] = useState(10);
    const [pctThreshold, setPctThreshold] = useState(70);
    const [hitThreshold, setHitThreshold] = useState(3);

    const [chartFlowInOption, setChartFlowInOption] = useState();
    const [chartFlowOutOption, setChartFlowOutOption] = useState();

    const [statisticsFlowInData, setStatisticsFlowInData] = useState();
    const [statisticsFlowOutData, setStatisticsFlowOutData] = useState();
    
    // date
    const [date, setDate] = useState(window.SERVER_DATA ? moment(window.SERVER_DATA['MF_LAST_DAY']):moment());


    // flow in/out list 
    const [dailyListEmpty, setDailyListEmpty] = useState(true);
    const [flowInList, setFlowInList] = useState([]);
    const [flowOutList, setFlowOutList] = useState([]);
    const [tickerFlowList, setTickerFlowList] = useState([]);


    const [chartTitle, setChartTitle] = useState('');
    const [tickers, setTickers] = useState('');
    
    // spinner
    const [showLoading, setShowLoading] = useState(true);
    // 返回信息
    const [message, setMessage] = useState('');    
    
    // type = in/out
    const generateChartOption = (type, res, ticker) => {
        if (type) {
                // massage data to get array of data 
                let dateList;
                let volumeList;
                let volumeColor;
                let rotateSymbol = 0;
                let markPointColor = "black";
                let closeList;
                let chartData = res.data;
                // { coord: ['5/11/22', 291.84] }
                let markPointData = [];
                let title;
                if (type == "in") {
                    title = `Flow In Chart for ${ticker}`;
                    volumeList = chartData.map( value => parseInt(value.flowIn));
                    // markpoint Data based on signal
                    res.signal.forEach( value => {
                        if (value.flowtype == 1) {
                            markPointData.push({coord: [value.xlabel, value.closePrice]})
                        }
                    });
                    volumeColor = '#24c537'; // green
                    rotateSymbol = 0;

                } else if (type == "out") {
                    title = `Flow Out Chart for ${ticker}`;
                    volumeList = chartData.map( value => parseInt(value.flowOut));
                    // markpoint Data based on signal
                    res.signal.forEach( value => {
                        if (value.flowtype == -1) {
                            markPointData.push({coord: [value.xlabel, value.closePrice]})
                        }
                    });
                    volumeColor = '#e84e4e';
                    rotateSymbol = 180;
                }
                setChartTitle(title);
                dateList = chartData.map( value => value.xlabel);
                closeList = chartData.map( value => value.close);                 
                const optionData = {       
                     grid:  {
                        left: '10%',                        
                        top: '8%',
                     },                                                                                                  
                     xAxis: [
                         {
                            type: 'category',
                            data: dateList,
                            boundaryGap: false,
                            axisLine: { lineStyle: { color: '#777' } },
                            min: 'dataMin',
                            max: 'dataMax',
                            // axisPointer: {
                            //     show: true
                            // },
                            // axisLine: { show: false },
                            // splitLine: { show: false },
                            // axisLabel: { show: false },
                            // axisTick: { show: false },
                         },
                        //  {
                        //     type: 'category',
                        //     gridIndex: 1,
                        //     data: dateList,
                        //     boundaryGap: true,
                        //     axisLine: { show: true },
                        //     splitLine: { show: true },
                        //     axisLabel: { show: true },
                        //     axisTick: { show: true },
                        //     axisLine: { lineStyle: { color: '#777' } },
                        //     min: 'dataMin',
                        //     max: 'dataMax',
                        //  }
                     ],  
                     yAxis: [
                         {
                            scale: true,
                            // position: 'right',
                            splitNumber: 5,
                            axisLine: { lineStyle: { color: '#777' } },
                            splitLine: { show: true },
                            axisTick: { show: false },
                            axisLabel: {
                              inside: false,
                              formatter: '{value}\n'
                            },
                            min: Math.floor(getMinOfArray(closeList)),
                            max: Math.ceil(getMaxOfArray(closeList))
                          },
                        //  {
                        //     gridIndex: 1,
                        //     scale: true,
                        //     splitNumber: 2,
                        //     axisLabel: { show: false },
                        //     axisLine: { show: false },
                        //     axisTick: { show: false },
                        //     splitLine: { show: false }
                        //  }
                     ],
                     series: [                    
                        {
                            name: 'Stock Price',
                            type: 'line',
                            data: closeList,
                            // xAxisIndex: 1,
                            // yAxisIndex: 1,
                            smooth: false,
                            showSymbol: false,
                            lineStyle: {
                                width: 1
                            },
                            markPoint: {
                                symbol: 'triangle',
                                symbolRotate: rotateSymbol,
                                // symbol: "image://https://djm58qs22mpkz.cloudfront.net/images/pioneers/categories/active/0.png",
                                symbolSize: 8,
                                itemStyle: {
                                    color: volumeColor
                                },
                                label: {
                                  formatter: function (param) {
                                    return ""
                                    // return param != null ? Math.round(param.value) + '' : '';
                                  }
                                },
                                data: markPointData
                                // tooltip: {
                                //   formatter: function (param) {
                                //     return param.name + '<br>' + (param.data.coord || '');
                                //   }
                                // }
                              },
                        },
                        // {
                        //     name: 'Volume',
                        //     type: 'bar',
                        //     xAxisIndex: 1,
                        //     yAxisIndex: 1,
                        //     itemStyle: {
                        //         color: volumeColor
                        //     },
                        //     data: volumeList
                        // },
                     ],
                     dataZoom: [
                        {
                          type: 'slider',
                          xAxisIndex: [0, 1],
                          realtime: false,
                          start: 0,
                          end: 100,
                        //   top: 80,
                        //   bottom: 0,
                          height: 24,
                          handleIcon:
                            'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                          handleSize: '120%'
                        },
                        {
                          type: 'inside',
                          xAxisIndex: [0, 1],
                          start: 40,
                          end: 70,
                        //   top: 30,
                          height: 24
                        }
                    ],
                    tooltip: {
                         trigger: 'axis',
                         axisPointer: {
                             type: 'cross'
                         },
                         backgroundColor: 'rgba(0, 0, 0, 0.6)',
                         borderWidth: '0',
                         textStyle: {
                             color: 'rgba(255, 255, 255, 0.85)',
                         },
                         shadowColor: 'rgba(0, 0, 0, 0.4)',
                         formatter(params) {
                             var relVal = params[0].name;
                             for (var i = 0, l = params.length; i < l; i++) {
                                if (i >= 1) {
                                    relVal += '<br/>' + params[i].marker + '<span style="display: inline-block; min-width: 120px">' + params[i].seriesName + ' : <span style="float: right">' + Number(params[i].value).toFixed(0) + '</span></span>'

                                } else {
                                    relVal += '<br/>' + params[i].marker + '<span style="display: inline-block; min-width: 120px">' + params[i].seriesName + ' : <span style="float: right">' + Number(params[i].value).toFixed(i + 1) + '</span></span>'
                                }
                             }
                             return relVal;
                         }
                     },
                     axisPointer: {
                         link: [
                         {
                             xAxisIndex: 'all'
                         }
                         ],
                         label: {
                            backgroundColor: '#777'
                         }
                     },
                     //  color: ['rgba(108, 74, 255, 1)', 'rgba(197, 171, 153, 1)'],                    
                     color: ['rgba(197, 171, 153, 1)'],                    
                     legend: {
                         itemHeight: 3,
                         itemWidth: 10,
                         // icon: 'rect',
                         orient: 'vertical',
                         left: 0,                        
                         top: 30,
                         bottom: 15,
                         textStyle: { color: '#99989D', fontSize: 10 },
                        //  data: [
                        //     {name: "Stock Price", icon: 'circle',},
                        //     // {name: "Signal Triggered", icon: 'circle'},                        
                        //  ]
                     },
                     title: {
                         text: title
                     },      
                 };
 
                setShowLoading(false);
                
                if (type == "in") {
                    setChartFlowInOption(optionData);
                } else if (type == "out") {
                    setChartFlowOutOption(optionData);
                }
        }
    };

    const handleKeypress = e => {
        if(e.key === 'Enter' || e.code === "NumpadEnter"){
            onSearch();
        }        
    };

    const handleTabSelect = type => {
        setDailyListEmpty(true); 
        setFrequency(type); 
        setTickers("");
        setMessage("");
        setChartFlowInOption()
        setChartFlowOutOption()
    };

    // 查询
    const onSearch = async (ticker, flow) => {
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }
        NProgress.start();
        const formatDate = date ? date.format('YYYY-MM-DD') : Date.now().format('YYYY-MM-DD');
        if (formatDate) {
            setChartFlowInOption();
            setChartFlowOutOption();
            setStatisticsFlowInData();
            setStatisticsFlowOutData();

            // on init load search w date and ticker
            if (typeof ticker != "string") {
                const tickersToPass = tickers.trim();
                const res = await getDailyList(formatDate, tickersToPass);
                
                if (res && res.data) {
                    // ticker 
                    if (frequency === 'ticker' && tickersToPass) {
                        if (res.data.summary && res.data.summary.length) {
                            // extract data
                            const tickerflowArray = res.data.summary.map((item) => {
                                const {accuGain, avgGain, succussRate, number, stopLoss, stopProfit} = item.statistics 
                                return {
                                    date: item.date, type: item.type, 
                                    closing_price: item.closing_price,
                                    closing_ts: item.closing_ts,
                                    opening_price: item.opening_price,
                                    opening_ts: item.opening_ts,
                                    profit: item.profit,
                                    accuGain, avgGain, succussRate, number, stopLoss, stopProfit
                                }
                            })   
                            if (res.data.ticker) {
                                setTickerSelected(res.data.ticker)
                            }                         
                            setDailyListEmpty(false);
                            setTickerFlowList(tickerflowArray)
                        } else {
                            setTickerFlowList([])
                        }
                    }

                    // handle date only data
                    // flowOut 2 item  = 6/23
                    // flowIn + Out = 6/15
                    if ((res.data.flowInTickers && res.data.flowInTickers.length) || (res.data.flowOutTickers && res.data.flowOutTickers.length)) {
                        // has data
                        setDailyListEmpty(false);
                        setTickerFlowList([])
                        
                        // TODO transform flowIn list to [{ticker: rest ata}]
                        // 8-25 success, missing statistics
                        let flowInTickerList = [];
                        if (frequency === 'daily') {
                            flowInTickerList = res.data.flowInTickers.map((ticker) => {
                                const tickerStatistics = res.data.flowInStatistics[ticker] || {};
                                return {
                                    ticker: ticker,
                                    ...tickerStatistics
                                }
                            });
                        } else {
                            // weekly
                            flowInTickerList = res.data.flowInTickers.map((ticker, index) => {
                                const tickerStatistics = res.data.flowInStatistics[index] || {};
                                return {
                                    ticker: ticker,
                                    date: res.data.flowInDates[index],
                                    ...tickerStatistics
                                }
                            });
                        }
                        setFlowInList(flowInTickerList);

                        // out flow data
                        let flowOutTickerList = [];
                        if (frequency === 'daily') {
                            flowOutTickerList = res.data.flowOutTickers.map((ticker) => {
                                const tickerStatistics = res.data.flowOutStatistics[ticker] || {};
                                return {
                                    ticker: ticker,
                                    ...tickerStatistics
                                }
                            })
                        } else {
                             // weekly
                             flowOutTickerList = res.data.flowOutTickers.map((ticker, index) => {
                                const tickerStatistics = res.data.flowOutStatistics[index] || {};
                                return {
                                    ticker: ticker,
                                    date: res.data.flowOutDates[index],
                                    ...tickerStatistics
                                }
                            });
                        }
                       
                        setFlowOutList(flowOutTickerList);

                        // console.log(">>flowInTickerList", flowInTickerList);

                    } else {
                        if (frequency != "ticker") {
                            setDailyListEmpty(true);
                            if (res.data.message == "Success") {
                                // 2022/1/13
                                setMessage("没有符合条件的股票入选");
                            } else {
                                if (res.data.message) {
                                    if(res.data.message == "No data") {
                                        // console.log(">>", res.data.message)
                                        setMessage("没有相关数据，请尝试查询其他日期或股票代码");

                                    } else {
                                        setMessage(res.data.message);

                                    }
                                    
                                } else {
                                    setMessage("")
                                }
                            }
                        } else {
                            if(res.data.message == "No data") {
                                console.log(">>", res.data.message)
                                setMessage("没有相关数据，请尝试查询其他日期或股票代码");
                            }
                        }
                    }
                
                } else {
                    setTickerFlowList([]);
                    setFlowInList([]);
                    setFlowOutList([]);
                    setTickerSelected("");
                    setMessage('');
                }
                NProgress.done();
            } else {
                // on click ticker, get in/out individual chart
                fetchList(ticker, formatDate, prevWindow, pctThreshold, subseqWindow, hitThreshold).then(res => {                    
                    if (res.data && res.data.length) {
                        if (res.flowInStatistics) {
                            // console.log(">>res.flowInStatistics", res.flowInStatistics);
                            setStatisticsFlowInData(res.flowInStatistics[0]);
                        }
                        if (res.flowOutStatistics) {
                            setStatisticsFlowOutData(res.flowOutStatistics[0]);
                        }
                        if (flow == "in") {
                            generateChartOption("in", res, ticker);
                        } else if (flow == "out") {
                            generateChartOption("out", res, ticker);
                        }
                        
                        NProgress.done();
    
                    }
                });
            }           
        }
    };

    const getDailyList = (date, ticker="") => {
        let url;
        if (frequency === 'weekly') {
            url = `/api/v3/unusualflow_weeklylist?date=${date}`
        } else if (frequency === 'daily') {
            url = `/api/v3/unusualflow_dailylist?date=${date}`;
            if (ticker && ticker.trim()) {
                url = `/api/v3/unusualflow_ticker?ticker=${ticker}`;
            } 
        } else if (frequency === 'ticker') {
            if (ticker && ticker.trim()) {
                url = `/api/v3/unusualflow_ticker?ticker=${ticker}`;
            } 
        }
        // console.log(">>url", url)
        if (url) {
            return HttpGet(url);
        }
    }

    const fetchList = async (ticker, date) => {
        // console.log(">>", ticker, date)
        // if (NProgress.isStarted()) {
        //     NProgress.done();
        //     NProgress.remove();
        // }

        // NProgress.start();
        const res = await anomalousFlowListData(ticker, date, prevWindow, pctThreshold, subseqWindow, hitThreshold);
        if (res && res.data) {
            // setMessage(res.data.message || '');
            if (res.data.data && res.data.signal) {
                // NProgress.done();
                return res.data;
            }
        } else {
            // setChartResult();
            setMessage('');
        }
        // NProgress.done();
    };

    const generateColumnData = (flow="in", isSearchTicker=false) => {
        var flowReturnTooltip = "每一次出现信号以收盘价买入正股，持有总共10个交易日，如果某日收盘价相较于买入价上涨超过5个STD则在该日收盘的时候止盈，如果某日收盘价相较于买入价下跌超过2个STD 则在该日收盘的时候止损，如果10个交易日期间既没有触发止盈也没有触发止损，则在10个交易日以后的收盘价平仓。用以上交易规则进行操作，过去2年的累计回报率、平均回报率、交易胜率如下表所示";
        if (flow !== "in") {
            flow = "out";
            flowReturnTooltip = "每一次出现信号以收盘价卖出正股，持有总共10个交易日，如果某日收盘价相较于买入价下跌超过5个STD则在该日收盘的时候止盈，如果某日收盘价相较于买入价上涨超过2个STD 则在该日收盘的时候止损，如果10个交易日期间既没有触发止盈也没有触发止损，则在10个交易日以后的收盘价平仓。用以上交易规则进行操作，过去2年的累计回报率、平均回报率、交易胜率如下表所示";
        }
        let result = [];
        // search w date: add ticker
        if (!isSearchTicker) {
            result.push(
                {
                    title: "Ticker",
                    dataIndex: 'ticker',
                    width: '10%',
                    render: (ticker) => <a onClick={() => {setTickerSelected(ticker); onSearch(ticker, flow);}}>{ticker}</a>
                }
            )
        } else {
            // ticker search: add date
            result.push(
                {
                    title: "Date",
                    dataIndex: 'date',
                    width: '10%'                    
                },
                {
                    title: "Type",
                    dataIndex: 'type',
                    width: '7%',
                }
            )
        }
        if (frequency == "weekly") {
            result.push(
                {
                    title: "Date",
                    dataIndex: 'date',
                    width: '10%',
                }
            )
        }
        if (frequency =="ticker") {
            result.push(               
                {
                    title: "Open date",
                    dataIndex: 'opening_ts',
                    width: '10%',
                },
                {
                    title: "Open price",
                    dataIndex: 'opening_price',
                    width: '10%',
                    render: (num) => num && num.toFixed(2)
                },
                {
                    title: "Close date",
                    dataIndex: 'closing_ts',
                    width: '10%',
                },
                {
                    title: "Close price",
                    dataIndex: 'closing_price',
                    width: '10%',
                    render: (num) => num && num.toFixed(2)
                },
                {
                    title: "Profit",
                    dataIndex: 'profit',
                    width: '10%',
                    render: (num) => num && (num*100).toFixed(0) + "%"
                },
                {
                    title: "Stop Loss",
                    dataIndex: 'stopLoss',
                    width: '10%',
                    render: (num) => num ? num.toFixed(2) : "TBA"
                },
                {
                    title: "Stop Profit",
                    dataIndex: 'stopProfit',
                    width: '10%',
                    render: (num) => num ? num.toFixed(2) : "TBA"
                },

            )

        } else {
            result.push(
                {
                    title: "Accumulate Return",
                    // title: () => {
                    //     return <Tooltip title={flowReturnTooltip}>Accumulate Return <InfoCircleOutlined /> </Tooltip>
                    // },
                    dataIndex: 'accuGain',
                    width: '15%',
                    render: (num) => num && parseInt(num *100) + "%"
                },
                {
                    title: "Average Return",
                    dataIndex: 'avgGain',
                    width: '15%',
                    render: (num) => num && parseInt(num *100) + "%"
                },
                {
                    title: "Success Rate",
                    dataIndex: 'succussRate',
                    width: '10%',
                    render: (num) => num && parseInt(num *100) + "%"
                },
                {
                    title: "Triggered #",
                    dataIndex: 'number',
                    width: '10%',
                },
                {
                    title: "Stop Loss",
                    dataIndex: 'stopLoss',
                    width: '10%',
                    render: (num) => num ? num.toFixed(2) : "TBA"
                },
                {
                    title: "Stop Profit",
                    dataIndex: 'stopProfit',
                    width: '10%',
                    render: (num) => num ? num.toFixed(2) : "TBA"
                }
            );
        }
        
        return result;
    }
    
    return (
        <div className='unusual-flow'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter'>
                    <Row className='form-container'>
                        <Col className='form-title' span={8} >{intl.get('products.product11.title')}</Col>
                        <Col className='form-content' span={16}>
                            <div className='content-item'>
                                <span className='label'>{intl.get('products.date')}</span>
                                <DatePicker
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date}
                                    onChange={date => setDate(date)}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                />
                                
                                {/* <span className='label'>{intl.get('products.ticker')}</span> */}
                                {/* <Input className="input-ticker" defaultValue={ticker} onChange={e => setTicker(e.target.value.toUpperCase())} onKeyPress={handleKeypress}/> */}
                            </div>
                            <div className='content-item content-item-frequency'>
                                {/* select week then hide ticker */}
                                <div className='select-button'>
                                    <span className={frequency === 'daily' ? 'active daily' : 'daily'} onClick={() => { handleTabSelect("daily") }}>日</span>
                                    <span className={frequency === 'weekly' ? 'active weekly' : 'weekly'} onClick={() => { handleTabSelect("weekly") }}>周</span>
                                    <span className={frequency === 'ticker' ? 'active ticker' : 'ticker'} onClick={() => { handleTabSelect("ticker") }}>股票</span>
                                </div>
                            </div>
                            <div className={frequency === 'ticker' ? 'content-item content-item-ticker' : 'content-item content-item-ticker hide'}>
                                <span className='label'>代码</span>
                                <Input value={tickers} onChange={e => setTickers(e.target.value.toUpperCase())} onKeyPress={handleKeypress}/>
                            </div>

                            <Button className='button' onClick={onSearch} >{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div>
                                <a href='https://medium.com/@quantunicorn/涨跌异动信号使用指南-b0d063a32102' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product11.subTitle')}</Col>
                        {/* <Col className='param' span={20}>
                            <span className='label'>PrevWindow (30-100)</span>
                            <Input className="input-param" type="number" min="30" max="100" defaultValue={40} onChange={e => setPrevWindow(e.target.value)} />

                            <span className='label'>SubseqWindow (0-10)</span>
                            <Input className="input-param" type="number" min="0" max="10" defaultValue={10} onChange={e => setSubseqWindow(e.target.value)} />

                            <span className='label'>pctThreshold (0-100%)</span>
                            <Input className="input-param" type="number" min="0" max="100" defaultValue={70} onChange={e => setPctThreshold(e.target.value)} />%
                           
                            <span className='label'>hitThreshold (0-100%)</span>
                            <Input className="input-param" type="number" min="0" max="100" defaultValue={3} onChange={e => setHitThreshold(e.target.value)} />%
                        </Col> */}
                    </Row>
                </Col>
            </div>

            <div id="product-content" className={tickerFlowList.length ? "searchTicker" : "searchDate"}>
                {/* {dailyListEmpty ? "true" : "false"} */}

                {!dailyListEmpty && frequency === 'weekly' ? 
                    <h2>每周精选</h2>
                    : ""
                }
                {/* search w ticker  */}
                {!dailyListEmpty && tickerFlowList.length ? 
                    <div>
                        <h2>{tickerSelected} flow signal</h2>
                        <Table size="small" columns={generateColumnData("in", true)} dataSource={tickerFlowList} pagination={true} />
                    </div>
                    : <Empty className="empty-ticker" text={message} />
                }     

                {/* search w date  */}   
                { !dailyListEmpty && frequency !== 'ticker' ?
                    <div className="flowData">
                        {flowInList.length ?
                            <div>
                                <h2>In flow signal</h2>
                                <p>
                                (每一次出现信号以收盘价买入正股，持有总共 10 个交易日，如果某日收盘价上涨超过 stop profit 的价格则在该日收盘的时候止盈，如果某日收盘价低于 stop loss 的价格则在该日收盘的时候止损，如果 10 个交易日期间既没有触发止盈也没有触发止损，则在 10 个交易日以后的收盘价平仓。用以上交易规则进行操作，过去 2 年的累计回报率、平均回报率、交易胜率如下表所示：)
                                </p>
                                <Table size="small" columns={generateColumnData("in")} dataSource={flowInList} pagination={true} />
                            </div>
                            : "" 
                        }
                    </div>
                    : <Empty className="empty-date" text={message} />
                }

                { !dailyListEmpty && frequency !== 'ticker' ?
                    <div className="flowData">
                        {flowOutList.length ?
                            <div>
                                <h2>Out flow signal</h2>
                                <p>
                                    (每一次出现信号以收盘价做空正股，持有总共 10 个交易日，如果某日收盘价下跌低于 stop profit 的价格则在该日收盘的时候止盈，如果某日收盘价高于 stop loss 的价格则在该日收盘的时候止损，如果 10 个交易日期间既没有触发止盈也没有触发止损，则在 10 个交易日以后的收盘价平仓。用以上交易规则进行操作，过去 2 年的累计回报率、平均回报率、交易胜率如下表所示：)
                                </p>
                                <Table size="small" columns={generateColumnData("out")} dataSource={flowOutList} pagination={true} />
                            </div>
                            : "" 
                        }
                    </div>
                    : null
                }

                {/* flow in chart */}
                { chartFlowInOption && 
                    <div className="chart">
                        {/* <div className="chart-title">{chartTitle}</div> */}
                        <span class="lenged-signal"><CaretUpOutlined style={{ fontSize: '16px', color: '#7fbe9e' }}/>Triggered</span>
                        <ReactECharts
                            option={chartFlowInOption}                            
                            style={chartStyle}
                            showLoading={showLoading}                                    
                        />   
                    </div>                                            
                }
                { chartFlowInOption && !statisticsFlowInData && 
                    <div class="flow-data">
                        <h2>Statistics within 10 Days</h2>
                        {/* <Table size="small" columns={{"title": "Flow In"}} dataSource={{}} pagination={false} /> */}
                        <table class="ant-table-content">
                            <tr>
                                <th>Flow In</th>
                                <th>Good Signal</th>
                                <th>Success Rate</th>
                                <th>Quantile25%</th>
                                <th>Quantile50%</th>
                                <th>Quantile75%</th>
                                <th>Kurtosis</th>
                                <th>MeanChange</th>
                                <th>Skewness</th>
                                <th>Variance</th>
                            </tr>
                            <tr>
                                <td>{statisticsFlowInData.FlowIn}</td>
                                <td>{statisticsFlowInData["Goodsignal"]}</td>
                                <td>{(statisticsFlowInData["SuccessRate"] *100).toFixed(2)}%</td>
                                
                                <td>{(statisticsFlowInData["Quantile25%"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Quantile50%"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Quantile75%"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Kurtosis"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["MeanChange"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Skewness"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Variance"] *100).toFixed(2)}%</td>
                            </tr>
                        </table>
                    </div>
                }

                {/* flow out */}
                { chartFlowOutOption && 
                    <div className="chart">
                        <span class="lenged-signal"><CaretDownOutlined style={{ fontSize: '16px', color: 'red' }}/>Triggered</span>
                         <ReactECharts
                            option={chartFlowOutOption}                            
                            style={chartStyle}
                            showLoading={showLoading}                                    
                    />     
                    </div>                                        
                }
                
                { chartFlowOutOption && !statisticsFlowOutData && 
                    <div class="flow-data">
                        <h2>Statistics within 10 Days</h2>
                        {/* <Table size="small" columns={{"title": "Flow In"}} dataSource={{}} pagination={false} /> */}
                        <table class="ant-table-content">
                            <tr>
                                <th>Flow Out</th>
                                <th>Good Signal</th>
                                <th>Success Rate</th>
                                <th>Quantile25%</th>
                                <th>Quantile50%</th>
                                <th>Quantile75%</th>
                                <th>Kurtosis</th>
                                <th>MeanChange</th>
                                <th>Skewness</th>
                                <th>Variance</th>
                            </tr>
                            <tr>
                                <td>{statisticsFlowOutData.FlowOut}</td>
                                <td>{statisticsFlowOutData["Goodsignal"]}</td>
                                <td>{(statisticsFlowOutData["SuccessRate"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Quantile25%"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Quantile50%"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Quantile75%"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Kurtosis"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["MeanChange"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Skewness"] *100).toFixed(2)}%</td>
                                <td>{(statisticsFlowInData["Variance"] *100).toFixed(2)}%</td>
                            </tr>
                        </table>
                    </div>
                }
                
            </div>
           
        </div>
    )
}

export default UnusualFlow;