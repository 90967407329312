import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Menu, Button, Dropdown, Badge, Alert, Space } from 'antd';
import icon1 from '@/asset/home/products/icon1.png';
import icon1Active from '@/asset/home/products/icon1-active.png';
import icon2 from '@/asset/home/products/icon2.png';
import icon2Active from '@/asset/home/products/icon2-active.png';
import icon3 from '@/asset/home/products/icon3.png';
import icon3Active from '@/asset/home/products/icon3-active.png';
import icon4 from '@/asset/home/products/icon4.png';
import icon4Active from '@/asset/home/products/icon4-active.png';
import icon5 from '@/asset/home/products/icon5.png';
import icon5Active from '@/asset/home/products/icon5-active.png';
import icon6 from '@/asset/home/products/icon6.png';
import icon6Active from '@/asset/home/products/icon6-active.png';
import icon7 from '@/asset/home/products/icon7.png';
import icon7Active from '@/asset/home/products/icon7-active.png';
import icon8 from '@/asset/home/products/icon8.png';
import icon8Active from '@/asset/home/products/icon8-active.png';
import icon9 from '@/asset/home/products/icon9.png';
import icon9Active from '@/asset/home/products/icon9-active.png';

import banner1Url from '@/asset/home/banner1.png';
import banner1enUrl from '@/asset/home/banner1en.png';
import banner2Url from '@/asset/home/banner2.png';
import banner2enUrl from '@/asset/home/banner2en.png';

import banner3Url from '@/asset/home/banner3.png';
import banner3enUrl from '@/asset/home/banner3en.png';
import banner4Url from '@/asset/home/banner4.png';
import banner4enUrl from '@/asset/home/banner4en.png';

import banner5Url from '@/asset/home/banner5.png';
import banner5enUrl from '@/asset/home/banner5en.png';
import banner6Url from '@/asset/home/banner6.png';
import banner6enUrl from '@/asset/home/banner6en.png';
import banner7Url from '@/asset/home/banner7.png';
import banner7enUrl from '@/asset/home/banner7en.png';
import banner8Url from '@/asset/home/banner8.png';
import banner8enUrl from '@/asset/home/banner8en.png';
import banner9Url from '@/asset/home/banner9.png';
import banner9enUrl from '@/asset/home/banner9en.png';

import Content from "./content";
import intl from 'react-intl-universal';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.less'; // core Swiper
import './index.less';

// import Swiper core and required modules
import SwiperCore, {
    Autoplay
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay]);

const ProductsBlock = () => {
    // 轮播中功能介绍的index
    const [activeIndex, setActiveIndex] = useState();
    // 用户点击停止轮播
    const [countFlag, setCountFlag] = useState(true);
    // swiper实例
    const swiperRef = React.useRef();

    const onInit = (Swiper) => {
        swiperRef.current = Swiper;
    };

    const list = [
        {
            img: icon1,
            activeImg: icon1Active,
            title: intl.get('home.products.product1.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product1.list.title1'),
                        description: intl.get('home.products.product1.list.description1'),
                    },
                    {
                        title: intl.get('home.products.product1.list.title2'),
                        description: intl.get('home.products.product1.list.description2'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1WcTVs2d3yTfWYStRQQQNmwOMF2jDqBj3',
                // imgEn: 'https://drive.google.com/uc?export=view&id=16NoJCYGXzFdqd0YuCL4zbxPV0tdnGD7N',
                img: banner1Url,
                imgEn: banner1enUrl,
                href: '/products#market'
            },
        },
        {
            img: icon2,
            activeImg: icon2Active,
            title: intl.get('home.products.product2.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product2.list.title1'),
                        description: intl.get('home.products.product2.list.description1'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1HtjbZn5HfAtgwOjOT7GbJBWeAExNLlEN',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1WnxvEF-22jagNzUzyewUDkSrAMivJWeP',
                img: banner2Url,
                imgEn: banner2enUrl,
                href: '/products#capitalflow'

            },
        },
        {
            img: icon3,
            activeImg: icon3Active,
            title: intl.get('home.products.product3.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product3.list.title1'),
                        description: intl.get('home.products.product3.list.description1'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1gYoPaLVSe1oAuFbX2DkWG06BmHFNIH8q',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1W4znLjX9B8pTiMaXi9X9gnOJkUce5nL6',
                img: banner3Url,
                imgEn: banner3enUrl,
                href: '/products#multiflow'

            },
        },
        {
            img: icon4,
            activeImg: icon4Active,
            title: intl.get('home.products.product4.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product4.list.title1'),
                        description: intl.get('home.products.product4.list.description1'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1iEHUzfOJtEHG014yMVtIu6go3zkU7mcG',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1Ies1SzT5VAjvqEOh6ZejM2Gz8gIiJI_z',
                img: banner4Url,
                imgEn: banner4enUrl,
                href: '/products#markethistory'

            },
        },
        {
            img: icon5,
            activeImg: icon5Active,
            title: intl.get('home.products.product5.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product5.list.title1'),
                        description: intl.get('home.products.product5.list.description1'),
                    },
                    {
                        title: intl.get('home.products.product5.list.title2'),
                        description: intl.get('home.products.product5.list.description2'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1D4wEMioLSJEK6jPIwv9T3lwuRUEYlJDr',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1JCkvrGElEbiB4jP3xNRrBawEtL-ywyH4',
                img: banner5Url,
                imgEn: banner5enUrl,
                href: '/products#qu100'

            },
        },
        {
            img: icon6,
            activeImg: icon6Active,
            title: intl.get('home.products.product6.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product6.list.title1'),
                        description: intl.get('home.products.product6.list.description1'),
                    },
                    {
                        description: intl.get('home.products.product6.list.description2'),
                    },
                    {
                        description: intl.get('home.products.product6.list.description3'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1cJqQMN39RTB_PyLwh-8dE5523ivMxP6e',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1c5MLgFjr4GGauOQ6hRtybgWwqlBc_LGn',
                img: banner6Url,
                imgEn: banner6enUrl,
                href: '/products#gex'

            },
        },
        {
            img: icon9,
            activeImg: icon9Active,
            title: intl.get('home.products.product9.title'),
            content: {
            textList: [
            {
            title: intl.get('home.products.product9.list.title1'),
            description: intl.get('home.products.product9.list.description1'),
            },
            {
            description: intl.get('home.products.product9.list.description2'),
            },
            {
            description: intl.get('home.products.product9.list.description3'),
            },
            ],
            img: banner9Url,
            imgEn: banner9enUrl,
            href: '/products#qualgo'
            
            
            },
        },
        {
            img: icon7,
            activeImg: icon7Active,
            title: intl.get('home.products.product7.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product7.list.title1'),
                        description: intl.get('home.products.product7.list.description1'),
                    },
                    {
                        description: intl.get('home.products.product7.list.description2'),
                    },
                    {
                        description: intl.get('home.products.product7.list.description3'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1LFjaQoZ-kLE_s4sBnTspCll7us_-5yCD',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1LFjaQoZ-kLE_s4sBnTspCll7us_-5yCD',
                img: banner7Url,
                imgEn: banner7enUrl,
                href: '/products#optiondata'

            },
        },
        {
            img: icon8,
            activeImg: icon8Active,
            title: intl.get('home.products.product8.title'),
            content: {
                textList: [
                    {
                        title: intl.get('home.products.product8.list.title1'),
                        description: intl.get('home.products.product8.list.description1'),
                    },
                    {
                        description: intl.get('home.products.product8.list.description2'),
                    },
                    {
                        description: intl.get('home.products.product8.list.description3'),
                    },
                ],
                // img: 'https://drive.google.com/uc?export=view&id=1f91Fep7ccKA5gEdON45lo196FMSg84mv',
                // imgEn: 'https://drive.google.com/uc?export=view&id=1GmUKHL0aEJfhwD2VeURGcN6QQJzDrLy-',
                img: banner8Url,
                imgEn: banner8enUrl,
                href: '/products#unusualflow'

            },
        },
    ]

    // 默认选中第一个
    useEffect(() => {
        setActiveIndex(0);
    }, [])

    // 停止轮播
    const stop = (index) => {
        setCountFlag(false);
        setActiveIndex(index);
        if (swiperRef.current) {
            swiperRef.current.autoplay.stop();
            swiperRef.current.slideTo(index)
        }
    }

    return (
        <div className='products-block-content'>
            <div className='container'>
                <div className='menu'>
                    <Badge.Ribbon className="badge-option-trade" placement='start' text='New!' color='red'></Badge.Ribbon>
                    <Badge.Ribbon className="badge-unusalflow" placement='start' text='New!' color='red'></Badge.Ribbon>

                    {list.map((v, index) =>                    
                        <div
                            key={index}
                            className={index === activeIndex ? 'icon active' : 'icon'}
                            onClick={() => stop(index)}
                        >                            
                            <img className='icon-img' src={index === activeIndex ? v.activeImg : v.img} alt='' ></img>
                            <div className='icon-title'>{v.title}</div>
                            <div className={index === activeIndex && countFlag ? 'icon-line-active' : 'icon-line'}></div>
                            
                        </div>
                    )}
                </div>


                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        "delay": 7000,
                        "disableOnInteraction": false
                    }}
                    onSlideChange={e => setActiveIndex(e.activeIndex)}
                    onInit={onInit}
                    slice={activeIndex}
                >
                    {list.map((v, index) => <SwiperSlide key={index}><Content {...v.content} stop={() => stop(index)} /></SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    )
}

export default ProductsBlock;