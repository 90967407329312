import Icon from '@ant-design/icons';

const DarkSvg = () => (
    <svg width="25px" height="24px" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="导航-已登录-后台夜间" transform="translate(-1505.000000, -31.000000)">
                <g id="编组-4" transform="translate(1505.000000, 31.000000)">
                    <rect id="矩形" x="0.080593669" y="0" width="24" height="24" rx="2"></rect>
                    <g id="系统" transform="translate(3.080594, 2.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3">
                        <g id="晴（夜间）" transform="translate(0.135315, 0.500000)">
                            <path d="M8.656152,0.912125418 L8.63756345,0.978982382 L8.63756366,0.978981614 C8.44067742,1.69541842 8.33970406,2.43480669 8.33728609,3.17779426 L8.33728609,3.17787158 C8.33728539,7.82609124 12.1054177,11.5942228 16.7536373,11.5942228 C17.0277395,11.5942229 17.3016791,11.5808325 17.5744742,11.5540997 L17.6509734,11.5469503 L17.6309549,11.6205898 C16.6321751,15.3068489 13.2830128,17.9121254 9.41407374,17.9121254 C4.87622954,17.9089165 1.13742194,14.3502671 0.909700275,9.81857255 C0.68234726,5.28617424 4.0458079,1.37112113 8.56535394,0.912125418 L8.65579463,0.912125418 L8.656152,0.912125418 Z" id="路径"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const DarkIcon = (props) => <Icon component={DarkSvg} {...props}></Icon>