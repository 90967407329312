import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import intl from 'react-intl-universal';
import { Product1SvgIcon, Product2SvgIcon, Product3SvgIcon, Product4SvgIcon, Product5SvgIcon, Product6SvgIcon } from "./icon";
import { useStore } from "@/hooks/useStore";
import { STORE_USER } from "@/stores/createStores";
import { Link } from "react-router-dom";
import { UserLevel } from "@/constant/level";
import { gotoSignIn } from "@/utils/gotoSignIn";
import LevelBlock from "@/components/level";
import './index.less';

import imgDark1 from '@/asset/overview/img1Dark.png';
import imgLight1 from '@/asset/overview/img1Light.png';
import imgDarkEn1 from '@/asset/overview/img1DarkEn.png';
import imgLightEn1 from '@/asset/overview/img1LightEn.png';
import imgDark2 from '@/asset/overview/imgDark2.png';
import imgLight2 from '@/asset/overview/imgLight2.png';
import imgDarkEn2 from '@/asset/overview/imgDarkEn2.png';
import imgLightEn2 from '@/asset/overview/imgLightEn2.png';
import imgDark3 from '@/asset/overview/imgDark3.png';
import imgLight3 from '@/asset/overview/imgLight3.png';
import imgDarkEn3 from '@/asset/overview/imgDarkEn3.png';
import imgLightEn3 from '@/asset/overview/imgLightEn3.png';
import imgDark4 from '@/asset/overview/imgDark4.png';
import imgLight4 from '@/asset/overview/imgLight4.png';
import imgDarkEn4 from '@/asset/overview/imgDarkEn4.png';
import imgLightEn4 from '@/asset/overview/imgLightEn4.png';
import imgDark5 from '@/asset/overview/imgDark5.png';
import imgLight5 from '@/asset/overview/imgLight5.png';
import imgDarkEn5 from '@/asset/overview/imgDarkEn5.png';
import imgLightEn5 from '@/asset/overview/imgLightEn5.png';
import imgDark6 from '@/asset/overview/imgDark6.png';
import imgLight6 from '@/asset/overview/imgLight6.png';
import imgDarkEn6 from '@/asset/overview/imgDarkEn6.png';
import imgLightEn6 from '@/asset/overview/imgLightEn6.png';

// unusualflow
import imgDark7 from '@/asset/overview/imgDark7.png';
import imgLight7 from '@/asset/overview/imgLight7.png';
import imgDarkEn7 from '@/asset/overview/imgDarkEn7.png';
import imgLightEn7 from '@/asset/overview/imgLightEn7.png';

import imgDark8 from '@/asset/overview/imgDark8.png';
import imgLight8 from '@/asset/overview/imgLight8.png';
import imgDarkEn8 from '@/asset/overview/imgDarkEn8.png';
import imgLightEn8 from '@/asset/overview/imgLightEn8.png';

// qualgo
import imgDarkQUALGO from '@/asset/overview/imgDarkQUALGO.png';
import imgLightQUALGO from '@/asset/overview/imgLightQUALGO.png';
import imgDarkEnQUALGO from '@/asset/overview/imgDarkEnQUALGO.png';
import imgLightEnQUALGO from '@/asset/overview/imgLightEnQUALGO.png';


const Overview = () => {
    // 判断登录
    const user = useStore(STORE_USER);    
    if (!user || !user.level) {
        gotoSignIn();
    }
    const [useZh, setUseZh] = useState(!window.localStorage.language || window.localStorage.language === 'zh_CN');
    const [useDark, setUseDark] = useState(!window.localStorage.theme || window.localStorage.theme === 'dark');
    const navigate = useNavigate();
    // 中英文
    useEffect(() => {
        if (window.localStorage.language === 'en_US') {
            setUseZh(false)
        } else {
            setUseZh(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.localStorage.language])
    // 主题
    useEffect(() => {
        if (window.localStorage.theme === 'light') {
            setUseDark(false)
        } else {
            setUseDark(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.localStorage.theme])
    const products = [
        {
            href: '/products#market',
            icon: <Product1SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1BbnXYCCJgktIAbdPN2ASj97itoxEuVyi',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1BgVSa-LxTY18M1-0gSKUAaRbN2_ld6ia',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1V3pL92_f0WJrQWDQs-Buhvi0ZqvF2VX4',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1kaHjeyfI9YxWl2ngF75Ehu5kWHinXtX_',
            imgDark: imgDark1,
            imgLight: imgLight1,
            imgDarkEn: imgDarkEn1,
            imgLightEn: imgLightEn1,
            title: intl.get('overview.product1.title'),
            description: intl.get('overview.product1.description'),
        },
        {
            href: '/products#capitalflow',
            icon: <Product2SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1F4Jc7ri1yXruOGd2jQkKBSkFZPPf7nuO',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1jbqcuU30M5CmY4eFcn9DGEIl_0XAOR7L',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1aF8bWGPDDQJMkV0qns33znANNBKkx3N7',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=121jCnjPony_jfjRDV42wzM1ojJr7Tt5u',
            imgDark: imgDark2,
            imgLight: imgLight2,
            imgDarkEn: imgDarkEn2,
            imgLightEn: imgLightEn2,
            title: intl.get('overview.product2.title'),
            description: intl.get('overview.product2.description'),
        },
        {
            href: '/products#multiflow',
            icon: <Product3SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1qaV2KuIiSMU4kuhpVj7vFZ8zDOvrUc4_',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1W_bg5nJvGGuDVbRYTLPN1o5JrfSjQjMe',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1fjt_aZbNbxE3u0L5k_sI2SRrntMu3qcn',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1l1EPiY8IIn7GM2Y6fuOwNb0TRqMUtOgs',
            imgDark: imgDark3,
            imgLight: imgLight3,
            imgDarkEn: imgDarkEn3,
            imgLightEn: imgLightEn3,
            title: intl.get('overview.product3.title'),
            description: intl.get('overview.product3.description'),
        },
        {
            href: '/products#markethistory',
            icon: <Product4SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1F0a9fuQsmBMzXnOROcYQ1gqERpzoawv9',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1KMyc9Ja3XBKDpqAd57e5KidWrLd-PG8n',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1G8mih0UUSz4RaQ2BnGmY5Ra4LuC_nXIL',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1KlR022XgLuzGbSVGNtkvcwn5azg11Jt0',
            imgDark: imgDark4,
            imgLight: imgLight4,
            imgDarkEn: imgDarkEn4,
            imgLightEn: imgLightEn4,
            title: intl.get('overview.product4.title'),
            description: intl.get('overview.product4.description'),
        },
        {
            href: '/products#qu100',
            icon: <Product5SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1zOIqWuCCBDNi0hFUIAp__3HXLHbIEvEc',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1OXUYY8CIqT8Mj2n4kIBpkkxx13_R6Iua',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1npqSazMeDVqbwxJYUeEiGRE-Lo1CgObW',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1uSHaWmoj8UcDzARlx5LUIhnEZU93SAp9',
            imgDark: imgDark5,
            imgLight: imgLight5,
            imgDarkEn: imgDarkEn5,
            imgLightEn: imgLightEn5,
            title: intl.get('overview.product5.title'),
            description: intl.get('overview.product5.description'),
        },
        {
            href: '/products#gex',
            icon: <Product6SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=10rJrMLJi9-SwkjXBNnvhv2BUgbPJXzn7',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1vSHUZQ1RyPT00l7XaV7NtAr4P6a1u9Z3',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1FDiY295yxgi1Fe1p9DXoxu8KEUFfEs-T',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1V4SztOeNAd8rOQj1VnTljhWGE66vaiLt',
            imgDark: imgDark6,
            imgLight: imgLight6,
            imgDarkEn: imgDarkEn6,
            imgLightEn: imgLightEn6,
            title: intl.get('overview.product6.title'),
            description: intl.get('overview.product6.description'),
        },

        {
            href: '/products#qualgo',
            icon: <Product4SvgIcon />,
            //imgDark: 'https://drive.google.com/uc?export=view&id=1LhShHJUnyoNKh__R2Q86o4rxvJmQjY5G',
            //imgLight: 'https://drive.google.com/uc?export=view&id=1-Sq_lzC53NvaeKkg0ivR2eEgqM526Je3',
            //imgDarkEn: 'https://drive.google.com/uc?export=view&id=1dfUZfJCTDqFwnpI5-BitObsxv6udUGjy',
            //imgLightEn: 'https://drive.google.com/uc?export=view&id=1dfUZfJCTDqFwnpI5-BitObsxv6udUGjy',
            imgDark: imgDarkQUALGO,
            imgLight: imgLightQUALGO,
            imgDarkEn: imgDarkEnQUALGO,
            imgLightEn: imgLightEnQUALGO,
            title: intl.get('overview.product12.title'),
            description: intl.get('overview.product12.description'),
        },

        {
            href: '/products#unusualflow',
            icon: <Product3SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1Ml8NwuWhdpfAgumB-NG6jxpr6Oyqzgjt',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1vYUVpQufNKqQbcxZbciq8eb6JaKbIn2z',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1FDiY295yxgi1Fe1p9DXoxu8KEUFfEs-T',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1V4SztOeNAd8rOQj1VnTljhWGE66vaiLt',
            imgDark: imgDark7,
            imgLight: imgLight7,
            imgDarkEn: imgDarkEn7,
            imgLightEn: imgLightEn7,
            title: intl.get('overview.product11.title'),
            description: intl.get('overview.product11.description'),
        },

        {    
            href: '/products#optiondata',
            icon: <Product3SvgIcon />,
            // imgDark: 'https://drive.google.com/uc?export=view&id=1L3R4YsAgYN2q2M7fqcSl7HnayEefuII9',
            // imgLight: 'https://drive.google.com/uc?export=view&id=1txWUOE0sRNRXAoziZwRaJ7kE8sdyBaUo',
            // imgDarkEn: 'https://drive.google.com/uc?export=view&id=1OHSrYtnjqmaMTW5-FYWel5J8qSztugDL',
            // imgLightEn: 'https://drive.google.com/uc?export=view&id=1OHSrYtnjqmaMTW5-FYWel5J8qSztugDL',
            imgDark: imgDark8,
            imgLight: imgLight8,
            imgDarkEn: imgDarkEn8,
            imgLightEn: imgLightEn8,
            title: intl.get('overview.product7.title'),
            description: intl.get('overview.product7.description'),
        },
    ]

    return (
        <div className='overview'>
            <div className='large-container'>
                {user && user.level && <div className='user-info'>
                    <LevelBlock />
                    {user.level !== UserLevel.gold && <div className='upgrade'>
                        <Link to='/#plans'>{intl.get('overview.button')}</Link>
                    </div>}
                </div>}
                <Row gutter={[16, 16]}>
                    {
                        products.map(v => {
                            const currentImg = `img${useDark ? 'Dark' : 'Light'}${useZh ? '' : 'En'}`;
                            return <Col span={8} key={v.href}>
                                <div className='item' onClick={() => navigate(v.href)}>
                                    <div className='img'>
                                        <img src={v[currentImg]} alt='' />
                                    </div>
                                    <div className='title'>
                                        {v.icon}
                                        <span>{v.title}</span>
                                    </div>
                                    <div className='description'>{v.description}</div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default Overview