// 小圆点颜色
export const getColor = (text) => {
    let color = '';
    if (text === '+') {
        color = 'green'
    } else if (text === '-') {
        color = 'red'
    } else {
        color = 'yellow'
    }
    return (
        <>
            <span className={color}></span>
            <span>{text}</span>
        </>
    );
}