import React, { useState } from "react";
import play from '@/asset/home/tutorials/video-play.png';
import number1 from '@/asset/home/tutorials/1.png';
import number2 from '@/asset/home/tutorials/2.png';
import number3 from '@/asset/home/tutorials/3.png';
import number4 from '@/asset/home/tutorials/4.png';
import number5 from '@/asset/home/tutorials/5.png';
import number6 from '@/asset/home/tutorials/6.png';
import './index.less';


const TutorialsBlock = () => {
    // 轮播中功能介绍的index
    const [activeIndex, setActiveIndex] = useState(0);

    const list = [
        {
            title: '资金流介绍及使用教程',
            // src: 'https://www.youtube.com/embed/YmTxxMtazcg',
            src: 'https://www.youtube.com/embed/NZFfmXjhcms',            
            img: number1
        },

        {
            title: 'GEX 使用指南',
            // src: 'https://www.youtube.com/embed/SFKbIkooi1M',
            src: 'https://www.youtube.com/embed/U6k4FKaaXpY',
            img: number2
        },
        {
            title: '期权大单产品介绍',
            // src: 'https://www.youtube.com/embed/FgNZvvpaH2U',
            src: 'https://www.youtube.com/embed/8bRL6jpjqy8',
            img: number3
        },
        {
            title: '涨跌异动信号产品介绍',
            // src: 'https://www.youtube.com/embed/5N1hp2RK2IY',
            src: 'https://www.youtube.com/embed/I7Di6imDFiI',
            img: number4
        },
    ];

    return (
        <div className='tutorials-block-content'>
            <div className='container'>
                <div className='video'>
                    <iframe src={list[activeIndex].src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className='video-list'>
                    {
                        list.map((v, index) =>
                            <div
                                className={activeIndex === index ? 'active item' : 'item'}
                                key={index}
                                onClick={() => setActiveIndex(index)}
                            >
                                <img src={activeIndex === index ? play : v.img} alt={index} />
                                <div className='title'>{v.title}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default TutorialsBlock;
