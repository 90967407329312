import { message } from "antd";

// 展示错误信息
export const showErrorMsg = (error) => {
    message.error(error);
    // switch (error) {
    //     case 400:
    //         message.error('用户名密码错误');
    //         break;
    //     case 504:
    //         message.error('服务开小差去了');
    //         break;
    //     default:
    //         message.error('信号被外星人劫持了');
    //         break;
    // }
};