import { lazy } from "react";

const Overview = lazy(() => import('../page/overview'));
const Products = lazy(() => import('../page/products'));
const AboutUs = lazy(() => import('../page/aboutUs'));
const News = lazy(() => import('../page/news'));
const Legal = lazy(() => import('../page/legal'));
const Privacy = lazy(() => import('../page/privacy'));
const Account = lazy(() => import('../page/account'));

export const Config = [
    {
        title: 'overview',
        path: '/overview',
        element: <Overview />
    },
    {
        title: 'products',
        path: '/products',
        element: <Products />
    },
    {
        title: 'aboutUs',
        path: '/aboutus',
        element: <AboutUs />
    },
    {
        title: 'account',
        path: '/account',
        element: <Account />
    },
    {
        title: 'news',
        path: '/news',
        element: <News />
    },
    {
        title: 'legal',
        path: '/legal',
        element: <Legal />
    },
    {
        title: 'privacy',
        path: '/privacy',
        element: <Privacy />
    },
]