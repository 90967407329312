// http请求
import axios from "axios";
import qs from 'qs'
import { gotoPlans } from "./gotoPlans";
import { gotoSignIn } from "./gotoSignIn";
import { showErrorMsg } from "./showErrorMsg";

// axios实例
const request = axios.create({
    withCredentials: true,
    timeout: 100000,
})

// 请求拦截
request.interceptors.request.use(
    config => {
        // 部分post是json
        if (config.postType && config.postType === 'json') {
            return config;
        }

        // 其余转为formdata
        // config.headers['Cache-Control'] = 'no-cache';
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data = qs.stringify(config.data) 
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

// 响应拦截
request.interceptors.response.use(
    response => {
        if (response.status === 201 && !response.config.ignoreError) {
            // 未登录或登录态失效
            if (response.data.message === "Not logged in") {
                gotoSignIn();
            } else {
                showErrorMsg(response.data.message);
            }
        }
        return response;
    },
    error => {
        if (error && error.response && !error.config.ignoreError) {
            if (error.response.status === 401) {
                // 权限不够去订阅页面
                gotoPlans();
            } else {
                showErrorMsg(error.response.data.message);
            }
        }
        return Promise.reject(error);
    }
)

export const HttpGet = (url, config) => {
    return request.get(url, config).then(data => data);
}

export const HttpPost = (url, data, config) => {
    return request.post(url, data, config).then(data => data);
}
