/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/asset/layout/logo-black.png';
import intl from 'react-intl-universal';
import { signIn } from '@/utils/outLet';
import './index.less';

const SignInLayout = () => {
    return (
        <div className='sign-in-layout'>
            <div className='left'></div>
            <div className='right'>
                <div className='back-btn'>
                    <Link to='/'>&lt; {intl.get('signInLayout.backHome')}</Link>
                </div>
                <div className='logo'>
                    <img src={logo} alt="QUANTUNICORN"></img>
                    <div>{intl.get('signInLayout.slogan')}</div>
                </div>
                <div className='content'>
                    {signIn()}
                </div>
            </div>
        </div>

    )
}

export default SignInLayout;