import { action, observable } from "mobx";
import { userInfo,signOut } from "@/service/user";

export class UserStore {
    @observable level = '';
    @observable membership = '';
    @observable email = '';
    @observable refer_link = '';

    @action.bound
    async userInfo(config) {
        const data = await userInfo(config);

        if (data && data.data) {
            if (data.data.membership) {
                this.level = data.data.membership;
                this.membership = data.data.membership;
            }
            if (data.data.email) {
                this.email = data.data.email;
            }
            if (data.data.refer_link) {
                this.refer_link = data.data.refer_link;
            }
            
        } else {
            this.level = '';
            this.membership = '';
            this.email = '';
            this.refer_link = '';
        }

    }

    @action.bound
    async signOut() {
        await signOut();
        this.level = '';
        this.membership = '';
        this.email = '';
        this.refer_link = '';        
    }
}
