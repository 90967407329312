import { Button, Checkbox, Form, Row, Col, Input } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { signIn } from "@/service/user";
import { gotoHome } from "@/utils/gotoHome";
import { useStore } from "@/hooks/useStore";
import { STORE_USER } from "@/stores/createStores";
import Success from "../component/success";
import queryString from 'query-string';
import './index.less';

const SignIn = () => {
    const [mismatch, setMismatch] = useState("");
    const navigate = useNavigate();
    const user = useStore(STORE_USER);
    const { next } = queryString.parse(window.location.search);

    // 检查是否登录
    const checkSignIn = useCallback(async () => {
        await user.userInfo({ ignoreError: true });
    }, [user])
    useEffect(() => {
        checkSignIn()
    }, [checkSignIn]);


    // TODO 登录 跳转or输入错误报错
    const onFinish = async (values) => {
        try {
            const res = await signIn({ ...values, next, reg_next: '/' });
            if (res.status === 200) {
                gotoHome();
            }else if(res.status === 201) {
                setMismatch(res.data.message);
                console.log(mismatch);
            }

        } catch (error) {
            console.log(error);
            // message.error('出错了')
            // 邮箱未注册？密码不正确？
        }
    };

    return user.level ?
        <Success text={intl.get('signInLayout.signIn.signedInText')} />
        :
        <div className='sign-in'>
            <div className='title'>{intl.get('signInLayout.signIn.title')}</div>
            <Form
                name="signIn"
                onFinish={onFinish}
                initialValues={{
                    email: window.localStorage.email,
                }}
                scrollToFirstError
            >
                <p className='error-message'>{mismatch}</p>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: intl.get('signInLayout.signIn.EmailInvalid'),
                        },
                        {
                            required: true,
                            message: intl.get('signInLayout.signIn.EmailEmpty'),
                        },
                    ]}
                >
                    <Input className="input" placeholder={intl.get('signInLayout.signIn.EmailPlaceholder')} />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: intl.get('signInLayout.signIn.passwordEmpty'),
                        },
                    ]}
                >
                    <Input.Password className="input" placeholder={intl.get('signInLayout.signIn.passwordPlaceholder')} />
                </Form.Item>
                    <Row>
                        <Col span={18}>
                            <Form.Item name="remember_me" valuePropName="checked">
                                <Checkbox>{intl.get("signInLayout.signIn.rememberMe")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                        <Form.Item><Link to='/forgot' className='forgot-password-link' >{intl.get("signInLayout.signIn.forgotPassword")}</Link></Form.Item>
                        </Col>
                    </Row>
                <Form.Item >
                    <Button type="primary" htmlType="submit" className='sign-in-btn'>
                        {intl.get('signInLayout.signIn.signInBtn')}
                    </Button>
                </Form.Item>
            </Form>

            <div className='title-join'>{intl.get('signInLayout.signIn.titleJoin')}</div>

            <Button className='signUp-Btn' onClick={() => navigate('/signup')}>
                {intl.get('signInLayout.signIn.signUpBtn')}
            </Button>
        </div>

}

export default SignIn
