import React from "react";
import { QrcodeOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import intl from 'react-intl-universal';
import QRCord from '@/asset/aboutUs/qrCode.png';
import './index.less';

const content = (
    <div>
        <img className='qrCode-img' src={QRCord} alt="" />
    </div>
);

const AboutUs = () => {
    return (
        <div className='about-us'>            
            <div className='banner'>
                <div className='container'>
                    <div className='title'>{intl.get("aboutUs.title")}</div>
                    <div className='description'>
                        <div className='text'>{intl.get("aboutUs.content.profile.text1")}</div>
                        <div className='text'>{intl.get("aboutUs.content.profile.text2")}</div>
                        <div className='text'>{intl.get("aboutUs.content.profile.text3")}</div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='block'>
                    <div className='title'>{intl.get("aboutUs.content.followUs.title")}</div>
                    <div className='text'>
                        <span>{intl.get("aboutUs.content.followUs.weChat")}</span>
                        <Popover className='qrCode-Popover' placement="right" trigger="hover" content={content}>
                            <QrcodeOutlined className='qrCode-btn' />
                        </Popover>
                    </div>
                    <div className='text'>
                        <span>{intl.get("aboutUs.content.followUs.youtube")}</span>
                        <a className='link' href='https://www.youtube.com/channel/UCpetyCmM-u7IUfZBoN3d6fQ'>Quantunicorn</a>
                    </div>
                    <div className='text'>
                        <span>{intl.get("aboutUs.content.followUs.discord")}</span>
                        <a className='link' href='https://discord.gg/r9MfAyPmxn'>Quantunicorn</a>
                    </div>
                    <div className='text'>{intl.get("aboutUs.content.followUs.zoom")}</div>
                </div>

                <div className='block'>
                    <div className='title'>{intl.get("aboutUs.content.contactUs.title")}</div>
                    <div className='text'>{intl.get("aboutUs.content.contactUs.business")}business@quantunicorn.com</div>
                    <div className='text'>{intl.get("aboutUs.content.contactUs.employment")}hr@quantunicorn.com</div>
                    <div className='text'>{intl.get("aboutUs.content.contactUs.feedback")}feedback@quantunicorn.com</div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs