import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import intl from 'react-intl-universal';
import { forgotPassword } from "../../service/user";
import './index.less';

const Forgot = () => {
    // TODO 发送邮件
    const [sent, setSent] = useState("");
    const onFinish = async(values) => {
        console.log('Received values of form: ', values);
        try {
            await forgotPassword(values);
            setSent(intl.get('signInLayout.forgot.sentEmail'));
            // 跳转到指定页面
        } catch (error) {
            message.error('出错了')
            // 邮箱未注册？密码不正确？
        }
    };

    return (
        <div className='forgot'>
            <div className='title'>{intl.get('signInLayout.forgot.title')}</div>
            <p className='sent-message'>{sent}</p>
            <Form
                name="forgot"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: intl.get('signInLayout.forgot.EmailInvalid'),
                        },
                        {
                            required: true,
                            message: intl.get('signInLayout.forgot.EmailEmpty'),
                        },
                    ]}
                >
                    <Input className="input" placeholder={intl.get('signInLayout.forgot.EmailPlaceholder')} />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit" className='reset-btn'>
                        {intl.get('signInLayout.forgot.submitBtn')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Forgot