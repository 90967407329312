import { HttpGet, HttpPost } from "../utils/request";

export function signIn(param) {
    return HttpPost('/user/sign-in', param)
}

export function signUp(param) {
    return HttpPost('/user/register', param)
}

export function signOut() {
    return HttpGet('/user/sign-out')
}

export function forgotPassword(param) {
    return HttpPost('/user/forgot-password', param)
}

export function resetPassword(param, auth) {
    return HttpPost(`/user/reset-password/${auth}`, param)
}

export function userInfo(config) {
    return HttpGet('/user/membership', config)
}

export function userCenter() {
    return HttpPost('/create-customer-portal-session')
}

// vpn测试用 可以删除
// export function signIn(param) {
//     return HttpPost('http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/user/sign-in', param)
// }

// export function signUp(param) {
//     return HttpPost('http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/user/register', param)
// }

// export function signOut() {
//     return HttpGet('http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/user/sign-out')
// }

// export function forgotPassword(param) {
//     return HttpPost('http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/user/forgot-password', param)
// }

// export function resetPassword(param, auth) {
//     return HttpPost(`http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/user/reset-password/${auth}`, param)
// }

// export function userInfo(config) {
//     return HttpGet('http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/user/membership', config)
// }

// export function userCenter() {
//     return HttpPost('http://feature-frontend-dot-suyuanliu.ue.r.appspot.com/create-customer-portal-session')
// }


