import React from "react";
import empty from '../../../../asset/products/empty.png';
import intl from 'react-intl-universal';
import './index.less';

const Empty = ({ message="No data", text, className }) => {
    var classNameNew = `empty ${className}`
    if (text && text != "") {
        // custom
        return (
            <div className={classNameNew}>
                <img src={empty} alt=''></img>
                {text ?
                    <div className='message'>
                        {text}
                    </div> : null}           
            </div>
        )
    } else {
        // default
        // console.log(">>", message, text)
        return (
            <div className={classNameNew}>
                <img src={empty} alt=''></img>
                {message ?
                    <div className='message'>
                        {intl.get('products.noData')}
                    </div> : null}
            </div>
        )
    }
}

export default Empty;