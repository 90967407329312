import AboutUs from "../page/aboutUs";
import NotFound from "../page/exception/404";
import Forgot from "../page/forgot";
import Home from "../page/home";
import SignIn from "../page/signIn";
// import News from "../page/news";
import Overview from "../page/overview";
import Privacy from "../page/privacy";
import Products from "../page/products";
import SignUp from "../page/signUp";
import Reset from "../page/reset";
import Legal from "../page/legal";
import Account from "../page/account";

// 模拟OutLet，根目录下路由
export const pageOutLet = () => {
    let child;
    switch (window.location.pathname) {
        case '/overview':
            child = <Overview />
            break;
        case '/products':
            child = <Products />
            break;
        case '/aboutus':
            child = <AboutUs />
            break;
        case '/account':
            child = <Account />
            break;    
        // case '/news':
        //     child = <News />
        //     break;
        case '/legal':
            child = <Legal />
            break;
        case '/privacy':
            child = <Privacy />
            break;
        case '/':
            child = <Home />
            break;
        default:
            child = <NotFound />
            break;
    }
    return child;
};

// 模拟OutLet，登录注册路由
export const signIn = () => {
    let child;
    switch (true) {
        // 防止/signin////无法匹配
        case window.location.pathname.indexOf('/signin')>-1:
            child = <SignIn />
            break;
        case window.location.pathname.indexOf('/signup')>-1:
            child = <SignUp />
            break;
        case window.location.pathname.indexOf('/forgot')>-1:
            child = <Forgot />
            break;
        case window.location.pathname.indexOf('/reset')>-1:
            child = <Reset />
            break;
        default:
            child = <></>
            break;
    }
    return child;
};