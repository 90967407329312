import { HttpGet } from "../utils/request";

export function anomalousFlowListData(ticker, date, prevWindow, pctThreshold, subseqWindow, hitThreshold) {
     // console.log(">>", pctThreshold, hitThreshold)
     // http://35.231.151.49:8081/api/v3/anomalousflow?ticker=QQQ&date=2023-01-28&prevWindow=40&pctThreshold=0p7&subseqWindow=10&hitThreshold=0p03
     prevWindow = prevWindow || 40;
     subseqWindow = subseqWindow || 10;
     pctThreshold = pctThreshold || 70; //pctThreshold=0p7 0-100; 0.7
     var pctThresholdStr = "0p";
     if (pctThreshold >= 10) {
          pctThresholdStr += pctThreshold
     } else {
          pctThresholdStr += "0" + pctThreshold
     }
     pctThresholdStr = pctThresholdStr.replace(".", "")

     hitThreshold = hitThreshold || 3; //0-100 0p03
     var hitThresholdStr = "0p";
     if (hitThreshold >= 10) {
          hitThresholdStr += hitThreshold
     } else {
          hitThresholdStr += "0" + hitThreshold
     }
     hitThresholdStr = hitThresholdStr.replace(".", "");

     // todo 
     const url = `/api/v3/anomalousflow?ticker=${ticker}&date=${date}&prevWindow=${prevWindow}&pctThreshold=${pctThresholdStr}&subseqWindow=${subseqWindow}&hitThreshold=${hitThresholdStr}`;
     // console.log(">>url", url)
     return HttpGet(url);
}
