export const UserLevel = {
    free: 'Free', //0
    silver: 'Silver', // 1
    gold: 'Gold',  // 2
    platinum: "Platinum", // 3

    silverAlgo: 'Silver+Algo', // 4
    goldAlgo: "Gold+Algo", // 5    
    algo: "Algo" // 6
}
