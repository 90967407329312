import Recaptcha from 'react-recaptcha';

const RecaptchaItem = ({ onChange }) => {
    const verifyCallback = (result) => {
        onChange(result);
    }

    var sitekey;
    if (window.SERVER_DATA === undefined) {
        sitekey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
    }
    else {
        sitekey = window.SERVER_DATA["RECAPTCHA_PUBLIC_KEY"];
    }

    return (<Recaptcha
        render="explicit"
        sitekey={sitekey}
        onloadCallback={() => { }}
        verifyCallback={verifyCallback}
    />);
}
export default RecaptchaItem
