import { Row, Col, DatePicker, Table, Button, Input, InputNumber, Select, Tag, Tooltip, Radio, Checkbox } from "antd";
import { InfoCircleOutlined, DownOutlined } from '@ant-design/icons';
import React, { useState, useEffect} from "react";
import Empty from '../empty'
import moment, { normalizeUnits } from 'moment';
import intl from 'react-intl-universal';
import { HttpGet } from "../../../../utils/request";

import './index.less';

// import mockData from './mockData.json';
// import mockData from './mockDataNew.json';
const { TextArea } = Input;

// http://35.231.151.49:8888/products#optiondata
// only 4/5 api data works now 

const { Option } = Select;

const OptionData = ({ NProgress }) => {    
    //date
    const [date, setDate] = useState(window.SERVER_DATA ? moment(window.SERVER_DATA['MF_LAST_DAY']):moment());
    // 返回信息
    const [message, setMessage] = useState('');
    
    // 1 block trade 
    const [list, setList] = useState([]);
    const [totalItem, setTotalItem] = useState();
    const [selectList, setSelectList] = useState([]);
    const [type, setType] = useState('');
    const [side, setSide] = useState('');
    const [filterTicker, setFilterTicker] = useState('');

    const [frequency, setFrequency] = useState('tradenotional'); //time
    const [top, setTop] = useState(false);
    const [tickers, setTickers] = useState('');
    const [frequencyTime, setFrequencyTime] = useState('daily');

    // filters simple 4
    const [isSingleLeg, setIsSingleLeg] = useState('any');

    // specal filter
    const [isUnusual, setIsUnusual] = useState();
    const [isPaired, setIsPaired] = useState();
    const [isRepeated, setIsRepeated] = useState();

    // value 
    const [atm, setAtm] = useState(true);
    const [otm, setOtm] = useState(true);
    const [itm, setItm] = useState(true);
    const [lastSelected, setLastSelected] = useState(false);
    // const [atmOtm, setAtmOtm] = useState([]);
    const [callPut, setCallPut] = useState('');
    const [specialType, setSpecialType] = useState('');

    // filters compare 5
    // const [expirationCompare, setExpirationCompare] = useState('any');
    // const [expirationCompareDate, setExpirationCompareDate] = useState('');
    const [expirationDTE, setExpirationDTE] = useState(0);

    // multi-select: trade date 
    const [strikeCompareLow, setStrikeCompareLow] = useState();
    const [strikeCompareHigh, setStrikeCompareHigh] = useState();
    const [openInterestLow, setOpenInterestLow] = useState();
    const [openInterestHigh, setOpenInterestHigh] = useState();
    const [tradeQtyLow, setTradeQtyLow] = useState();
    const [tradeQtyHigh, setTradeQtyHigh] = useState();
    const [tradeNotionalLow, setTradeNotionalLow] = useState();
    const [tradeNotionalHigh, setTradeNotionalHigh] = useState();
    
    // 2 notional: not in use 
    const [typeNotional, setTypeNotional] = useState('');
    const [sideNotional, setSideNotional] = useState('');
    const [filterTickerNotional, setFilterTickerNotional] = useState('');

    // 3 repeat
    const [typeRepeat, setTypeRepeat] = useState('');
    const [sideRepeat, setSideRepeat] = useState('');
    const [filterTickerRepeat, setFilterTickerRepeat] = useState('');

    const DEFAULT_FILTER_SELECT = [{value: "any", label: "any"}, {value: "yes", label: "yes"}, {value: "no", label: "no"}]
    const DEFAULT_FILTER_COMPOARE = [{value: "any", label: "any"}, {value: "greater than", label: ">"}, {value: "equals to", label: "="}, {value: "less than", label: "<"}]    

    useEffect(() => {
        // on search on init load
        // TODO: weekend has no data BE issue
        onSearch(0, true);        
    }, [])


    // 1 block trade datatable 
    const columnsBlockTrade = [
        // {
        //     title: 'Date',
        //     dataIndex: 'Date',
        //     className:'Date',
        //     // sorter: (a, b) => a.Time.localeCompare(b.Time)
        // },
        {
            title: 'Time',
            dataIndex: 'Time',
            className:'Time',
            // sorter: (a, b) => a.Time.localeCompare(b.Time)
        },
        {
            title: 'Symbol',
            dataIndex: 'Symbol',            
            // sorter: (a, b) => a.Symbol.localeCompare(b.Symbol)
        },
        {
            title: 'Option Expiration',
            dataIndex: 'OptionExpiration',
            // sorter: (a, b) => new Date(b.OptionExpiration) - new Date(a.OptionExpiration)
        },
      
        {
            title: 'Type',
            dataIndex: 'Type',
            // sorter: (a, b) => a.Type.localeCompare(b.Type)
        }, 

        {
            title: 'Strike',
            dataIndex: 'Strike',
            // sorter: (a, b) => a.Strike - b.Strike
        },  
        {
            title: 'Spot Price',
            dataIndex: 'SpotPrice',
            // sorter: (a, b) => a.SpotPrice - b.SpotPrice
        },
        {
            title: 'Open Interest',
            dataIndex: 'OpenInterest',
            // sorter: (a, b) => a.OpenInterest - b.OpenInterest
        },   
        {
            title: 'Trade Qty',
            dataIndex: 'TradeQty',
            // sorter: (a, b) => a.TradeQty - b.TradeQty
        },  
        {
            // title: '<b>Trade Notional</b>',
            title: () => {
                return <b>Trade Notional</b>
            },
            dataIndex: 'TradeNotional',
            // sorter: (a, b) => a.TradeNotional - b.TradeNotional,
            render: (text) => {
                if (text) {
                    // num to k/ m
                    let newNum = Intl.NumberFormat('en-US', {
                        notation: "compact",
                        maximumFractionDigits: 1
                      }).format(text);
                    return <b>{newNum}</b>
                }
            }
        },  
        {
            title: 'Side',
            dataIndex: 'Side',
            render: (text) => {
                return <span className={text}>{text}</span>                
            }
            // sorter: (a, b) => a.Side.localeCompare(b.Side)
        },  
        {
            title: () => {
                return <Tooltip title="Single leg：单腿策略  裸call/裸put
                Combination：多腿策略组合 如 vertical spread/butterfly/strangle等
                ">Condition<InfoCircleOutlined /> </Tooltip>
            },
            dataIndex: 'Condition',
            // sorter: (a, b) => a.Condition.localeCompare(b.Condition)
        },         
        {            
            title: () => {
                return <Tooltip title="某个特定价位日期的期权在当日成交的交易量显著大于该位置的open interest时会触发此记号，这意味着该期权今天有新的资金介入">Unusual Volume<InfoCircleOutlined /> </Tooltip>
            },
            dataIndex: 'UnusualVolume',
            // sorter: (a, b) => a.UnusualVolume.localeCompare(b.UnusualVolume),
            render: (text) => {
                if (text && text == "Y") {
                    return <Tag color="geekblue">Unusual Volume</Tag>
                }
            }
        },
        {
            title: () => {
                return <Tooltip title="如果某一单期权大单交易和暗盘/明盘的股票交易形成 配对策略时会触发此标记，交易者在交易期权的同时也下单了股票，使得期权+股票的组合总delta 为0，组合净值不受股价波动的影响，这意味着这一单期权并没有看多/看跌倾向，不能作为普通的期权大单来参考">Pair<InfoCircleOutlined /> </Tooltip>
            },
            dataIndex: 'Pair',
            // sorter: (a, b) => a.Pair.localeCompare(b.Pair),
            render: (text) => {
                if (text && text == "Y") {
                    return <Tag color="geekblue">Pair</Tag>
                }
            }
        },
        {
            title: () => {
                return <Tooltip title="如果某个特定日期和价位的期权在当日被连续多笔小单成交时会触发此标记，这意味着主力想要买入/卖出这个期权，但是不希望一笔数额太大被人发现，所以分成多笔连续成交">Repeat<InfoCircleOutlined /> </Tooltip>
            },
            dataIndex: 'Repeat',
            // sorter: (a, b) => a.Repeat.localeCompare(b.Repeat),
            render: (text) => {
                if (text && text == "Y") {
                    return <Tag color="green">Repeat</Tag>
                }
            }
        }

    ];

    // 2 notionals
    const columnsNotional = [
        {
            title: 'Symbol',
            dataIndex: 'Symbol',            
            sorter: (a, b) => a.Symbol.localeCompare(b.Symbol)
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            sorter: (a, b) => a.Type.localeCompare(b.Type)
        }, 
        {
            title: 'Side',
            dataIndex: 'Side',
            sorter: (a, b) => a.Side.localeCompare(b.Side)
        },  
        {
            title: 'Trade Qty',
            dataIndex: 'TradeQty',
            sorter: (a, b) => a.TradeQty - b.TradeQty
        },  
       
        {
            title: 'Trade Notional',
            dataIndex: 'TradeNotional',
            sorter: (a, b) => a.TradeNotional - b.TradeNotional,
            render: (text) => {
                if (text) {
                    // num to k/ m
                    let newNum = Intl.NumberFormat('en-US', {
                        notation: "compact",
                        maximumFractionDigits: 1
                      }).format(text);
                    return newNum
                }
            }
        },             
    ];
    // 3 repeat 
    const columnsRepeat = [
        {
            title: 'Symbol',
            dataIndex: 'Symbol',            
            sorter: (a, b) => a.Symbol.localeCompare(b.Symbol)
        },
        {
            title: 'Option Expiration',
            dataIndex: 'OptionExpiration',
            sorter: (a, b) => new Date(b.OptionExpiration) - new Date(a.OptionExpiration)
        },   
        {
            title: 'Type',
            dataIndex: 'Type',
            sorter: (a, b) => a.Type.localeCompare(b.Type)
        }, 
        {
            title: 'Strike',
            dataIndex: 'Strike',
            sorter: (a, b) => a.Strike - b.Strike
        },  
        {
            title: 'Spot Price',
            dataIndex: 'SpotPrice',
            sorter: (a, b) => a.SpotPrice - b.SpotPrice
        },
        {
            title: 'Open Interest',
            dataIndex: 'OpenInterest',
            sorter: (a, b) => a.OpenInterest - b.OpenInterest
        },   
        {
            title: 'Trade Qty',
            dataIndex: 'TradeQty',
            sorter: (a, b) => a.TradeQty - b.TradeQty
        },  
    
        {
            title: 'Trade Notional',
            dataIndex: 'TradeNotional',
            sorter: (a, b) => a.TradeNotional - b.TradeNotional,
            render: (text) => {
                if (text) {
                    // num to k/ m
                    let newNum = Intl.NumberFormat('en-US', {
                        notation: "compact",
                        maximumFractionDigits: 1
                      }).format(text);
                    return newNum
                }
            }
        },  
    ];

   
    // load list data
    const onSearch = async (page, preload=false) => {
        // console.log(">>page", page)
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }
        NProgress.start();
      
        const res = await getOptionData(page, preload);        

        if (res.data && res.data.data && res.data.data.length) {
            // console.log(">>res.data", res.data)
            const fullCount = res.data.data[0]["full_count"]
            setTotalItem(fullCount-100);
            setList(res.data.data);
            setMessage(res.data.message);
            
            // setSelectList(res.data);                    
        } else {
            // likely error / no data found 
            setTotalItem();
            setList([]);
            // setSelectList([]);
            setMessage("没有相关数据，请尝试查询其他日期或股票代码");
            
            // if (res.data && res.data.message) {
            //     // console.log(">>error", res.data.message)
            //     setMessage(res.data.message);
            // } else {
            //     setMessage("");
            // }
        }

        NProgress.done();       
    };

    const getOptionData = (page, preload=false) => {
        if (typeof page != "number") {
            page = 0
        }            
        let formatDate = date.format('YYYY-MM-DD');
        if (preload && window.SERVER_DATA) { // use MF date
            formatDate = window.SERVER_DATA['MF_LAST_DAY']
            // console.log(">>",formatDate)
        }

        // only works on 4/5 
        // return HttpGet(`/api/v3/optiontrade?date=2023-04-05&orderby=time&desc=true&page=0&rowcount=100`);
        let url = `/api/v3/optiontrade?date=${formatDate}&orderby=${frequency}&desc=${!top}&page=${page}&rowcount=100`;
        if (tickers) {
            url += `&ticker=${tickers}`;
        }    
        
        // filter simple * 4 is? only add when not any 
        if (isSingleLeg && isSingleLeg != "any") {
            url += `&is_single=${isSingleLeg}`;
        }

        // if (specialType && specialType.length) {
        //     // console.log(">>specialType",specialType)
        //     if (specialType.includes("unusual")) {
        //         url += `&is_unusual=yes`
        //     }
        //     if (specialType.includes("paired")) {
        //         url += `&is_paired=yes`
        //     }
        //     if (specialType.includes("repeated")) {
        //         url += `&is_repeated=yes`
        //     }
        // }
        // old logic 
        if (isUnusual && isUnusual != "any") {
            url += `&is_unusual=${isUnusual}`
        }
        if (isPaired && isPaired != "any") {
            url += `&is_paired=${isPaired}`
        }
        if (isRepeated && isRepeated != "any") {
            url += `&is_repeated=${isRepeated}`
        }

        if (callPut && callPut != "any") {
            url += `&option_type=${callPut}`
        }
        if (!itm) {
            url += `&allow_itm=false`
        }
        if (!atm) {
            url += `&allow_atm=false`
        }
        if (!otm) {
            url += `&allow_otm=false`
        }

        // day/week
        if (frequencyTime == "weekly") {
            url += `&is_assured=true`
        }

        // filter compare * 5 
        // TODO: removed expirationCompare
        // if (expirationCompare != "any") {
        //     url += `&expiration_date_comparison=${expirationCompare}`;
        //     const expirationCompareDateFormated = expirationCompareDate.format('YYYY-MM-DD');
        //     if (expirationCompareDateFormated) {
        //         url += `&expiration_date=${expirationCompareDateFormated}`;
        //     }
        // }
        // if (expirationCompareDate) {
        //     const expirationCompareDateFormated = expirationCompareDate.format('YYYY-MM-DD');
        //     if (expirationCompareDateFormated) {
        //         url += `&expiration_date=${expirationCompareDateFormated}`;
        //     }
        // }
        if (expirationDTE && expirationDTE > 0) {
            // 10 < 180: start=0; end = n 
            // > 180: start =180; end =-1 
            if (expirationDTE <= 180) {
                url += `&expiration_date_start=0&expiration_date_end=${expirationDTE}`;
            } else {
                url += `&expiration_date_start=180`
            }
        }
        if (strikeCompareLow > 0) {
            url += `&strike_low=${strikeCompareLow}`
        }
        if (strikeCompareHigh > 0) {
            url += `&strike_high=${strikeCompareHigh}`
        }         
        if (openInterestLow > 0) {
            url += `&open_interest_low=${openInterestLow}`
        }
        if (openInterestHigh > 0) {
            url += `&open_interest_high=${openInterestHigh}`
        } 
        // trade_qty_low
        if (tradeQtyLow > 0) {
            url += `&trade_qty_low=${tradeQtyLow}`
        } 
        if (tradeQtyHigh > 0) {
            url += `&trade_qty_high=${tradeQtyHigh}`
        }
        if (tradeNotionalLow > 0) {
            url += `&trade_notional_low=${tradeNotionalLow}`
        } 
        if (tradeNotionalHigh > 0) {
            url += `&trade_notional_high=${tradeNotionalHigh}`
        } 
       
        //new logic compare low high
        // if (strikeCompareNum && strikeCompareNum > 0) {
        //     url += `&strike=${strikeCompareNum}`
        // }
        // if (openInterestCompareNum && openInterestCompareNum > 0) {
        //     url += `&open_interest=${openInterestCompareNum}`;
        // }
        // if (tradeQtyCompareNum && tradeQtyCompareNum > 0) {
        //     url += `&trade_qty=${tradeQtyCompareNum}`;
        // }
        // if (tradeNotionalCompareNum && tradeNotionalCompareNum > 0) {
        //     url += `&trade_notional=${tradeNotionalCompareNum}`;
        // }

        //old logic w compare 
        // if (strikeCompare != "any" && strikeCompareNum) {
        //     url += `&strike_comparison=${strikeCompare}`;
        //     if (strikeCompareNum > 0) {
        //         url += `&strike=${strikeCompareNum}`;
        //     }
        // }
        // if (openInterestCompare != "any" && openInterestCompareNum) {
        //     url += `&open_interest_comparison=${openInterestCompare}`;
        //     if (openInterestCompareNum > 0) {
        //         url += `&open_interest=${openInterestCompareNum}`;
        //     }
        // }
        // if (tradeQtyCompare != "any" && tradeQtyCompareNum) {
        //     url += `&trade_qty_comparison=${tradeQtyCompare}`;
        //     if (tradeQtyCompareNum > 0) {
        //         url += `&trade_qty=${tradeQtyCompareNum}`;
        //     }
        // }
        // if (tradeNotionalCompare != "any" && tradeNotionalCompareNum) {
        //     url += `&trade_notional_comparison=${tradeNotionalCompare}`;
        //     if (tradeNotionalCompareNum > 0) {
        //         url += `&trade_notional=${tradeNotionalCompareNum}`;
        //     }
        // }
       
        // console.log(">>url", url)
        
        return HttpGet(url);
    }   
  

    // 1 block trade
    // test cases = 
    // 1 call + buy then change to sell put 
    // 1 call + buy +  qqq; then delete qqq
    // CALL/PUT
    // const onSetType = (value) => {        
    //     if (value) {
    //         setType(value);
    //         let newList = list;
    //         if (newList) {
    //             newList = newList.filter(v => v.Type == value.toLocaleUpperCase())
    //         }
    //         if (side) {
    //             newList = newList.filter(v => v.Side == side)
    //         }
    //         if (filterTicker) {
    //             newList = newList.filter(v => v.Symbol?.indexOf(filterTicker.toLocaleUpperCase()) > -1)
    //         }
    //         setSelectList(newList);
    //     }
    // };
    const onSetType = (value, list, side, filterTicker, tab) => {        
        if (value) {                       
            let newList = list;
            if (newList) {
                newList = newList.filter(v => v.Type == value.toLocaleUpperCase())
            }
            if (side) {
                newList = newList.filter(v => v.Side == side)
            }
            if (filterTicker) {
                newList = newList.filter(v => v.Symbol?.indexOf(filterTicker.toLocaleUpperCase()) > -1)
            }
            if (tab == "block") {
                setType(value);
                setSelectList(newList);
            }
        }
    };
    const onSetSide = (value, list, type, filterTicker, tab) => {
        if (value) {
            let newList = list;
            if (newList) {
                newList = newList.filter(v => v.Side == value)
            }
            if (type) {
                newList = newList.filter(v => v.Type == type.toLocaleUpperCase())
            }
            if (filterTicker) {
                newList = newList.filter(v => v.Symbol?.indexOf(filterTicker.toLocaleUpperCase()) > -1)
            }
            if (tab == "block") {
                setSide(value);
                setSelectList(newList);
            }
        }
    };  
    const handleInputChange = (target, list, selectList, type, side, tab) => {
        let newList = list;
        if (type || side) {
            newList = selectList;            
        }
        if (target && newList) {
            newList = newList.filter(v => v.Symbol?.indexOf(target.toLocaleUpperCase()) > -1)
        }
        if (!target) { // case when change from qqq to empty 
            newList = list;
            if (type) {
                newList = newList.filter(v => v.Type == type.toLocaleUpperCase())
            } 
            if (side) {
                newList = newList.filter(v => v.Side == side)
            }
        }
        if (tab == "block") {
            setFilterTicker(target);
            setSelectList(newList);
        }
    }

    const handleKeypress = e => {        
        if(e.key === 'Enter' || e.code === "NumpadEnter"){
            onSearch()
        }
    };

    const renderSpecialTypePlaceholder = () => {
        // All if none is selected 
        let display = "";
        if (!isUnusual && !isPaired && !isRepeated) {
            display = "All";
        } else {
            // console.log(">>", isUnusual)
            if (isUnusual == "yes") {
                display += "异常"
            } else if (isUnusual == "no") {
                display += "非异常"
            }
            if (isPaired == "yes") {
                if (display.length) { display += ", " }
                display += "配对"
            } else if (isPaired == "no") {
                if (display.length) { display += ", " }
                display += "非配对"
            }
            if (isRepeated == "yes") {
                if (display.length) { display += ", " }
                display += "重复"
            } else if (isRepeated == "no") {
                if (display.length) { display += ", " }
                display += "非重复"
            }
        }
        return display
    }

    const renderTradeDataPlaceholder = () => {
        if (!strikeCompareLow && !strikeCompareHigh && !openInterestLow && !openInterestHigh && !tradeQtyLow && !tradeQtyHigh && !tradeNotionalLow && !tradeNotionalHigh) {
            return "All"
        } else {
            return "已选择.."
        }
    }

    const renderValuePlaceholder = () => {
        let display = "";
        if(otm && atm && itm) {
            display = "All"
        } else { // 1/2 of 3 selection 
            if (atm) {
                if (display.length) { display += ", " }
                display += "平值ATM"
            }
            if (itm) {
                if (display.length) { display += ", " }
                display += "实值ITM"
            }
            if (otm) {
                if (display.length) { display += ", " }
                display += "虚值OTM"
            }
        }
        return display
    }

    return (
        <div className='optiondata'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={4}>
                            {intl.get('products.product7.title')}
                        </Col>
                        <Col className='form-content' span={20}>
                            <div className='content-item content-item-date'>
                                {/* <span className='label'>{intl.get('products.date')}</span> */}
                                <DatePicker                                    
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date}
                                    onChange={date => setDate(date)}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                />
                            </div>

                            <div className='select-button select-button-frequency'>
                                <span className={frequency === 'time' ? 'active' : ''} onClick={() => { setFrequency('time'); } }>
                                    {intl.get('products.time')}</span>
                                <span className={frequency === 'tradenotional' ? 'active' : ''} onClick={() => {setFrequency('tradenotional'); }}>
                                    {intl.get('products.notional')}</span>
                            </div>

                            <div className='select-button'>
                                <span className={top ? 'active' : ''} onClick={() => setTop(true)}>
                                    {intl.get('products.asc')}
                                </span>
                                <span className={top ? '' : 'active'} onClick={() => setTop(false)}>
                                    {intl.get('products.desc')}
                                </span>
                            </div>                            
                            <div className={frequencyTime === 'daily' ? 'content-item content-item-ticker' : 'content-item content-item-ticker hide'}>
                                <span className='label'>股票</span>
                                <Input placeholder="" defaultValue={tickers} onChange={e => setTickers(e.target.value.toUpperCase())} onKeyPress={handleKeypress}/>
                            </div>

                            <Button className='button' onClick={onSearch}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>

                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://medium.com/@quantunicorn/期权大单使用指南-b6b4a5d0e379' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product7.subTitle')}</Col>                        
                    </Row>
                    <Row>
                        <Col className='form-content form-content-left' span={24}>
                            <div className='content-item content-item-frequency'>
                                {/* select week then hide ticker */}
                                <div className='select-button'>
                                    <span className={frequencyTime === 'daily' ? 'active' : ''} onClick={() => setFrequencyTime('daily')}>当日所有期权</span>
                                    <span className={frequencyTime === 'weekly' ? 'active' : ''} onClick={() => setFrequencyTime('weekly')}>每日精选</span>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='form-container form-newFilter'>
                        <Col span={2}>
                            <div>策略</div>                            
                            <Select className="select-simple" defaultValue="All" options={[{value: "any", label: "All"}, {value: "yes", label: "单腿"}, {value: "no", label: "混合"}]} onChange={value => setIsSingleLeg(value)}></Select>                            
                        </Col>
                        <Col span={2}>
                            <div>类型</div>                            
                            <Select className="select-simple" defaultValue="All" options={[{value: "any", label: "All"}, {value: "call", label: "看涨call"}, {value: "put", label: "看跌put"}]} onChange={value => setCallPut(value)}></Select>
                        </Col>
                        <Col span={5}>
                            <div>交易数据</div>                            
                            <Select
                                id="select-1"
                                className="select-trade-data"
                                style={{width: 260}}
                                placeholder={renderTradeDataPlaceholder()}
                                options={[{value: "any", label: "any"}]}                                
                                dropdownRender={(menu) => (
                                    <div className="dropdown-trade">
                                        <p>
                                            <label>行权价格 (美元)</label>
                                            <InputNumber placeholder="0" className="input-simple" onChange={value => setStrikeCompareLow(value)} value={strikeCompareLow} />
                                            <span className="dropdown-splitter">-</span>                                             
                                            <InputNumber placeholder="+∞" className="input-simple" onChange={value => setStrikeCompareHigh(value)} value={strikeCompareHigh}/>
                                        </p>
                                        <p>
                                            <label>未平仓合约 (笔)</label>
                                            <InputNumber placeholder="0" className="input-simple" onChange={value => setOpenInterestLow(value)} value={openInterestLow}/>
                                            <span className="dropdown-splitter">-</span>                                             
                                            <InputNumber placeholder="+∞" className="input-simple" onChange={value => setOpenInterestHigh(value)} value={openInterestHigh}/>
                                        </p>
                                        <p>
                                            <label>交易笔数 (笔)</label>
                                            <InputNumber placeholder="0" className="input-simple" onChange={value => setTradeQtyLow(value)} value={tradeQtyLow} />
                                            <span className="dropdown-splitter">-</span>                                             
                                            <InputNumber placeholder="+∞" className="input-simple" onChange={value => setTradeQtyHigh(value)} value={tradeQtyHigh} />
                                        </p>
                                        <p>
                                            <label>交易额 (M)</label>
                                            <InputNumber placeholder="0" className="input-simple" onChange={value => setTradeNotionalLow(value)} value={tradeNotionalLow} />
                                            <span className="dropdown-splitter">-</span>                                             
                                            <InputNumber placeholder="+∞" className="input-simple" onChange={value => setTradeNotionalHigh(value)} value={tradeNotionalHigh}/>
                                        </p>
                                        <Button className='button button-cancel' 
                                            onClick={()=> {
                                                // cancel = reset trade data 
                                                setStrikeCompareLow();
                                                setStrikeCompareHigh();
                                                setOpenInterestLow();
                                                setOpenInterestHigh();
                                                setTradeQtyLow();
                                                setTradeQtyHigh();
                                                setTradeNotionalLow();
                                                setTradeNotionalHigh();
                                            }}
                                        >取消</Button>

                                        <Button className='button button-confirm' 
                                            onClick={(ev) => {  
                                                ev.target.closest(".ant-select-dropdown").classList.add('ant-select-dropdown-hidden');
                                            }}
                                        >确认</Button>
                                    </div>
                                )}
                            >                                
                            </Select>
                            {/* <Select className="select-simple" defaultValue="any" options={[{value: "any", label: "any"}, {value: "call", label: "看涨call"}, {value: "put", label: "看跌put"}]} onChange={value => setCallPut(value)}></Select> */}
                        </Col>
                        <Col span={5}>
                            <div>特殊类型</div>                            
                            <div className="select-multiple-container">
                                {/* <Select placeholder="All" style={{width: 214}} className="select-multiple" showSearch={false} mode="multiple" options={[{value: "unusual", label: "异常Unusual"}, {value: "paired", label: "配对Paired"}, {value: "repeated", label: "重复Repeated"}]} onChange={value => setSpecialType(value)}></Select>
                                <DownOutlined className="select-multiple-arrow"/> */}
                                <Select
                                    style={{width: 260}}
                                    placeholder={renderSpecialTypePlaceholder()}
                                    options={[{value: "any", label: "any"}]}                                
                                    dropdownRender={(menu) => (
                                    <div className="dropdown-special">
                                        <p>
                                            <h4>异常</h4>
                                            <Radio.Group onChange={({ target: { value } }) => setIsUnusual(value)} value={isUnusual}>
                                                <Radio value={"yes"}>异常</Radio>
                                                <Radio value={"no"}>非异常</Radio>
                                                <Radio>不限</Radio>
                                            </Radio.Group>
                                        </p>
                                        <p>
                                            <h4>配对</h4>
                                            <Radio.Group onChange={({ target: { value } }) => setIsPaired(value)} value={isPaired}>
                                                <Radio value={"yes"}>配对</Radio>
                                                <Radio value={"no"}>非配对</Radio>
                                                <Radio >不限</Radio>
                                            </Radio.Group>
                                        </p>
                                        <p>
                                            <h4>重复交易</h4>
                                            <Radio.Group onChange={({ target: { value } }) => setIsRepeated(value)} value={isRepeated}>
                                                <Radio value={"yes"}>重复</Radio>
                                                <Radio value={"no"}>非重复</Radio>
                                                <Radio >不限</Radio>
                                            </Radio.Group>
                                        </p>
                                        <Button className='button button-cancel'
                                            onClick={() => {
                                                // cancel to reset data 
                                                setIsUnusual()
                                                setIsPaired()
                                                setIsRepeated()
                                            }}  
                                        >取消</Button>
                                        <Button className='button button-confirm'  
                                            onClick={(ev) => {  
                                                ev.target.closest(".ant-select-dropdown").classList.add('ant-select-dropdown-hidden');
                                            }}
                                        >确认</Button>
                                    </div>
                                    )}
                                >                                
                                </Select>
                            </div>                                                                              
                        </Col>
                        <Col span={3}>
                            <div>价值</div>                            
                            {/* <Select defaultValue="All" className="select-simple" 
                                options={[{value: "any", label: "All"}, {value: "atm", label: "平值ATM"}, {value: "itm", label: "实值ITM"}, {value: "otm", label: "虚值OTM"}]} 
                                onChange={value => setAtmOtm(value)}>
                            </Select> */}
                             <Select
                                    style={{width: 170}}
                                    placeholder={renderValuePlaceholder()}
                                    options={[{value: "any", label: "any"}]}                                
                                    dropdownRender={(menu) => (
                                    <div className="dropdown-special">
                                        <p>
                                            <Checkbox defaultChecked checked={atm} disabled={(atm && !itm && !otm)} onChange={(data) => {
                                                if (itm || otm){
                                                    setAtm(data.target.checked)
                                                }
                                            }}>平值ATM</Checkbox>
                                        </p>
                                        <p>
                                            <Checkbox defaultChecked checked={itm} disabled={(itm && !atm && !otm)} onChange={(data) => {
                                                if (atm || otm){
                                                    setItm(data.target.checked)
                                                }
                                                
                                            }}>实值ITM</Checkbox>
                                        </p>
                                        <p>
                                            <Checkbox defaultChecked checked={otm} disabled={(otm && !atm && !itm)} onChange={(data) => {
                                                if (atm || itm){
                                                    setOtm(data.target.checked)
                                                }
                                            }}>虚值OTM</Checkbox>
                                        </p>


                                        {/* <Checkbox.Group 
                                            options={[{value: "atm", label: "平值ATM"}, {value: "itm", label: "实值ITM"}, {value: "otm", label: "虚值OTM"}]} 
                                            defaultValue={['atm', 'itm', 'otm']} 
                                            onChange={(checkedValues)=>{
                                                // validate 
                                                if (checkedValues.length) {
                                                    setAtmOtm(checkedValues)
                                                } else {
                                                    // must select one 
                                                    console.log(">>", "must select one")
                                                }
                                                
                                                console.log(">>", checkedValues.target, checkedValues)

                                            }} 
                                        /> */}

                                        <Button className='button button-cancel'
                                            onClick={() => {
                                                // cancel to reset data 
                                                setOtm(true);
                                                setAtm(true);
                                                setItm(true);
                                            }}  
                                        >取消</Button>
                                        <Button className='button button-confirm'  
                                            onClick={(ev) => {  
                                                ev.target.closest(".ant-select-dropdown").classList.add('ant-select-dropdown-hidden');
                                            }}
                                        >确认</Button>
                                    </div>
                                    )}
                                >                                
                                </Select>
                            
                        </Col>
                        <Col span={3}>
                            <div>行权日期距交易日期</div>                            
                            {/* <DatePicker
                                placeholder="All"
                                allowClear={true}
                                format='YYYY-MM-DD'
                                onChange={date => setExpirationCompareDate(date)}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                            /> */}
                            {/* change to DTE  */}
                            <Select style={{width: 130}} defaultValue="All" className="select-dte" options={[{value: 0, label: "All"}, {value: 7, label: "7天以内"}, {value: 14, label: "14天以内"}, {value: 21, label: "21天以内"},{value: 28, label: "28天以内"}, {value: 60, label: "60天以内"}, {value: 90, label: "90天以内"},{value: 180, label: "180天以内"}, {value: 181, label: "180天以上"},]} onChange={value => setExpirationDTE(value)}></Select>

                        </Col>
                    </Row>

                </Col>
            </div>

            {list && list.length ?
                <div className='product-result table-item'>
                    <p>{message}</p>
                    {/* <div className='table-header'>
                        <div className='table-input'>
                            <span className='label'>Option Type</span>
                            <Select
                                defaultValue=""
                                style={{width: 70}}
                                onChange={ value => onSetType(value, list, side, filterTicker, "block") }
                            >
                                <Option value="call">Call</Option>
                                <Option value="put">Put</Option>
                            </Select>
                            <span className='label' style={{marginLeft: "30px"}}>Side</span>
                            <Select
                                defaultValue=""
                                style={{width: 70}}
                                onChange={ value => onSetSide(value, list, type, filterTicker, "block") }
                            >
                                <Option value="Buy">Buy</Option>
                                <Option value="Sell">Sell</Option>
                            </Select>
                        </div>
                        <div className='table-input'>
                            <span>{intl.get('products.product5.globalSearch')}</span>
                            <Input 
                                className="product-result-search" 
                                placeholder={`${intl.get('products.ticker')}`} 
                                onChange={e => handleInputChange(e.target.value, list, selectList, type, side, "block")} 
                            />
                        </div>
                    </div> */}
                    <Table 
                        size="small" 
                        columns={columnsBlockTrade} 
                        dataSource={list} 
                        pagination={{ pageSize: 100, pageSizeOptions: [100], total: totalItem, onChange: onSearch }}
                    />                                            
                </div>
                
                : <Empty text={message} />
            }    
        </div>
    )
}

export default OptionData;