import React from "react";
import silverIcon from '@/asset/home/sets/silver.png';
import goldIcon from '@/asset/home/sets/gold.png';
import platinumIcon from '@/asset/home/sets/platinum.png';

import { useStore } from "@/hooks/useStore";
import { STORE_USER } from "@/stores/createStores";
import { UserLevel } from "@/constant/level";

const LevelBlock = () => {
    const user = useStore(STORE_USER);

    return (
        <div className='level'>
            {user.level === UserLevel.gold ? <>
                <img src={goldIcon} alt='' />
                <span>Gold</span>
            </> : null}

            {user.level === UserLevel.silver ? <>
                <img src={silverIcon} alt='' />
                <span>Silver</span>
            </> : null}

            {user.level === UserLevel.platinum ? <>
                <img src={platinumIcon} alt='' />
                <span>Platinum</span>
            </> : null}

            {user.level === UserLevel.silverAlgo ? <>
                <img src={silverIcon} alt='' />
                <span>Silver+Algo</span>
            </> : null}

            {user.level === UserLevel.goldAlgo ? <>
                <img src={goldIcon} alt='' />
                <span>Gold+Algo</span>
            </> : null}

            {user.level === UserLevel.algo ? <>
                <img src={silverIcon} alt='' style={{filter: "saturate(5)", width: "28px" }}/>
                <span>Algo</span>
            </> : null}


            {user.level === UserLevel.free ? <span>Free</span> : null}
        </div>
    )
}

export default LevelBlock