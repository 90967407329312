import React from "react";
import comment1 from '../../../../asset/home/comment1.png';
import comment2 from '../../../../asset/home/comment2.png';
import './index.less';


const CommentsBlock = () => {
    const column = [
        [
            {
                comment: ['QU是我唯一付费订阅的金融服务。我现在做股票交易非常依赖QU， 节省很多时间， 直观清晰 ,可操作性强，稳定提高操作准确性。 越是大股票我越会依赖资金流。 比如连续几天排在资金流前10，甚至几周排名都在前面，我会比较放心的做多或者加大仓位。', "QU CEO 每周两次的复盘和对当前股市的分析都是高质量的分享， 非常有价值。"],
                commentator: '@Sharon',
                position: '资深专业投资人'
            },
            {
                comment: ['QU的产品思路设计清奇，大道至简。我非常赞赏QU创始人Sean从高能物理研究跨界到金融量化领域的创新，很多华尔街机构的量化模型都是具有数学物理背景的研发人员打造出来的。'],
                commentator: '@Brian',
                position: '华尔街资深分析师'
            }
        ],
        [
            {
                comment: ['QU资金流系统我已经使用接近一年，现在已经成为我交易不可或缺的一项工具。', '每日通过对QU100标的的审查，可以让我快速的得知市场的热点板块，以及资金集中做多的股票。结合其他的指标，可以有效的选出值得买入的股票。通过大盘资金流以及GEX的分析，可以很好的掌握大盘的动向，从而适当的加减仓位或者对冲。', '最后，QU的团队也非常专业。每周两次的宏观与个股分享也提供了很好的思路。综合而言，非常推荐QU资金流系统给每一位投资人。'],
                commentator: '@Frank',
                position: '基金经理, Ph.D.'
            },
            {
                comment: ['QU带给大家的是一个高度集合化的、多维度的、针对金融市场“信息不对称理论”的量化工具。大家可以根据自己的生活、工作状态和不同交易习惯，结合QU的信号，果断做出高概率的正确决策。', '在接触QU的产品之前，我先认识的是QU的创始人Sean，他给我的像是“我最得意的博士弟子”的那种感觉，有着超越他年龄的谦虚、沉稳和自信。我期待QU更多的创新，让投资者受益。'],
                commentator: 'Professor M. N.',
                position: '现苏黎世联邦理工学院教授、前宾夕法尼亚大学教授'
            }
        ],
        [
            {
                comment: ['QU的资金流产品已经成为我自己每日交易操作和复盘必不可少的工具。QU100不仅帮助聚焦每日潜在操作热点标的， 也为判断标的趋势的强弱并提供了重要参考。', '大盘资金流，GEX，以及QU创始人每周的会员分享与讨论对于洞察市场整体环境有极大助益，同时也提供了很多短线高回报的盈利机会。 QU的产品，到目前为止是我个人使用频率最高的工具之一。'],
                commentator: '@易姚',
                position: '专业投资人、《美股大牛眼》微信公众号博主'
            },
            {
                comment: ['好的交易决策系统往往需要对多个独立正交的分析工具进行有机组合。QU资金流分析是基于对市场交易数据深刻理解的量化创新，是具有高度实用价值的独立分析系统，在投资分析中异军突起，开创了基本面和传统技术面之外的新的分析维度。'],
                commentator: '@资深缠友',
                position: '企业高管, Ph.D.'
            }
        ]
    ];

    return (
        <div className='comments-block-content'>
            <div className='container'>
                {
                    column.map((columnItem, columnIndex) =>
                        <div className='column' key={columnIndex}>
                            {
                                columnItem.map((rowItem, rowIndex) =>
                                    <div className='row' key={rowIndex}>
                                        <img src={(columnIndex + rowIndex) % 2 ? comment2 : comment1} alt='' />
                                        <div className='comment'>{rowItem.comment.map((text, index) => <p key={index}>{text}</p>)}</div>
                                        <div className='commentator'>{rowItem.commentator}</div>
                                        <div className='position'>{rowItem.position}</div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default CommentsBlock;