import React from "react";
import intl from 'react-intl-universal';
import { RightCircleOutlined } from '@ant-design/icons'
import './index.less';


const NewsBlock = () => {
    const news = [
        {
            title: intl.getHTML('home.news.title1'),
            color: 'rgba(108, 74, 255, 1)',
            tagName: intl.getHTML('home.news.article')
        },
        {
            title: intl.getHTML('home.news.title2'),
            // color: 'rgba(162, 134, 114, 1)',
            color: 'rgba(108, 74, 255, 1)',
            tagName: intl.getHTML('home.news.article')
        },
        {
            title: intl.getHTML('home.news.title3'),
            color: 'rgba(108, 74, 255, 1)',
            tagName: intl.getHTML('home.news.article')
        },
    ];



    return (
        <div className='news-block'>
            <div className='news-content'>
                {
                    news.map((v, index) => {
                        return (
                            <div className='new-item' key={index}>
                                <div className='description'>{v.title}</div>
                                {/* <div className='tag' style={{ background: v.color }}>{v.tagName}</div> */}
                            </div>
                        )
                    })
                }
            </div>
            <div className='view-more'>
                <a target='_blank' href='https://quantunicorn.medium.com'>
                    <span>{intl.get('home.news.button')}</span>
                    <RightCircleOutlined />
                </a>
            </div>
        </div>
    )
}

export default NewsBlock;
