import { Button, DatePicker, Row, Col, Table } from "antd";
import React, { useState } from "react";
import { marketData } from "@/service/market";
import Empty from '../empty'
import moment from 'moment';
import { getColor } from "@/utils/getColor";
import { useNavigate } from "react-router";
import intl from 'react-intl-universal';
import './index.less';

// 空状态
const initialList = {
    market: [],
    top10: [],
    bottom10: []
}

const Market = ({ NProgress }) => {
    const navigator = useNavigate();
    // frequency
    const [frequency, setFrequency] = useState('daily');
    // date
    const [date, setDate] = useState(window.SERVER_DATA ? moment(window.SERVER_DATA['MF_LAST_DAY']):moment());
    // 返回list数据
    const [list, setList] = useState(initialList);
    // 返回信息
    const [message, setMessage] = useState('');

    const columns = [
        {
            title: intl.get('products.product1.marketIndex'),
            dataIndex: 'ticker',
            width: '40%',
            render: (ticker) => {
                return <div className='purple' onClick={() => { navigator(`/products#capitalflow?ticker=${ticker}&date=${date.format('YYYY-MM-DD')}`) }}>{ticker}</div>;
            }
        },
        {
            title: intl.get('products.capitalFlowDirection'),
            dataIndex: 'sentiment',
            width: '60%',
            render: (text) => getColor(text),
        },
    ];

    const rankColumns = [
        {
            title: intl.get('products.rank'),
            width: '50%',
            render: (_, record, index) => {
                return index + 1;
            }
        },
        {
            title: intl.get('products.ticker'),
            width: '50%',
            render: (_, record) => {
                return <div className='purple' onClick={() => { navigator(`/products#capitalflow?ticker=${record}&date=${date.format('YYYY-MM-DD')}`) }}>{record}</div>;
            }
        },
    ];

    // 查询
    const search = async () => {
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }

        NProgress.start();
        if (frequency && date) {
            const res = await marketData(frequency, date.format('YYYY-MM-DD'));
            if (res && res.data && res.data.data) {
                setList(res.data.data);
            } else {
                setList(initialList);
            }
            if (res && res.data && res.data.message) setMessage(res.data.message);
        } else {
            setList(initialList);
        }
        NProgress.done();
    };

    return (
        <div className='market'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={12}>{intl.get('products.product1.title')}</Col>
                        <Col className='form-content' span={12}>
                            <div className='content-item'>
                                <span className='label'>{intl.get('products.date')}</span>
                                <DatePicker
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date}
                                    onChange={date => setDate(date)}
                                    getPopupContainer={() => document.getElementById('product')}
                                />
                            </div>

                            <div className='select-button'>
                                <span className={frequency === 'daily' ? 'active' : ''} onClick={() => setFrequency('daily')}>{intl.get('products.daily')}</span>
                                <span className={frequency === 'weekly' ? 'active' : ''} onClick={() => setFrequency('weekly')}>{intl.get('products.weekly')}</span>
                            </div>

                            <Button className='button' onClick={search}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://quantunicorn.medium.com/%E5%85%A8%E5%B8%82%E5%9C%BA%E4%BF%A1%E5%8F%B7%E6%A6%82%E8%A7%88%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-43120980377' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product1.subTitle')}</Col>
                    </Row>
                </Col>
            </div>

            {list && list.market && list.market.length ?
                <>
                    <div className='update-time'>{message}</div>
                    <Row className='table' gutter={16}>
                        <Col span={8}>
                            <div className='table-item'>
                                <div className='table-title'>{intl.get('products.product1.marketCapitalFlow')}</div>
                                <Table rowKey='ticker' size="small" columns={columns} dataSource={list?.market || []} pagination={false} />
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className='table-item'>
                                <div className='table-title'>{intl.get('products.product1.top10')}</div>
                                <Table rowKey={record => record} size="small" columns={rankColumns} dataSource={list?.top10 || []} pagination={false} />
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className='table-item'>
                                <div className='table-title'>{intl.get('products.product1.bottom10')}</div>
                                <Table rowKey={record => record} size="small" columns={rankColumns} dataSource={list?.bottom10 || []} pagination={false} />
                            </div>
                        </Col>
                    </Row>
                </> : <Empty message={message} />
            }
        </div>
    )
}

export default Market;
