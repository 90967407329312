import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Product1SvgIcon, Product2SvgIcon, Product3SvgIcon, Product4SvgIcon, Product5SvgIcon, Product6SvgIcon } from "../../../overview/icon";
import { OverviewIcon } from "./icon";
import intl from 'react-intl-universal';

const ProductMenu = () => {
    // 当前菜单
    const [current, setCurrent] = useState(["overview"]);
    const navigate = useNavigate();
    const location = useLocation();

    // 点击菜单改变hash
    const handleMenuClick = value => {
        // if (value.key === 'overview') {
        //     navigate('overview');
        //     return;
        // }
        window.location.hash = value.key;
    };

    // 根据hash选中菜单
    useEffect(() => {
        if(/^#capitalflow\?ticker=[A-Za-z0-9]+&date=\d{4}-\d{2}-\d{2}$/.test(location.hash)) {
            setCurrent(['capitalflow'])
            return;
        }
        setCurrent([location.hash.slice(1)])
    }, [location.hash])

    return (
        <Menu onClick={handleMenuClick} selectedKeys={current} mode="vertical">
            <Menu.Item key="overview" icon={<OverviewIcon />}>
                {intl.get('products.overviewTitle')}
            </Menu.Item>
            <Menu.Item key="market" icon={<Product1SvgIcon />}>
                {intl.get('products.product1.title')}
            </Menu.Item>
            <Menu.Item key="capitalflow" icon={<Product2SvgIcon />}>
                {intl.get('products.product2.title')}
            </Menu.Item>
            <Menu.Item key="multiflow" icon={<Product3SvgIcon />}>
                {intl.get('products.product3.title')}
            </Menu.Item>
            <Menu.Item key="markethistory" icon={<Product4SvgIcon />}>
                {intl.get('products.product4.title')}
            </Menu.Item>
            <Menu.Item key="qu100" icon={<Product5SvgIcon />}>
                {intl.get('products.product5.title')}
            </Menu.Item>
            <Menu.Item key="gex" icon={<Product6SvgIcon />}>
                {intl.get('products.product6.title')}
            </Menu.Item>
            
            <Menu.Item key="qualgo" icon={<Product4SvgIcon />}>
                {intl.get('products.product12.title')}
            </Menu.Item>    

            <Menu.Item key="unusualflow" icon={<Product3SvgIcon />}>
                {intl.get('products.product11.title')}
            </Menu.Item>
            
            <Menu.Item key="optiondata" icon={<Product3SvgIcon />}>
                {intl.get('products.product7.title')}
            </Menu.Item>
        </Menu>
    )
}

export default ProductMenu