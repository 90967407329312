import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import intl from 'react-intl-universal';
import Success from "../component/success";
import queryString from 'query-string';
import { useNavigate } from "react-router";
import { resetPassword, signIn } from "@/service/user";
import './index.less';

const Reset = () => {
    // 修改成功
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const { auth, email } = queryString.parse(window.location.search);
    console.log(auth)

    // 没有验证码跳转404
    useEffect(() => {
        if (!auth || !email) {
            navigate('/404');
        }
    }, [auth, email, navigate])

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        try {
            await resetPassword(values, auth);
            await signIn({ email: email, password: values.new_password });
            setSuccess(true);
        } catch (error) {
            message.error('出错了')
            // 邮箱未注册？密码不正确？
        }
    };

    return success ?
        <Success text={intl.get('signInLayout.reset.resetSuccessText')} />
        :
        <div className='reset'>
            <div className='title'>{intl.get('signInLayout.reset.title')}</div>
            <Form
                name="reset"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item
                    name="new_password"
                    rules={[
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9\W]{6,99999}$/,
                            message: intl.get('signInLayout.signUp.passwordInvalid'),
                        },
                        {
                            required: true,
                            message: intl.get('signInLayout.reset.passwordEmpty'),
                        },
                    ]}
                >
                    <Input.Password className="input" placeholder={intl.get('signInLayout.reset.passwordPlaceholder')} />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit" className='reset-btn'>
                        {intl.get('signInLayout.reset.submitBtn')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
}

export default Reset