import React from "react";
import './index.less'

const Privacy = () => {
    return (
        <div className='container privacy-policy'>
            <div className='title'>PRIVACY POLICY</div>
            <div className='text'>Effective Date: September 1st, 2021</div>
            <div className='text'>READ THIS PRIVACY POLICY CAREFULLY BEFORE USING OR OBTAINING ANY MATERIALS, INFORMATION, PRODUCTS OR SERVICES THROUGH THIS SITE.</div>
            <div className='text'>EleQuant Technology, Inc. is a Delaware corporation operating under the name Quant Unicorn (“Quant Unicorn” the “Company,” “we,” “us,” “our”). The Company takes your privacy seriously. The Company pledges to follow the provisions of this Privacy Policy in order to protect your privacy rights. This Privacy Policy forms part of the Quant Unicorn Terms of Service and is incorporated by reference therein.</div>
            <div className='text'>This Privacy Policy (“Policy”) outlines our general policy and practices for protecting your private information on the QuantUnicorn.com website and related services (collectively, the “Site”). It includes the types of information we gather, how we use it, and the choices individuals have regarding our use of their information. This Policy applies to all personal information received by us whether in electronic, written, or verbal format. Personal information is information that can be associated with a specific person and used to identify that person. Personal information does not include information that has been anonymized so that it does not identify a specific individual, which may be used for any legal purpose.</div>
            <div className='text'>The Company reserves the right to modify this Policy at any time and will do so from time to time. Each modification shall be effective upon its posting to the Site. Your continued use of the Site following any such modification constitutes your acceptance of any changes to this Policy. It is therefore important that you review this Policy regularly. If you have any questions concerning this Policy, please contact the Company at feedback@quantunicorn.com.</div>
            <div className='text'>1.Scope</div>
            <div className='text'>This Policy covers all of the Site. However, it does not apply to entities that the Company does not own or control including advertisers or developers of content. The Company may include third-party links on the Site. These third-party sites are governed by their own privacy policies and not this Policy. We therefore have no responsibility or liability for the content and activities of these third-party sites. Please check the privacy policy of any third-party site you interact with on or off the Site.</div>
            <div className='text'>2.Information Company Collects About You</div>
            <div className='text'>Each time a user accesses the Site they receive a “cookie” from us. We use cookies (small pieces of data stored for an extended period of time on a computer, mobile phone, or other device) to make the Site easier to use, and to protect both you and the Company. These cookies give the Company information about your use of the Site. You may remove or block cookies using the settings in your browser, but doing so may impact your ability to use the Site. We may also use pixels, widgets and other tools to gather such information to improve the experience of the website or mobile application.</div>
            <div className='text'>We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.</div>
            <div className='text'>When you access the Site from a computer, mobile phone, or other device, we may collect information from that device about your browser type, location, and IP address, as well as the pages you visit and the date and time you access the Site.</div>
            <div className='text'>We may keep track of some of the actions you take on the Site including the user profiles. We may also ask you about your personal and investing habits. Even if you do not provide this information, we may obtain it from your profile, your using habit, or the services you view or purchase. We may retain the details of connections or transactions you make on the Site.</div>
            <div className='text'>We may collect information on your use of the site, such as pages visited, links clicked, text entered, and mouse movements, as well as information more commonly collected such as the referring URL, browser, operating system, and IP address. We use publicly available sources to approximate your geographic region and Internet Service Provider based on your IP address. We also collect information on your usage of our Services. We collect information that may be personally identifiable, such as IP addresses. Also, you may choose to interact with the Site in a way that results in your providing Personal Information to us, such as giving us your name and email address when signing up for a free trial of the Services or creating an account to license the Services we provide.</div>
            <div className='text'>We may collect information from the ads you view when you use the Site. We may also keep track of links you click on in emails you receive from us. This is done to increase the relevancy of the ads you see.</div>
            <div className='text'>We may also gather information about you from the content other Users post on the Site. Some of this information may contain personal information.</div>
            <div className='text'>3.Information You Provide to Company</div>
            <div className='text'>As part of the process to become a user of Quant Unicorn, we require you to provide your username, email address, and a password. You may voluntarily provide other information including, but not limited to, mailing address, phone number, place of employment, past employment and education history, etc.</div>
            <div className='text'>4.How We Use Your Personal Information</div>
            <div className='text'>We use the information you are required to provide to become a user in order to insure you are over the age of eighteen (18). The Site is not meant to be used by anyone under the age of eighteen. If you are under eighteen, please do not attempt to register for Quant Unicorn or send us any personal information. If you believe someone has submitted information from someone under eighteen, contact us and we will remove the information as soon as possible.</div>
            <div className='text'>We may share non-personally identifying information we collect from your activity on the Site with other users, news agencies and other third parties in order for them to better understand investment trends and patterns specific to Quant Unicorn Users.</div>
            <div className='text'>We will use the information we collect to provide services and features to you. We will also use the information to measure and improve the Site, and to provide you with customer support.</div>
            <div className='text'>We may contact you with service-related announcements from time to time. We may also update you about new product offerings and communications relevant to your use of the Site. We may include Content in the emails we send to you. </div>
            <div className='text'>Certain software applications and applets transmit data to us. We may not make a formal disclosure if we believe our collection of and use of the information is the obvious purpose of the Site or its related application. If it is not obvious that we are collecting or using such information, the first time you provide the information we will disclose that we are collecting it from you.</div>
            <div className='text'>We may use the information we collect to prevent potential illegal activities. We also use a variety of methods to detect and address anomalous activity and screen content to prevent abuse.</div>
            <div className='text'>5.How We Share Your Information</div>
            <div className='text'>The Company may share your information with third parties when we believe doing so is permitted by you, reasonably necessary to offer our services, or when legally required to do so. We will not share your information with third parties in a way we think violates your privacy. </div>
            <div className='text'>The Company may be sold, sell or buy businesses or assets of businesses, or merge with another business. In such transactions, personal information generally is one of the transferred business assets. Also, in the event that the Company, a line of business of the Company, or substantially all of our assets are transferred, personal information may be one of the transferred assets. If any such change in ownership or control of your personal information will take place, we will make a reasonable effort to provide notice on the Site and via your most recently provided email address.</div>
            <div className='text'>6.Security</div>
            <div className='text'>Company shall only process personal information in a way that is compatible with and relevant to the purpose for which it was collected or has been authorized. To the extent necessary for those purposes, Company shall take reasonable steps to ensure that personal information is accurate, complete, current, and reliable for its intended use.</div>
            <div className='text'>7.Data Integrity</div>
            <div className='text'>The Company reserves the right to modify this Policy at any time and will do so from time to time. Each modification shall be effective upon its posting to the Site. Your continued use of the Site following any such modification constitutes your acceptance of any changes to this Policy. It is therefore important that you review this Policy regularly. If you have any questions concerning this Policy please contact the Company at feedback@quantunicorn.com.</div>
            <div className='text'>8.Access</div>
            <div className='text'>You can review, update, and change some of your personal information – including your email address and password – in your profile at any time. We will allow you access to your personal information and allow you to correct, amend, or delete inaccurate information. Access will not be allowed where the burden or expense of providing access would be disproportionate to the risks to your privacy or where personal rights other than your own would be violated.</div>
            <div className='text'>9."Do Not Track" Disclosures</div>
            <div className='text'>Certain state laws require us to indicate whether we honor “Do Not Track” settings in your browser concerning targeted advertising. We adhere to the standards set out in this Privacy Policy and do not monitor or follow any Do Not Track browser requests.</div>
            <div className='text'>10.Children's Policy</div>
            <div className='text'>The Children’s Online Privacy Protection Act was created to protect children under the age of 13 from unsuspecting acts or practices in conjunction with collecting, using, and disclosing any information about them. Our Site is not intended for anyone under the age of 13. If you are under 13, do not use or provide any information on or through our Site. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you are a parent or guardian or otherwise believe we might have any information from or about a child under 13, please contact us so that we can delete the child's information. The Site will never knowingly accept, collect, maintain or use any information from a child under the age of 13. If a child whom we know to be under the age of 13 sends personal information to us online, we will only use that information to respond directly to that child or notify parents.</div>
            <div className='text'>11.Other</div>
            <div className='text'>By using the Site, you consent to having your personal information and data transferred to and processed in the United States. Please contact us with any questions or concerns regarding our policy at this address: feedback@quantunicorn.com.</div>
        </div>
    )
}

export default Privacy
