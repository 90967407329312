import React, { useEffect, useState } from "react";
import { Button } from "antd";
import intl from 'react-intl-universal';
import './404.less';
import { useNavigate } from "react-router";

const NotFound = () => {
    const [countDown, setCountDown] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setCountDown(countDown - 1)
            if (countDown === 1) {
                navigate('/')
            }
        }, 1000);
        // 清除计时器
        return () => clearTimeout(timer);
    });

    return (
        <div className='not-found'>
            <div className='banner'>
                <div className='container'>
                    <div className='title'>404</div>
                    <div className='text'>
                        <div>{intl.get('notFound.text1')}</div>
                        <div>{intl.get('notFound.text2', { sec: countDown, unit: countDown > 1 ? 'seconds.' : 'second.' })}</div>
                    </div>
                    <Button className='button' onClick={() => navigate('/')}>{intl.get('notFound.btnText', { sec: countDown })}</Button>
                </div>
            </div>
        </div>
    )

}

export default NotFound