const openInterestOption = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
            shadowStyle: {
                color: 'rgba(108, 74, 255, 0.15)'
            }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderWidth: '0',
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
        },
        shadowColor: 'rgba(0, 0, 0, 0.4)',
    },
    xAxis: {
        type: 'category',
        axisLine: {
            lineStyle: {
                color: 'gray',
                opacity: 0.5,
                width: 1,
            },
        },
        axisLabel: {
            textStyle: {
                color: '#99989D',
            },
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            textStyle: {
                color: '#99989D',
            },
        },
        splitLine: {
            lineStyle: {
                color: ['gray'],
                opacity: 0.2,
            },
        }
    },
    dataset: {
        dimensions: ['strike', 'type'],
        source: [],
    },
    legend: {
        itemHeight: 12,
        itemWidth: 12,
        icon: 'rect',
        left: 0,
        textStyle: { color: '#99989D', fontSize: '14px' }
    },
    grid: {
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true,
    },
    series: [
        {
            type: 'bar',
            stack: 'total',
            name: 'Call',
            barMaxWidth: 37,
            itemStyle: {
                color: 'rgba(108, 74, 255, 1)',
            },
        },
        {
            type: 'bar',
            stack: 'total',
            name: 'Put',
            barMaxWidth: 37,
            itemStyle: {
                color: 'rgba(197, 171, 153, 1)',
            }
        },
    ],
};

export default openInterestOption;