import React from "react";
import './index.less'

const Legal = () => {
    return (
        <div className='container service-agreement'>
            <div className='title'>TERMS OF SERVICE AGREEMENT</div>
            <div className='text'>Effective Date: September 1st, 2021</div>
            <div className='text'>READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY BEFORE USING OR OBTAINING ANY MATERIALS, INFORMATION, PRODUCTS OR SERVICES THROUGH THIS SITE. </div>
            <div className='text'>This Terms of Service (“Agreement”) is a legal agreement between you (referred to herein as “you” or “your”) and EleQuant Technology, Inc., a Delaware corporation operating under the name Quant Unicorn (“Quant Unicorn”, “we”, “our” or “us”) for access to and use of our website available at QuantUnicorn.com (the “Site”) and the related software, feeds, and online services owned, controlled or offered by us that post a link to this Agreement (referred to collectively as the “Service”).</div>
            <div className='text'>1. Grant of rights – Restrictions of use</div>
            <div className='text'>A) Authorized Users (defined below in Section 1.F) are granted a limited, revocable, non-transferable, non-sublicensable, non-exclusive license and right during the applicable Term, to access the Services through a generally available mobile device, web browser or Quant Unicorn authorized Site to view content and information, including Quant Unicorn Data, and otherwise use the Services to the extent intended and permitted by the functionality thereof. </div>
            <div className='text'>B) Neither party shall use the name, trademarks, service marks, symbols, or logos of the other party without the express prior written consent of the other party. Additionally, under no circumstances may any Authorized User offer, sell, retransmit any part of the Online Services or Materials to any other person for commercial resale or commercial redistribution in any medium or use the Online Services or the Materials to compete with the business of Quant Unicorn. You may not modify, reverse engineer, reverse assemble or reverse compile any part of the Online Services or Materials. You may not use the Online Services in any way to improve the quality of any data sold or contributed by you to any third party. Downloading and storing Materials in an archival database, decompiling, disassembling or reverse engineering the Materials is strictly prohibited. </div>
            <div className='text'>C) All right, title, and interest (including all copyrights, trademarks and other intellectual property rights) in the Online Services and Materials in any medium belongs to Quant Unicorn or its third-party suppliers of Materials. Authorized Users do not acquire any proprietary interest in the Online Services, Materials, or copies thereof, except the limited rights granted herein.</div>
            <div className='text'>D) Authorized Users may not use the Online Services or Materials in any fashion that knowingly infringes the intellectual property rights or proprietary interests of Quant Unicorn or any third party. Your use of the Online Services and Materials must comply with all applicable laws, rules or regulations. You may not use the Online Services for any illegal purpose or in any manner inconsistent with the Agreement or any applicable laws.</div>
            <div className='text'>E) Authorized Users may not remove, alter, edit or obscure the copyright notice or other notices contained in the Online Services and Materials or on QuantUnicorn.com.</div>
            <div className='text'>F) For the purpose of this Agreement, the term “Authorized User” refers to you as an eligible person with a Silver Level, Gold Level, Platinum Level or Diamond Level access.</div>
            <div className='sub-text'>a) You agree that Quant Unicorn login information, including username and password, may only be used by the Authorized User to whom Quant Unicorn assigns it and that Quant Unicorn login information may not be shared with or used by any other person, including other Authorized Users.</div>
            <div className='sub-text'>b) You represent, warrant, and agree that you will not create or attempt to create multiple user accounts on the Service.	</div>
            <div className='sub-text'>c) You will use best efforts prevent unauthorized use of Quant Unicorn login information and will promptly notify Quant Unicorn, in writing, if you suspect that Quant Unicorn login information is lost, stolen, compromised, or misused.</div>
            <div className='sub-text'>d) Use of the Online Services via mechanical, robotic, scripted or any other automated means is strictly prohibited. In the absence of any prior written agreement by Quant Unicorn, use of the Online Services is permitted only via manually conducted, discrete, individual search and retrieval activities.</div>
            <div className='sub-text'>e) Quant Unicorn may amend, enhance, add to, withdraw, or otherwise change Online Services, Materials, and feature functionality within the Online Services without notice to you. Such modifications shall be effective immediately.</div>
            <div className='sub-text'>f) Authorized Users are required to provide truthful and accurate information during registration, including, but not limited to, correct payment information, correct contact information (e-mail, telephone, etc.), confirmation of payment arrangements, confirmation of status, acceptance this Agreement </div>
            <div className='sub-text'>g) Authorized Users are required to notify Quant Unicorn of User’s any relevant changes made to the information contained in this Section.</div>
            <div className='text'>2. Provision of the Service by Us</div>
            <div className='text'>We are constantly improving the Service in order to provide the best possible experience for our users. You acknowledge and agree that the form and nature of the Service which we provide may change from time to time without prior notice to you. Any new features that augment or enhance the current Service shall be subject to this Agreement. </div>
            <div className='text'>You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access the Service. Accordingly, you agree that you will be responsible to us for all activities that occur under your account. If you become aware of any unauthorized use of your password or of your account, you agree to notify us immediately by using the “Contact Us” feature on the Site.</div>
            <div className='text'>3. Privacy Policy </div>
            <div className='text'>For information about our data protection practices, please read our privacy policy available at the Site. This policy explains how we treat your personal information, and how we protect your privacy when you use the Service. You agree to the use of your data in accordance with our privacy policy. </div>
            <div className='text'>4. Changes to Agreement</div>
            <div className='text'>We reserve the right to change, amend and/or modify this Agreement, in whole or in part, at any time, and when we do so, we will provide you with reasonable notice that a change, amendment or modification has been made. You agree that reasonable notice includes, by way of example, a reasonably prominent posting on the Site or the sending of an e-mail to you, indicating that this Agreement has been changed. You hereby acknowledge and agree that such changes, amendments and/or modifications will become effective when posted on the Service or as otherwise provided by us. From time to time, we may post on the Service or otherwise notify you of additional or different rules and policies relating to the Service. These rules and policies shall thereafter be part of this Agreement. If you use the Service after the posting or other notice of changes in this Agreement or changed rules or policies, you are agreeing to follow and be bound by them for such use.</div>
            <div className='text'>5. Limited warranty and Indemnification </div>
            <div className='text'>You agree, at your own expense, to indemnify, defend and hold harmless Quant Unicorn, its suppliers, agents, directors, officers, employees, representatives, successors, and assigns from and against any and all loss, damage and expense, including reasonable attorney’s fees, and amounts paid in settlement arising from any and all third party claims that: (i) arise out of your use of the Online Services in violation of this Agreement; (ii) violate or breach this Agreement and in turn infringes such party’s intellectual property rights. You shall have the right, at your expense, to assume the exclusive defense and control of any such matter and Quant Unicorn will fully cooperate with you in asserting any available defense. This indemnification is contingent on Quant Unicorn providing prompt notice to you of any such third-party claim and shall not apply if the claim stems from any negligence, willful misconduct, or breach of this Agreement by Quant Unicorn.</div>
            <div className='text'>YOU AGREE THAT YOUR USE OF THE ONLINE SERVICES IS AT YOUR SOLE RISK AND YOU ACKNOWLEDGE THAT, THE ONLINE SERVICES AND MATERIALS ARE PROVIDED “AS IS”, AND “AS AVAILABLE” AND THAT QUANT UNICORN AND EACH THIRD PARTY SUPPLIER OF MATERIALS MAKE NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE ONLINE SERVICES AND MATERIALS, INCLUDING BUT NOT LIMITED TO, MERCHANTIBILITY, OR FITNESS FOR A PARTICULAR PURPOSE OR USE.</div>
            <div className='text'>2. Provision of the Service by Us</div>
            <div className='text'>We are constantly improving the Service in order to provide the best possible experience for our users. You acknowledge and agree that the form and nature of the Service which we provide may change from time to time without prior notice to you. Any new features that augment or enhance the current Service shall be subject to this Agreement. </div>
            <div className='text'>You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access the Service. Accordingly, you agree that you will be responsible to us for all activities that occur under your account. If you become aware of any unauthorized use of your password or of your account, you agree to notify us immediately by using the “Contact Us” feature on the Site.</div>
            <div className='text'>3. Privacy Policy </div>
            <div className='text'>For information about our data protection practices, please read our privacy policy available at the Site. This policy explains how we treat your personal information, and how we protect your privacy when you use the Service. You agree to the use of your data in accordance with our privacy policy. </div>
            <div className='text'>4. Changes to Agreement</div>
            <div className='text'>We reserve the right to change, amend and/or modify this Agreement, in whole or in part, at any time, and when we do so, we will provide you with reasonable notice that a change, amendment or modification has been made. You agree that reasonable notice includes, by way of example, a reasonably prominent posting on the Site or the sending of an e-mail to you, indicating that this Agreement has been changed. You hereby acknowledge and agree that such changes, amendments and/or modifications will become effective when posted on the Service or as otherwise provided by us. From time to time, we may post on the Service or otherwise notify you of additional or different rules and policies relating to the Service. These rules and policies shall thereafter be part of this Agreement. If you use the Service after the posting or other notice of changes in this Agreement or changed rules or policies, you are agreeing to follow and be bound by them for such use.</div>
            <div className='text'>5. Limited warranty and Indemnification </div>
            <div className='text'>You agree, at your own expense, to indemnify, defend and hold harmless Quant Unicorn, its suppliers, agents, directors, officers, employees, representatives, successors, and assigns from and against any and all loss, damage and expense, including reasonable attorney’s fees, and amounts paid in settlement arising from any and all third party claims that: (i) arise out of your use of the Online Services in violation of this Agreement; (ii) violate or breach this Agreement and in turn infringes such party’s intellectual property rights. You shall have the right, at your expense, to assume the exclusive defense and control of any such matter and Quant Unicorn will fully cooperate with you in asserting any available defense. This indemnification is contingent on Quant Unicorn providing prompt notice to you of any such third-party claim and shall not apply if the claim stems from any negligence, willful misconduct, or breach of this Agreement by Quant Unicorn.</div>
            <div className='text'>YOU AGREE THAT YOUR USE OF THE ONLINE SERVICES IS AT YOUR SOLE RISK AND YOU ACKNOWLEDGE THAT, THE ONLINE SERVICES AND MATERIALS ARE PROVIDED “AS IS”, AND “AS AVAILABLE” AND THAT QUANT UNICORN AND EACH THIRD PARTY SUPPLIER OF MATERIALS MAKE NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE ONLINE SERVICES AND MATERIALS, INCLUDING BUT NOT LIMITED TO, MERCHANTIBILITY, OR FITNESS FOR A PARTICULAR PURPOSE OR USE.</div>
            <div className='text'>6. DISCLAIMER OF LIABILITY</div>
            <div className='text'>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE, OUR AFFILIATES, DIRECTORS, MEMBERS, MANAGERS, OFFICERS, EMPLOYEES, AGENTS OR THIRD PARTY LICENSORS, BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OR LOSSES ARISING OUT OF OR RELATING TO THIS AGREEMENT AND/OR THE SERVICE PROVIDED HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE, RECKLESSNESS, PROFESSIONAL NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER BASIS OR LEGAL THEORY. SUCH LIMITATION OF LIABILITY SHALL APPLY WHETHER OR NOT THE DAMAGES ARISE DIRECTLY OR INDIRECTLY FROM: (i) THE USE OR MISUSE OF, OR RELIANCE UPON, THE SERVICE PROVIDED HEREUNDER; (ii) THE INABILITY TO USE THE SERVICE FOR ANY REASON, INCLUDING, WITHOUT LIMITATION, FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN OPERATION, OR ANY FAILURE OF PERFORMANCE NOT LIMITED TO ACTS OF GOD, COMMUNICATION FAILURE, THEFT, OR DESTRUCTION; (iii) THE INTERRUPTION, SUSPENSION, OR TERMINATION OF THE SERVICE; OR (iv) THE DELETION AND/OR CORRUPTION OF ANY DATA, INFORMATION, DOCUMENTS, FILES AND/OR ANY OTHER MATERIALS STORED ON A SERVER OWNED OR UNDER OUR CONTROL OR IN ANY WAY CONNECTED TO THE SERVICE. SUCH LIMITATION ON LIABILITY SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.</div>
            <div className='text'>7. Investment Risk; No Solicitation of Advice </div>
            <div className='text'>An investment in stocks could lose money over short or even long periods. You should expect stock prices and returns may have large range fluctuations. NO GUARANTEE CAN BE MADE IF YOU INVEST BASED ON THE INFORMATION PROVIDED ON THIS SITE. You specifically acknowledge that none of the Quant Unicorn Parties will be held liable for losses or gains arising out of or relating to your use the Services and that no other Quant Unicorn user will be liable to you for losses or gains arising out of any Services Content or User Content of any type available through the Services. You acknowledge that your investment decisions or recommendations are made entirely at your election. You also specifically acknowledge that any user may or may not have a position in any investment described in User Content and that you have been informed that the user posting User Content may have established a position at a much earlier time at more favorable prices, has no duty to update the User Content if its investment position changes, and may effect transactions in investments before or after, and in the same manner or the opposite manner, to any investment transactions directly or indirectly described in the User Content.</div>
            <div className='text'>8. Term and Termination </div>
            <div className='text'>This Agreement is effective until terminated by us or you. Making unauthorized copies or distribution of website content or otherwise violating these Terms may result in the termination of your Quant Unicorn account, prohibition on use of the Services, and further legal action. Quant Unicorn reserves the right to limit your use of or access to the Services, in its sole discretion in order to maintain the performance and availability of the Services and to enforce these Terms of Service. Quant Unicorn is not liable for the loss, corruption, alteration or removal of any content transmitted using our Services. By using our Services, you expressly waive the right to seek damages and agree to hold Quant Unicorn harmless for any such loss, alteration, corruption or removal. You acknowledge and agree that you are solely responsible for retaining all records and reconciling all transaction information relating to your use of the Services.</div>
            <div className='text'>This Agreement shall be governed by and construed in accordance with the laws of the state of Delaware.</div>
            <div className='text'>9. No Assignment, Sublicense or Transfer</div>
            <div className='text'>You may not assign, sublicense, or transfer this Agreement or any rights or obligations hereunder without our prior written consent. Any such attempted assignment, sublicense, or transfer will be null and void and we, in our sole discretion, shall have the right to immediately terminate this Agreement. </div>
            <div className='text'>You agree that we may communicate with you electronically. Such electronic communications may consist of e-mail, notices posted on the Service, and other communications. You agree that all agreements, notices, disclosures, and other communications we send to you electronically will satisfy any requirement that such communication be in writing and, to the extent intended, such communication will be an enforceable and binding term or amendment to this Agreement.</div>
            <div className='text'>This Agreement shall constitute the entire agreement of the parties with respect to its subject matter and replaces and supersedes any prior written or verbal communications, representations, proposals or quotations on that subject matter. </div>
            <div className='text'>This Agreement and its terms and conditions may be amended or replaced by Quant Unicorn from time to time as described herein or by written agreement at the discretion of Quant Unicorn. Pricing may only be changed in accordance with the terms of your price schedule; all other provisions set out in the general terms and conditions of this Agreement may be amended in Quant Unicorn’s sole discretion and effective immediately after notice to you, if applicable, or if any changes are made to this Agreement, such changes will: (a) only be applied prospectively; and (b) will apply to all similarly situated Quant Unicorn customers using the Online Services.</div>
            <div className='text'>10. Cancellation Policy</div>
            <div className='text'>You may cancel your subscription at any time. Unless you cancel at least 24 hours before the start of your next billing period, you will be charged for the next billing period. After cancellation, you will continue to have access to the Site for the remainder of your billing period.</div>
            <div className='text'>If we take action to collect any unpaid subscription fees from you, you will pay all costs (on a full indemnity basis) incurred in doing so.</div>
            <div className='text'>WE DO NOT OFFER REFUNDS OR CREDITS FOR UNUSED SUBSCRIPTION PERIODS DUE TO ACCIDENTAL PURCHASES, MEDICAL CONDITIONS, OR ANY SIMILAR REASON OR EVENT, UNLESS REQUIRED BY LAW TO DO SO.</div>
            <div className='text'>If you are dissatisfied for any reason, please contact our Support Team at feedback@quantunicorn.com.</div>
            <div className='text'>6. DISCLAIMER OF LIABILITY</div>
            <div className='text'>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE, OUR AFFILIATES, DIRECTORS, MEMBERS, MANAGERS, OFFICERS, EMPLOYEES, AGENTS OR THIRD PARTY LICENSORS, BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OR LOSSES ARISING OUT OF OR RELATING TO THIS AGREEMENT AND/OR THE SERVICE PROVIDED HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE, RECKLESSNESS, PROFESSIONAL NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER BASIS OR LEGAL THEORY. SUCH LIMITATION OF LIABILITY SHALL APPLY WHETHER OR NOT THE DAMAGES ARISE DIRECTLY OR INDIRECTLY FROM: (i) THE USE OR MISUSE OF, OR RELIANCE UPON, THE SERVICE PROVIDED HEREUNDER; (ii) THE INABILITY TO USE THE SERVICE FOR ANY REASON, INCLUDING, WITHOUT LIMITATION, FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN OPERATION, OR ANY FAILURE OF PERFORMANCE NOT LIMITED TO ACTS OF GOD, COMMUNICATION FAILURE, THEFT, OR DESTRUCTION; (iii) THE INTERRUPTION, SUSPENSION, OR TERMINATION OF THE SERVICE; OR (iv) THE DELETION AND/OR CORRUPTION OF ANY DATA, INFORMATION, DOCUMENTS, FILES AND/OR ANY OTHER MATERIALS STORED ON A SERVER OWNED OR UNDER OUR CONTROL OR IN ANY WAY CONNECTED TO THE SERVICE. SUCH LIMITATION ON LIABILITY SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.</div>
            <div className='text'>7. Investment Risk; No Solicitation of Advice </div>
            <div className='text'>An investment in stocks could lose money over short or even long periods. You should expect stock prices and returns may have large range fluctuations. NO GUARANTEE CAN BE MADE IF YOU INVEST BASED ON THE INFORMATION PROVIDED ON THIS SITE. You specifically acknowledge that none of the Quant Unicorn Parties will be held liable for losses or gains arising out of or relating to your use the Services and that no other Quant Unicorn user will be liable to you for losses or gains arising out of any Services Content or User Content of any type available through the Services. You acknowledge that your investment decisions or recommendations are made entirely at your election. You also specifically acknowledge that any user may or may not have a position in any investment described in User Content and that you have been informed that the user posting User Content may have established a position at a much earlier time at more favorable prices, has no duty to update the User Content if its investment position changes, and may effect transactions in investments before or after, and in the same manner or the opposite manner, to any investment transactions directly or indirectly described in the User Content.</div>
            <div className='text'>8. Term and Termination </div>
            <div className='text'>This Agreement is effective until terminated by us or you. Making unauthorized copies or distribution of website content or otherwise violating these Terms may result in the termination of your Quant Unicorn account, prohibition on use of the Services, and further legal action. Quant Unicorn reserves the right to limit your use of or access to the Services, in its sole discretion in order to maintain the performance and availability of the Services and to enforce these Terms of Service. Quant Unicorn is not liable for the loss, corruption, alteration or removal of any content transmitted using our Services. By using our Services, you expressly waive the right to seek damages and agree to hold Quant Unicorn harmless for any such loss, alteration, corruption or removal. You acknowledge and agree that you are solely responsible for retaining all records and reconciling all transaction information relating to your use of the Services.</div>
            <div className='text'>This Agreement shall be governed by and construed in accordance with the laws of the state of Delaware.</div>
            <div className='text'>9. No Assignment, Sublicense or Transfer</div>
            <div className='text'>You may not assign, sublicense, or transfer this Agreement or any rights or obligations hereunder without our prior written consent. Any such attempted assignment, sublicense, or transfer will be null and void and we, in our sole discretion, shall have the right to immediately terminate this Agreement. </div>
            <div className='text'>You agree that we may communicate with you electronically. Such electronic communications may consist of e-mail, notices posted on the Service, and other communications. You agree that all agreements, notices, disclosures, and other communications we send to you electronically will satisfy any requirement that such communication be in writing and, to the extent intended, such communication will be an enforceable and binding term or amendment to this Agreement.</div>
            <div className='text'>This Agreement shall constitute the entire agreement of the parties with respect to its subject matter and replaces and supersedes any prior written or verbal communications, representations, proposals or quotations on that subject matter. </div>
            <div className='text'>This Agreement and its terms and conditions may be amended or replaced by Quant Unicorn from time to time as described herein or by written agreement at the discretion of Quant Unicorn. Pricing may only be changed in accordance with the terms of your price schedule; all other provisions set out in the general terms and conditions of this Agreement may be amended in Quant Unicorn’s sole discretion and effective immediately after notice to you, if applicable, or if any changes are made to this Agreement, such changes will: (a) only be applied prospectively; and (b) will apply to all similarly situated Quant Unicorn customers using the Online Services.</div>
            <div className='text'>10. Cancellation Policy</div>
            <div className='text'>You may cancel your subscription at any time. Unless you cancel at least 24 hours before the start of your next billing period, you will be charged for the next billing period. After cancellation, you will continue to have access to the Site for the remainder of your billing period.</div>
            <div className='text'>If we take action to collect any unpaid subscription fees from you, you will pay all costs (on a full indemnity basis) incurred in doing so.</div>
            <div className='text'>WE DO NOT OFFER REFUNDS OR CREDITS FOR UNUSED SUBSCRIPTION PERIODS DUE TO ACCIDENTAL PURCHASES, MEDICAL CONDITIONS, OR ANY SIMILAR REASON OR EVENT, UNLESS REQUIRED BY LAW TO DO SO.</div>
            <div className='text'>If you are dissatisfied for any reason, please contact our Support Team at feedback@quantunicorn.com.</div>
        </div>
    )
}

export default Legal



