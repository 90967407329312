import { Button, DatePicker, Input, Row, Col, Select } from "antd";
import React, { useState } from "react";
import moment from 'moment';
import Empty from '../empty';
import { detailedGEXtData, totalGEXtData } from "@/service/gex";
import totalOption from "./totalOption";
import ReactECharts from 'echarts-for-react';
import expirationOption from "./onExpirationOption";
import openInterestOption from "./openInterestOption";
import intl from 'react-intl-universal';
import { toPercent } from "@/utils/toPercent";
import './index.less';
import {chartStyle} from "../../style"

const { Option } = Select

const Gex = ({ NProgress }) => {
    // ticker
    // const [ticker, setTicker] = useState('AAPL');
    const [ticker, setTicker] = useState('SPY');
    // date
    const [date, setDate] = useState(window.SERVER_DATA['GEX_LAST_DAY'] ? moment(window.SERVER_DATA['GEX_LAST_DAY']):moment());

    // total 数据
    const [total, setTotal] = useState({
        daily: [],
        ticker: ''
    });
    // total 数据时间范围
    const [totalDateRange, setTotalDateRange] = useState(6);
    // total chart option
    const [option, setOption] = useState(totalOption([]))
    const [percentile, setPercentile] = useState();

    // expiration GEX 数据
    const [expirationList, setExpirationList] = useState([]);

    // detail data
    const [detail, setDetail] = useState({
        strike: [],
        call: [],
        put: [],
        date: ''
    });
    // 日期列表
    const [dateList, setDateList] = useState([]);
    // 返回 message
    const [message, setMessage] = useState('');

    // 寻找开始日期 index
    const findIndex = (startDate, list) => {
        let index = 0;
        console.log(list[0].date,list[list.length-1].date,startDate.format('YYYY-MM-DD'))
        let startDateString = startDate.format('YYYY-MM-DD')
        for (let i = 0; i < list.length; i++) {
            if (list[i].date >= startDateString) {
                index = i;
                break;
            }
        }
        return index;
    }

    const calculatePercentile = (list) => {
        const sortList = (list || []).sort((a, b) => a.gex - b.gex);
        const rank = (sortList || []).findIndex((n) => n.date === date.format('YYYY-MM-DD'));
        console.log(sortList, rank, date.format('YYYY-MM-DD'))
        if (list.length && typeof (rank) === 'number' && rank !== -1) {
            setPercentile(toPercent((rank + 1) / list.length));

        } else {
            setPercentile('N/A');
        }
    }

    // 查询 total 数据
    const fetchTotal = async () => {
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }

        NProgress.start();
        const res = await totalGEXtData(ticker);
        if (res && res.data) {
            if (res.data.daily) {
                setTotal(res.data);
                setTotalDateRange(6)
                // 查询符合时间范围的数据
                const index = findIndex(moment(date).subtract(6, "months"), res.data.daily);
                const endIndex = findIndex(date, res.data.daily);
                console.log(index,endIndex)
                if(endIndex === 0){
                    setMessage('No Data Avaliable')
                    setTotal({
                        daily: [],
                        ticker: ''
                    });
                    setPercentile();
                }else{
                    const newList = res.data.daily.slice(index,endIndex+1)
                    setOption(totalOption(newList));
                    // 计算百分比
                    calculatePercentile(newList);
                }
            } else {
                setMessage(res.data.message || '')
                setTotal({
                    daily: [],
                    ticker: ''
                });
                setPercentile();
            }
        } else {
            setMessage('')
            setTotal({
                daily: [],
                ticker: ''
            });
            setPercentile();
        }
        NProgress.done();
    }

    // 根据 range 找到 total 数据 
    const getTotalDate = (months) => {
        const index = findIndex(moment(date).subtract(months, "months"), total.daily);
        const endIndex = findIndex(date, total.daily);
        setTotalDateRange(months);
        const list = total.daily.slice(index,endIndex+1)
        setOption(totalOption(list));
        calculatePercentile(list);
    }

    // 查询指定日期 expiration 数据
    const selectDetail = (list, date) => {
        let strike = []
        let call = []
        let put = []
        let dateList = []
        let index = 0;
        list.forEach((item, itemIndex) => {
            dateList.push(item['exp-date']);
            if (date && date === item['exp-date']) {
                index = itemIndex;
            }
        });
        list[index].Details.forEach(item => {
            if (strike.indexOf(item['strike']) === -1) {
                strike.push(item['strike'])
            }
            if (item['type'] === 'C') {
                call.push(item['OpenInterest'])
            } else {
                put.push(-item['OpenInterest'])
            }
        });
        setDetail({
            strike,
            call,
            put,
            date: dateList[index],
        })
        setDateList(dateList)
    }

    // 查询 expiration 数据
    const fetchExpiration = async () => {
        const res = await detailedGEXtData(ticker, date.format('YYYY-MM-DD'));
        if (res && res.data && res.data.breakdowns) {
            setExpirationList(res.data.breakdowns.map(v => { return { ...v, ExpDaytotal: v.ExpDaytotal.toFixed(2) } }));
            selectDetail(res.data.breakdowns);
        } else {
            // 数据恢复初始状态
            setExpirationList([]);
            setDetail({
                strike: [],
                call: [],
                put: [],
                date: ''
            });
            setDateList([]);
        }
    }

    // 查询
    const search = () => {
        if (ticker && date) {
            fetchTotal();
            fetchExpiration();
        } else {
            // 数据恢复初始状态
            setTotal({
                daily: [],
                ticker: ''
            });
            setTotalDateRange(6)
            setExpirationList([]);
            setDetail({
                strike: [],
                call: [],
                put: [],
                date: ''
            });
            setDateList([]);
            setMessage('');
        }
    };

    // 查询指定日期 detail
    const handleDateChange = (date) => {
        selectDetail(expirationList, date);
    }

    const handleKeypress = e => {
        
        if(e.key === 'Enter' || e.code === "NumpadEnter"){
            search()
          }
        
      };

    return (
        <div className='gex'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={10}>{intl.get('products.product6.title')}</Col>
                        <Col className='form-content' span={14}>
                            <div className='content-item'>
                                <span className='label'>{intl.get('products.ticker')}</span>
                                <Input defaultValue={ticker} onChange={e => setTicker(e.target.value)} onKeyPress={handleKeypress}/>
                            </div>

                            <div className='content-item'>
                                <span className='label'>{intl.get('products.date')}</span>
                                <DatePicker
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date}
                                    onChange={date => setDate(date)}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                />
                            </div>

                            <Button className='button' onClick={search}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://quantunicorn.medium.com/qu-gex-%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-8ced1d43915d' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product6.subTitle')}</Col>
                    </Row>
                </Col>
            </div>
            

            {(total && total.daily.length) || (expirationList && expirationList.length) ?
                <Row className='table' gutter={[16, 16]}>
                    <Col span={24} className='chart'>
                        <div className='chart-header'>
                            <div className='chart-title'>
                                {total.ticker} GEX (GEX Percentile {percentile})
                            </div>
                            <div className='chart-button'>
                                <span className={totalDateRange === 6 ? 'active' : ''} onClick={() => getTotalDate(6)}>6{intl.get('products.product6.months')}</span>
                                <span className={totalDateRange === 12 ? 'active' : ''} onClick={() => getTotalDate(12)}>1{intl.get('products.product6.year')}</span>
                                <span className={totalDateRange === 24 ? 'active' : ''} onClick={() => getTotalDate(24)}>2{intl.get('products.product6.years')}</span>
                            </div>
                        </div>
                        <ReactECharts
                            option={option}
                            style={chartStyle}
                        />
                    </Col>

                    {expirationList && expirationList.length && detail && detail.strike.length ?
                        <>
                            <Col span={24} className='chart'>
                                <div className='chart-title'>
                                    {total.ticker} GEX On Expiration Dates
                                </div>
                                <ReactECharts
                                    option={{ ...expirationOption, dataset: { ...expirationOption.dataset, source: expirationList } }}
                                    style={chartStyle}
                                />
                            </Col>

                            <Col span={24} className='chart'>
                                <div className='chart-header'>
                                    <div className='chart-title'>
                                        {`${total.ticker} Open Interest`}
                                    </div>
                                    {detail.date && <div className='chart-select'>
                                        <Select
                                            defaultValue={detail.date}
                                            getPopupContainer={triggerNode => triggerNode.parentNode}
                                            onChange={e => handleDateChange(e)}
                                        >
                                            {dateList.map(v => <Option value={v} key={v}>{v}</Option>)}
                                        </Select>
                                    </div>}
                                </div>
                                <ReactECharts
                                    option={{
                                        ...openInterestOption,
                                        xAxis: { ...openInterestOption.xAxis, data: detail.strike },
                                        series: [{ ...openInterestOption.series[0], data: detail.call }, { ...openInterestOption.series[1], data: detail.put }]
                                    }}
                                    style={chartStyle}
                                />
                            </Col>
                        </>
                        : null
                    }

                </Row> : <Empty message={message} />
            }
        </div>
    )
}

export default Gex;