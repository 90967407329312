import { Button, DatePicker, Input, Row, Col, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import { capitalFlowChartData, capitalFlowListData } from "@/service/capitalFlow";
import ReactECharts from 'echarts-for-react';
import defaultOption from "./option";
import Empty from '../empty';
import { getColor } from "@/utils/getColor";
import queryString from 'query-string';
import { useLocation } from "react-router";
import intl from 'react-intl-universal';
import './index.less';
import {chartStyle} from "../../style"

// 空状态
const initialList = {
    daily: [],
    weekly: [],
}

const initialChartList = {
    list: [],
    ticker: '',
}

const CapitalFlow = ({ NProgress }) => {
    const location = useLocation();
    const { ticker: queryTicker, date: queryDate } = queryString.parse(location.hash.replace('#capitalflow', ''));
    // ticker
    const [ticker, setTicker] = useState(queryTicker || 'AAPL');
    // date
    const [date, setDate] = useState(queryDate ? moment(queryDate) : window.SERVER_DATA ? moment(window.SERVER_DATA['MF_LAST_DAY']):moment());
    // table数据
    const [list, setList] = useState(initialList);
    // 返回信息
    const [message, setMessage] = useState('');
    // chart数据
    const [hourlyData, setHourlyData] = useState(initialChartList);
    const [dailyData, setDailyData] = useState(initialChartList);
    const [weeklyData, setWeeklyData] = useState(initialChartList);

    const dailyColumns = [
        {
            title: intl.get('products.date'),
            dataIndex: 'label',
            width: '25%',
        },
        {
            title: intl.get('products.capitalFlowDirection'),
            dataIndex: 'sentiment',
            width: '30%',
            render: (text) => getColor(text),
        },
        {
            title: intl.get('products.long_short'),
            dataIndex: 'long_short',
            width: '25%',
        },
        {
            title: intl.get('products.rank'),
            dataIndex: 'rank',
            width: '20%',
            render: (_, record) => {
                return `${record.rank}/${record.total}`;
            }
        },
        
    ];

    const weeklyColumns = [
        {
            title: intl.get('products.date'),
            dataIndex: 'label',
            width: '45%',
        },
        {
            title: intl.get('products.capitalFlowDirection'),
            dataIndex: 'sentiment',
            width: '10%',
            render: (text) => getColor(text),
        },
        {
            title: intl.get('products.long_short'),
            dataIndex: 'long_short',
            width: '25%',
        },
        {
            title: intl.get('products.rank'),
            dataIndex: 'rank',
            width: '20%',
            render: (_, record) => {
                return `${record.rank}/${record.total}`;
            }
        },
        
    ];

    // 查询table数据
    const fetchList = async (ticker, date) => {
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }

        NProgress.start();
        const res = await capitalFlowListData(ticker, date);
        if (res && res.data) {
            setMessage(res.data.message || '');
            if (res.data.data) {
                setList(res.data.data);
            }
        } else {
            setList(initialList);
            setMessage('');
        }
        NProgress.done();
    };

    // 查询charts数据
    const fetchChartList = async (ticker, frequency, date) => {
        const res = await capitalFlowChartData(ticker, frequency, date);
        if (res && res.data && res.data.data) {
            return res.data.data.map(v => { return { near: v.near.toFixed(2), total: v.total.toFixed(2), xlabel: frequency === 'hourly' ? v.xlabel + ':00' : v.xlabel } });
        } else {
            return [];
        }
    };

    // 查询
    const search = (() => {
        if (ticker && date) {
            const formatDate = date.format('YYYY-MM-DD')
            fetchList(ticker, formatDate);
            fetchChartList(ticker, 'hourly', formatDate).then(res => setHourlyData({ list: res, ticker }));
            fetchChartList(ticker, 'daily', formatDate).then(res => setDailyData({ list: res, ticker }));
            fetchChartList(ticker, 'weekly', formatDate).then(res => setWeeklyData({ list: res, ticker }));
        } else {
            setList(initialList);
            setHourlyData(initialChartList);
            setDailyData(initialChartList);
            setWeeklyData(initialChartList)
            setMessage('')
        }
    });

    // 带参跳转查询
    const fetchDefault = useCallback(() => {
        fetchList(queryTicker, queryDate);
        fetchChartList(queryTicker, 'hourly', queryDate).then(res => setHourlyData({ list: res, queryTicker }));
        fetchChartList(queryTicker, 'daily', queryDate).then(res => setDailyData({ list: res, queryTicker }));
        fetchChartList(queryTicker, 'weekly', queryDate).then(res => setWeeklyData({ list: res, queryTicker }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryDate, queryTicker])

    const handleKeypress = e => {
        
        if(e.key === 'Enter' || e.code === "NumpadEnter"){
            search()
          }
        
      };

    useEffect(() => {
        if (queryTicker && queryDate) {
            fetchDefault();
        }
    }, [fetchDefault, queryDate, queryTicker])

    return (
        <div className='capital-flow'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={10} >{intl.get('products.product2.title')}</Col>
                        <Col className='form-content' span={14}>
                            <div className='content-item'>
                                <span className='label'>{intl.get('products.ticker')}</span>
                                <Input defaultValue={ticker} onChange={e => setTicker(e.target.value)} onKeyPress={handleKeypress}/>
                            </div>

                            <div className='content-item'>
                                <span className='label'>{intl.get('products.date')}</span>
                                <DatePicker
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date}
                                    onChange={date => setDate(date)}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                />
                            </div>
                            <Button className='button' onClick={search} >{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://quantunicorn.medium.com/%E4%B8%AA%E8%82%A1%E8%B5%84%E9%87%91%E6%B5%81%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-80d3887f3f97' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product2.subTitle')}</Col>
                    </Row>
                </Col>
            </div>
            
            {!list.daily.length && !hourlyData.list.length && !dailyData.list.length && !weeklyData.list.length ? <Empty message={message} /> : null}

            {list && list.daily && list.daily.length ?
                <>
                    <div className='update-time'>{message}</div>
                    <Row className='table' gutter={16}>
                        <Col span={12}>
                            <div className='table-item'>
                                <div className='table-title'>{intl.get('products.dailyRank')}</div>
                                <Table rowKey='label' size="small" columns={dailyColumns} dataSource={list?.daily || []} pagination={false} />
                            </div>
                        </Col>
                        <Col className='form-content' span={12}>
                            <div className='table-item'>
                                <div className='table-title'>{intl.get('products.weeklyRank')}</div>
                                <Table rowKey='label' size="small" columns={weeklyColumns} dataSource={list?.weekly || []} pagination={false} />
                            </div>
                        </Col>
                    </Row>
                </> : null
            }

            {
                hourlyData.list.length ? <div className='chart'>
                    <div className='chart-title'>
                        {hourlyData.ticker}{intl.get('products.product2.hourlyCapitalFlow')}
                    </div>
                    <div className='chart-description'>
                        {intl.get('products.product2.chartTip')}
                    </div>
                    <ReactECharts option={{ ...defaultOption, dataset: { ...defaultOption.dataset, source: hourlyData.list } }} style={chartStyle}/>
                </div> : null
            }

            {
                dailyData.list.length ? <div className='chart'>
                    <div className='chart-title'>
                        {dailyData.ticker}{intl.get('products.product2.dailyCapitalFlow')}
                    </div>
                    <div className='chart-description'>
                        {intl.get('products.product2.chartTip')}
                    </div>
                    <ReactECharts option={{ ...defaultOption, dataset: { ...defaultOption.dataset, source: dailyData.list } }} style={chartStyle} />
                </div> : null
            }

            {
                weeklyData.list.length ? <div className='chart'>
                    <div className='chart-title'>
                        {weeklyData.ticker}{intl.get('products.product2.weeklyCapitalFlow')}
                    </div>
                    <div className='chart-description'>
                        {intl.get('products.product2.chartTip')}
                    </div>
                    <ReactECharts option={{ ...defaultOption, dataset: { ...defaultOption.dataset, source: weeklyData.list } }} style={chartStyle}/>
                </div> : null
            }
        </div>
    )
}

export default CapitalFlow;