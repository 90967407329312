import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ProductMenu from "./component/menu";
import Overview from './component/overview';
import Market from './component/market';
import CapitalFlow from './component/capitalFlow';
import MultiFlow from './component/multiFlow';
import MarketHistory from './component/marketHistory';
import QU100 from './component/qu100';
import QUalgo from './component/qualgo';
import OptionData from './component/optionData';
import Gex from './component/gex';
import UnusualFlow from './component/unusualFlow';

import './index.less'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import intl from 'react-intl-universal';

NProgress.configure({ parent: '#product-header' });
NProgress.configure({ showSpinner: false });

const Products = props => {
    const [content, setContent] = useState();
    const location = useLocation();

    // 展示hash对应的内容 
    useEffect(() => {
        // 切换内容时清除 NProgress
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }
        // default overview
        if (location.hash === '#overview') {
            document.title = intl.get('products.title');
            setContent(<Overview {...props} NProgress={NProgress} />);

        } else if (location.hash === '#market') {
            document.title = intl.get('products.product1.title');
            setContent(<Market {...props} NProgress={NProgress} />);
        } else if (/^#capitalflow\?ticker=[A-Za-z0-9]+&date=\d{4}-\d{2}-\d{2}$/.test(location.hash) || location.hash === '#capitalflow') {
            document.title = intl.get('products.product2.title');
            setContent(<CapitalFlow {...props} NProgress={NProgress} />);
        } else if (location.hash === '#multiflow') {
            document.title = intl.get('products.product3.title');  
            setContent(<MultiFlow {...props} NProgress={NProgress} />);
        } else if (location.hash === '#markethistory') {
            document.title = intl.get('products.product4.title');
            setContent(<MarketHistory {...props} NProgress={NProgress} />);
        } else if (location.hash === '#qu100') {
            document.title = intl.get('products.product5.title');
            setContent(<QU100 {...props} NProgress={NProgress} />);
        } else if (location.hash === '#gex') {
            document.title = intl.get('products.product6.title');
            setContent(<Gex {...props} NProgress={NProgress} />);
            
        } else if (location.hash === '#qualgo') {
            document.title = intl.get('products.product12.title');
            setContent(<QUalgo {...props} NProgress={NProgress} />);

        } else if (location.hash === '#unusualflow') {
            document.title = intl.get('products.product11.title');
            setContent(<UnusualFlow {...props} NProgress={NProgress} />);        
        
        } else if (location.hash === '#optiondata') {
            // new product = option data
            document.title = intl.get('products.product7.title');
            setContent(<OptionData {...props} NProgress={NProgress} />);

        } else {
            // document.title = intl.get('products.product1.title');
            // window.location.hash = 'market';
            // setContent(<Market {...props} NProgress={NProgress} />);
            document.title = intl.get('products.title');
            setContent(<Overview {...props} NProgress={NProgress} />);
        }
    }, [location.hash, props])

    // 退出页面执行
    useEffect(() => {
        return () => {
            document.title = "QUANTUNICORN"
            NProgress.done();
            NProgress.remove();
        };
    }, [])

    return (
        <div className='products' id='product'>
            <div className='large-container'>
                <Row>
                    <Col span={5} className='menu'>
                        <ProductMenu {...props} />
                    </Col>
                    <Col span={19} className='content'>{content}</Col>
                </Row>
            </div>
        </div>
    )
}

export default Products