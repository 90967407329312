import { Button, DatePicker, Row, Col, Input } from "antd";
import React, { useState } from "react";
import moment from 'moment';
import Table from './table.js';
import Empty from '../empty'
import { multiFlowListData } from "@/service/multiFlow.js";
import intl from 'react-intl-universal';

import './index.less';

const { TextArea } = Input;

const MultiFlow = ({ NProgress }) => {
    // tickers
    const [tickers, setTickers] = useState('AAPL,TSLA');
    // date
    const [date, setDate] = useState(window.SERVER_DATA ? moment(window.SERVER_DATA['MF_LAST_DAY']):moment());
    // tickerList
    const [tickerList, setTickerList] = useState([]);
    // 返回信息
    const [message, setMessage] = useState('');


    const search = async () => {
        if (NProgress.isStarted()) {
            NProgress.remove();
        }

        NProgress.start();
        if (tickers && date) {
            // 字符串分割
            let list = tickers.split(',').map(v => v.trim()).filter(v => v);
            // 数组去重
            list = list.filter((v, index) => {
                return list.indexOf(v) === index;
            });

            let dataList = await Promise.all(list.map(async v => {
                const res = await multiFlowListData(v, date.format('YYYY-MM-DD'));
                if (res && res.data && res.data.message) setMessage(res.data.message)

                if (res && res.data && res.data.data) {
                    return { ...res.data.data, ticker: v };
                }
            }))

            dataList = (dataList || []).filter(v => v);

            // console.log(">>", dataList)

            setTickerList(dataList);
        } else {
            setTickerList([]);
            setMessage('')
        }
        NProgress.done();
    };

    const handleKeypress = e => {
        if(e.key === 'Enter' || e.code === "NumpadEnter"){
            search()
          }
      };

    return (
        <div className='multi-flow'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={10}>{intl.get('products.product3.title')}</Col>
                        <Col className='form-content' span={14}>
                            <div className='content-item'>
                                <span className='label'>{intl.get('products.ticker')}</span>
                                <TextArea defaultValue={tickers} onChange={e => setTickers(e.target.value.toUpperCase())} autoSize={{ minRows: 1, maxRows: 2 }} onKeyPress={handleKeypress}/>
                            </div>

                            <div className='content-item'>
                                <span className='label'>{intl.get('products.date')}</span>
                                <DatePicker
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date} onChange={date => setDate(date)}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                />
                            </div>

                            <Button className='button' onClick={search}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://quantunicorn.medium.com/%E5%A4%9A%E6%A0%87%E7%9A%84%E8%B5%84%E9%87%91%E6%B5%81%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-98e454beb38f' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product3.subTitle')}</Col>
                    </Row>
                </Col>
            </div>

            {
                tickerList && tickerList.length ? <>
                    <div className='update-time'>{message}</div>
                    {tickerList.map((v) => <Table key={v.ticker} ticker={v.ticker} list={v} />)}
                </> : <Empty message={message} />
            }
        </div>
    )
}

export default MultiFlow;