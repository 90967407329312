import { Button, Row, Col, Table, Input, Tabs } from "antd";
import React, { useState } from "react";
import Empty from '../empty';
import intl from 'react-intl-universal';
import ReactECharts from 'echarts-for-react';
import { HttpGet } from "../../../../utils/request";
import GaugeChart from "react-gauge-chart";

import './index.less';

const TabPane = Tabs.TabPane;

const MODE_TICKER_MAP = [
    {mode: "Mode A", ticker: "", perf: []},
    {mode: "Mode B", ticker: "", perf: []},
    {mode: "Mode C", ticker: "", perf: []},
]
const chartStyle = {
    height: 500,
    width: '100%'
}
const datatableColumns = [
    {
        title: 'Open Date',
        dataIndex: 'opened',
        className:'opened'            
    },        
    {
        title: 'Ticker',
        dataIndex: 'ticker',            
        className:'ticker'
    },
    {
        title: 'Long/Short',
        dataIndex: 'longshort',
        className:'longshort',            
    },
    // {
    //     title: 'Count',
    //     dataIndex: 'count',            
    //     className:'count'
    // },
    {
        title: 'Open Price',
        dataIndex: 'buyprice',            
        className:'buyprice'
    },
    {
        title: 'Close Price',
        dataIndex: 'sellprice',            
        className:'sellprice'
    },
    {
        title: 'Close Date',
        dataIndex: 'closed',            
        className:'closed'
    },
    {
        title: 'Profit/Loss %',
        dataIndex: 'percent',            
        className:'percent'
    },
    // {
    //     title: 'Profit/Loss $',
    //     dataIndex: 'gain',            
    //     className:'gain'
    // },
]
const datatableColumnsLatestOrder = [
    {
        title: 'Date',
        dataIndex: 'opened',
        className:'opened'            
    },        
    {
        title: 'Ticker',
        dataIndex: 'ticker',            
        className:'ticker'
    },
    {
        title: 'Long/Short',
        dataIndex: 'longshort',            
        className:'longshort',            
    },
    // {
    //     title: 'Count',
    //     dataIndex: 'count',            
    //     className:'count'
    // },
    {
        title: 'Open Price',
        dataIndex: 'buyprice',            
        className:'buyprice'
    },
    {
        title: 'Close Price',
        dataIndex: 'sellprice',            
        className:'sellprice'
    },
    {
        title: 'Close Date',
        dataIndex: 'closed',            
        className:'closed'
    },
    {
        title: 'Profit/Loss %',
        dataIndex: 'percent',            
        className:'percent'
    },
]

// add longshort and tofixed price 
const getMassagedPerfData = (orderList = []) => {
    // console.log(">>", orderList)
    orderList.forEach(item => {
        if (item.gain) {
            item.gain = item.gain.toFixed(2);
        }
        if (item.buyprice >= 0) {
            item.longshort = "long"
        } else {
            item.longshort = "short"
        }        
    })
    return orderList;    
}


const QUalgo = ({ NProgress }) => {
    // 返回list数据
    const [list, setList] = useState([]);
    const [listLatestOrder, setListLatestOrder] = useState([]);
    const [currentHolding, setCurrentHolding] = useState();
    // list搜索信息
    const [selectList, setSelectList] = useState([]);
    const [spyShortList, setSpyShortList] = useState([]);
    // 返回信息
    const [message, setMessage] = useState('');
    const [returnMap, setReturnMap] = useState();

    const [chartOption, setChartOption] = useState();

    const [date, setDate] = useState('');
    const [isLong, setIsLong] = useState(true); // default long
    const [longShortValue, setLongShortValue] = useState();

    const gaugeChartStyle = {
        width: 500,
        // height: 250,
    }

    const getMonthReturn = (end, start, current, obj) => {
        // if both data exist = pass this month return end - start
        if (obj[end] && obj[start]) {
            return obj[end] - obj[start]
        } else {
            if (obj[start]) {
                // still in current month 
                return obj[current] - obj[start]
            } else {
                return 0
            }
        }
    }
   
    // 查询
    const search = async (topn) => {
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }
        NProgress.start();

        
        // on init search
        if (typeof topn != "number") {
            const resDaily = await getAlgoData();
            if (resDaily && resDaily.data && resDaily.data.data) {
                let res = resDaily.data.data;
                // console.log(">>res", res);

                 // TODO: TEST short via this 
                // res.indicator = 0; //1 long

                const longshortNum = res.indicator == 1 ? 0.75 : 0.25;
                if (res.indicator == 0) {
                    setIsLong(false)
                }
                if (res.date) {
                    const newDateShorten = res.date.substring(5);
                    setDate(newDateShorten);
                }
                
                if (res.mode1 && res.mode1["ticker"]) {                    
                    MODE_TICKER_MAP[0]["ticker"] = res.mode1["ticker"]
                    MODE_TICKER_MAP[0]["perf"] = getMassagedPerfData(res.mode1["past_performace"])
                }
                if (res.mode2 && res.mode2["ticker"]) {
                    MODE_TICKER_MAP[1]["ticker"] = res.mode2["ticker"]
                    MODE_TICKER_MAP[1]["perf"] = getMassagedPerfData(res.mode2["past_performace"])
                }
                if (res.mode3 && res.mode3["ticker"]) {
                    MODE_TICKER_MAP[2]["ticker"] = res.mode3["ticker"]
                    MODE_TICKER_MAP[2]["perf"] = getMassagedPerfData(res.mode3["past_performace"])
                }
    
                // let actionMessage = resDaily.data.data.date + " " + longshortStr + ": " +  resDaily.data.data.top5.join(", ")                
                // show table                 
                setList(MODE_TICKER_MAP);
                // console.log(">>longshortNum", longshortNum)
                setLongShortValue(longshortNum);
                if (MODE_TICKER_MAP[0]["perf"] && MODE_TICKER_MAP[0]["perf"].length) {
                    setSelectList(MODE_TICKER_MAP[0]["perf"].slice().reverse());
                }

                // set short data
                if (res.indicator == 0) {
                    // console.log(">>res1", res["4shorts"]["past_performace"]);
                    if (res["4shorts"]) {
                        setSpyShortList(getMassagedPerfData(res["4shorts"]["past_performace"]).slice().reverse());
                    }
                }

                setChartOption();
                // init load 
                search(1);

            } else {
                setList([]);
                setSelectList([]);
                setChartOption();
            }
            // console.log(">>topn",topn)
            const res = await getMFengineOrdersData();
            if (res && res.data && res.data.data && res.data.data.orders) {
                let orderList = res.data.data.orders;
                // console.log(">>orderList", orderList)
                orderList.forEach(item => {
                    if (item.gain) {
                        item.gain = item.gain.toFixed(2);
                    }
                    if (item.buyprice >= 0) {
                        item.longshort = "long"
                    } else {
                        item.longshort = "short"
                    }
                    
                })
                setListLatestOrder(orderList);
            } else {
                setListLatestOrder([]);
            }


        } else { // topn = 1, 2, 3 
            // select ticker to 1 show table and 2 plot chart 
            const res = await getAlgoDataPlot(topn);
            const resDailySummary = await getAlgoDailySummary(topn);
            
            if (resDailySummary && resDailySummary.data && resDailySummary.data.data && resDailySummary.data.data.summary) {
                // console.log(">>resDailySummary", resDailySummary.data.data.summary)
                setCurrentHolding(resDailySummary.data.data.summary);
            }
            
            if (res && res.data) {
                if (list[topn-1] && list[topn-1]["perf"]) {
                    if (list[topn-1]["perf"] && list[topn-1]["perf"].length) {
                        setSelectList(list[topn-1]["perf"].reverse())                    
                    }
                }
                // render chart 
                if (res.data.data) {
                    const chartData = res.data.data; 
                    //set latest trade 
                    // if (res.data.data.length) {
                    //     // console.log(">>setCurrentHolding", chartData[res.data.data.length-1])
                    //     setCurrentHolding(chartData[res.data.data.length-1])
                    // }

                    // setup monthly return data
                    // convert array to {date: return%}
                    const obj = {}; //return obj
                    chartData.forEach( item => {
                        // let value = item.normalizedMFprofit * 100;
                        let value = item.pv * 100;
                        obj[item.date] = value.toFixed(1);
                    });

                    // render month perf
                    const lastestData = chartData.slice(-1);
                    const lastestPV = lastestData[0]["pv"];
                    const lastestDate = lastestData[0]["date"];
                    var qualgoMonthReturn = {
                        2020: [
                            (obj["2020-12-31"] - obj["2020-01-15"]).toFixed(1),
                            obj["2020-01-31"]-obj["2020-01-15"],obj["2020-02-28"]-obj["2020-01-31"],obj["2020-03-31"]-obj["2020-02-28"],obj["2020-04-30"]-obj["2020-03-31"],obj["2020-05-29"]-obj["2020-04-30"],
                            obj["2020-06-30"]-obj["2020-05-29"],obj["2020-07-31"]-obj["2020-06-30"],obj["2020-08-28"]-obj["2020-07-31"],obj["2020-09-30"]-obj["2020-08-28"],obj["2020-10-30"]-obj["2020-09-30"],
                            obj["2020-11-30"]-obj["2020-10-30"],obj["2020-12-31"]-obj["2020-11-30"]
                        ],
                        2021: [
                            // 41.8,
                            (obj["2021-12-31"] - obj["2020-12-31"]).toFixed(1),
                            obj["2021-01-29"]-obj["2020-12-31"],obj["2021-02-26"]-obj["2021-01-29"],obj["2021-03-31"]-obj["2021-02-26"],obj["2021-04-30"]-obj["2021-03-31"],obj["2021-05-28"]-obj["2021-04-30"],
                            obj["2021-06-30"]-obj["2021-05-28"],obj["2021-07-30"]-obj["2021-06-30"],obj["2021-08-31"]-obj["2021-07-30"],obj["2021-09-30"]-obj["2021-08-31"],obj["2021-10-29"]-obj["2021-09-30"],
                            obj["2021-11-30"]-obj["2021-10-29"],obj["2021-12-31"]-obj["2021-11-30"]
                        ],
                        2022: [
                            // 61.3,
                            (obj["2022-12-30"] - obj["2021-12-31"]).toFixed(1),
                            obj["2022-01-31"]-obj["2021-12-31"],obj["2022-02-28"]-obj["2022-01-31"],obj["2022-03-31"]-obj["2022-02-28"],obj["2022-04-29"]-obj["2022-03-31"],obj["2022-05-31"]-obj["2022-04-29"],
                            obj["2022-06-30"]-obj["2022-05-31"],obj["2022-07-29"]-obj["2022-06-30"],obj["2022-08-31"]-obj["2022-07-29"],obj["2022-09-30"]-obj["2022-08-31"],obj["2022-10-31"]-obj["2022-09-30"],
                            obj["2022-11-30"]-obj["2022-10-31"],obj["2022-12-30"]-obj["2022-11-30"]
                        ],
                        2023: [
                            (obj["2023-12-29"] - obj["2022-12-30"]).toFixed(1), // YTD 13.5%
                            obj["2023-01-31"]-obj["2022-12-30"],obj["2023-02-28"]-obj["2023-01-31"],obj["2023-03-31"]-obj["2023-02-28"],obj["2023-04-28"]-obj["2023-03-31"],obj["2023-05-31"]-obj["2023-04-28"],
                            obj["2023-06-30"]-obj["2023-05-31"],obj["2023-07-31"]-obj["2023-06-30"],obj["2023-08-31"]-obj["2023-07-31"],obj["2023-09-29"]-obj["2023-08-31"], obj["2023-10-31"]-obj["2023-09-29"],obj["2023-11-30"]-obj["2023-10-31"],obj["2023-12-29"]-obj["2023-11-30"],
                        ],
                        2024: [
                            (lastestPV *100 - obj["2023-12-29"]).toFixed(1),
                            getMonthReturn("2024-01-31", "2023-12-29", lastestDate, obj),
                            getMonthReturn("2024-02-29", "2024-01-31", lastestDate, obj),
                            getMonthReturn("2024-03-28", "2024-02-29", lastestDate, obj),
                            getMonthReturn("2024-04-30", "2024-03-28", lastestDate, obj),
                            getMonthReturn("2024-05-31", "2024-04-30", lastestDate, obj),
                            getMonthReturn("2024-06-28", "2024-05-31", lastestDate, obj),
                            getMonthReturn("2024-07-31", "2024-06-28", lastestDate, obj),
                            getMonthReturn("2024-08-30", "2024-07-31", lastestDate, obj),
                            getMonthReturn("2024-09-30", "2024-08-30", lastestDate, obj),
                            getMonthReturn("2024-10-31", "2024-09-30", lastestDate, obj),
                            getMonthReturn("2024-11-29", "2024-10-31", lastestDate, obj),
                            getMonthReturn("2024-12-31", "2024-11-29", lastestDate, obj),
                        ]
                    }
                    setReturnMap(qualgoMonthReturn);

                    // setup chart    
                    const dateList = chartData.map( value => value.date);
                    // const presentValue = chartData.map( value => parseInt(value.pv)); //present value $
                    const presentValue = chartData.map( value => value.pv*100);
                    
                    // convert array to object key for easy access object["2023-05-10"]
                    let longShortMap = []
                    chartData.forEach((item) => {
                        longShortMap[item.date] = {long: item.long, short: item.short}
                    });
                    const option = {
                        grid: {
                            left: "5%",
                            top: "15%"
                        },  
                        xAxis: {
                          type: 'category',
                          axisLine: {
                            lineStyle: {
                                color: 'gray',
                                opacity: 0.5,
                                width: 1,
                            },
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#99989D',
                                },
                            },
                          data: dateList
                        },
                        yAxis: {
                          type: 'value',
                          scale: true,
                          axisLine: { lineStyle: { color: '#777' } },
                          axisLabel: {
                            inside: false,
                            formatter: '{value} %'
                          },
                        },
                        series: [
                          {
                            name: 'QU Algo Cumulative Return',
                            data: presentValue,
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            showSymbol: false,
                            lineStyle: {
                                color: 'red',
                                width: 3,
                            },
                          }
                        ],
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross'
                            },
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderWidth: '0',
                            textStyle: {
                                color: 'rgba(255, 255, 255, 0.85)',
                            },
                            shadowColor: 'rgba(0, 0, 0, 0.4)',
                            formatter(params) {
                                // console.log(">>", params)
                                const date = params[0].name;
                                let result = "";
                                if (params[0].data) {
                                    // present value
                                    result += parseInt(params[0].data) + "%";
                                }
                                if (date && longShortMap[date]) {
                                    if (longShortMap[date]["long"]) {
                                        result += "<br/>Long: " + longShortMap[date]["long"].join(",")
                                    }
                                    if (longShortMap[date]["short"]) {
                                        result += "<br/>Short: " + longShortMap[date]["short"].join(",")
                                    }
                                }
                                return result;
                            }
                        },
                        dataZoom: [
                            {
                              type: 'slider',
                              xAxisIndex: [0, 1, 2],
                              realtime: false,
                              start: 0,
                              end: 100,
                            //   top: 80,
                            //   bottom: 0,
                              height: 24,
                              handleIcon:
                                'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                              handleSize: '120%'
                            },
                            {
                              type: 'inside',
                              xAxisIndex: [0, 1, 2],
                              start: 40,
                              end: 70,
                            //   top: 30,
                              height: 24
                            }
                        ],
                        axisPointer: {
                            link: [
                            {
                                xAxisIndex: 'all'
                            }
                            ],
                            label: {
                               backgroundColor: '#777'
                            }
                        },
                        color: ['rgba(108, 74, 255, 1)', 'rgba(197, 171, 153, 1)'],       
                        legend: {
                            itemHeight: 3,
                            itemWidth: 10,
                            icon: 'plain',
                            orient: 'vertical',
                            left: 0,                        
                            top: 30,
                            bottom: 15,
                            textStyle: { color: '#99989D', fontSize: 12 },
                            itemStyle: {
                                color: "red"
                            }
                        },
                      };
                    setChartOption(option);
                    
                } else {
                    setMessage(res.data.message || '');
                }
                
                NProgress.done();    

            } else {
                setList([]);
                setSelectList([]);
                setChartOption();
                setMessage('');
            }
        }

        
    }

    const columns = [
        {
            dataIndex: 'mode',        
        },
        {
            dataIndex: 'ticker',
            render: (ticker, data, index) => {
                return <a onClick={() => { search(index + 1) }} > {ticker}</a>;
            }
        },
    ];

    const getAlgoDataPlot = (topn=1) => {
        // topn =1,2,3
        let url = `/api/mfengineplot?topn=${topn}`;
        return HttpGet(url);
    }

    const getAlgoDailySummary = (topn=1) => {
        // topn =1,2,3
        let url = `/api/mfengine_dailysummary?topn=${topn}`;
        return HttpGet(url);
    }

    const getAlgoData = () => {
        let url = `/api/mfengine`;
        return HttpGet(url);
    }  

    const getMFengineOrdersData = (ticker, date) => {
        return HttpGet(`/api/mfengineorders`);
    }

    const onChangeTab = (key) => {
        // console.log(">>onChangeTab", parseInt(key)+1);
        setSelectList([]);
        search(parseInt(key) + 1);
    };

    return (
        <div className='qu-algo'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter'>
                    <Row className='form-container'>
                        <Col className='form-title' span={10}>{intl.get('products.product12.title')}</Col>
                        <Col className='form-content' span={14}>
                            <Button className='button' onClick={search}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div>
                                <a href="https://quantunicorn.medium.com/qu-algo%E4%BF%A1%E5%8F%B7%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-28dc99d208c7"
                                target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product12.subTitle')}</Col>
                    </Row>
                </Col>
            </div>

            { list && list.length && chartOption?
                <Tabs className="algo-tab" defaultActiveKey='0' type="card" onChange={onChangeTab}>
                    {
                        list.map((modeObj, index) => 
                            <TabPane key={index} tab={<h4>{modeObj.mode}</h4>}>
                                <Row>
                                    <Col span={6}>
                                        <div id="gaugeChart">
                                            <GaugeChart
                                                id="gauge-chart3"
                                                nrOfLevels={2}
                                                // colors={["#24c537", "#e84e4e"]}
                                                colors={["#e84e4e", "#24c537"]}
                                                arcWidth={0.3}
                                                percent={longShortValue}
                                                textColor={'white'}
                                                style={gaugeChartStyle}
                                                animate={false}
                                                hideText={true} 
                                            />
                                            <span id="gaugeChartShort">Bearish</span>
                                            <span id="gaugeChartLong">Bullish</span>                                                                                        
                                        </div>
                                        <div className={`modeTicker ${isLong ? "bull": "bear"}`}>
                                            <div className="modeTickerHeader">
                                                <span>{date}</span>
                                                {isLong ? <span> 做多标的</span> :
                                                    <span> 做空标的</span>
                                                }
                                            </div>
                                            {isLong ? <div className="modeTickerLong">{modeObj.ticker}</div> :
                                                <div className="modeTickerShort">SPY QQQ IWM DIA</div>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <ReactECharts
                                            option={chartOption}                            
                                            style={chartStyle}                    
                                        />
                                    </Col>
                                </Row>

                                {/* current holding */}
                                {currentHolding ? 
                                 <div className="quCurrentHolding quOrders ant-table-wrapper">
                                    <div className="ant-table ant-table-small">
                                        <table>
                                            <thead className="ant-table-thead">
                                                <tr>
                                                    <th className="ant-table-cell start">{date}：</th>
                                                    <th className="ant-table-cell">4:00pm 前持仓</th>
                                                    <th className="ant-table-cell">4:00pm MOC交易</th>
                                                    <th className="ant-table-cell end">4:00pm 后持仓</th>
                                                </tr>
                                            </thead>
                                            <tbody className="ant-table-tbody">
                                                <tr className="ant-table-row ant-table-row-level-0">
                                                    <td className="ant-table-cell"></td>
                                                    <td className="ant-table-cell">
                                                        <Row>
                                                            <Col span={4}>多仓：</Col>
                                                            <Col span={20}>
                                                                {currentHolding.long.map((ticker) => {
                                                                    return (<span>{ticker} <br></br></span>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="ant-table-cell">
                                                        <Row>
                                                            <Col span={4}>卖出：</Col>
                                                            <Col span={20}>
                                                                {currentHolding.to_sell.map((ticker) => {
                                                                    return (<span>{ticker} <br></br></span>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="ant-table-cell">
                                                        <Row>
                                                            <Col span={4}>多仓：</Col>
                                                            <Col span={20}>
                                                                {currentHolding.long_close.map((ticker) => {
                                                                    return (<span>{ticker} <br></br></span>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>

                                                <tr className="ant-table-row ant-table-row-level-0">
                                                    <td className="ant-table-cell"></td>
                                                    <td className="ant-table-cell">
                                                        <Row>
                                                            <Col span={4}>空仓：</Col>
                                                            <Col span={20}>
                                                                {currentHolding.short.map((ticker) => {
                                                                    return (<span>{ticker} <br></br></span>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="ant-table-cell">
                                                        <Row>
                                                            <Col span={4}>买入：</Col>
                                                            <Col span={20}>
                                                                {currentHolding.to_buy.map((ticker) => {
                                                                    return (<span>{ticker} <br></br></span>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="ant-table-cell">
                                                        <Row>
                                                            <Col span={4}>空仓：</Col>
                                                            <Col span={20}>
                                                                {currentHolding.short_close.map((ticker) => {
                                                                    return (<span>{ticker} <br></br></span>)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                                <tr className="ant-table-row ant-table-row-level-0">
                                                    <td className="ant-table-cell"> </td>
                                                    <td className="ant-table-cell"> </td>
                                                    <td className="ant-table-cell"> </td>
                                                    <td className="ant-table-cell"> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : ""}

                                {/* tutorial */}
                                <div id="gaugeChartText">
                                    <h3 className="chartTutorialHeader">复现收益曲线图（不考虑复利）的交易规则（以参考账户10万美金举例）：</h3>
                                    <div className="chartTutorialContent">
                                        <p>1. 用收盘市价单（market on close order，简写moc）进行交易操作。</p>
                                        <p className={isLong ? "highlight" : ""}>2. 若当日指针指向bullish，用moc订单买入5万美金当日模型选择的个股，持有两个交易日后用moc订单平仓。</p>
                                        <p className={!isLong ? "highlight" : ""}>3. 若当日指针指向bearish，用moc订单卖出所有做多持仓，同时用moc订单在当日下单做空spy/qqq/iwm/dia 各5万美金。持有两个交易日后，用moc订单平仓。
                                        </p>
                                        <p>关于QU Algo更多的使用技巧，请在discord联系我们，进行更细致的培训和讲解。</p>
                                    </div>
                                </div>

                                {/* Long past perf table 1 */}
                                {isLong && selectList && selectList.length ?
                                    <Table
                                        className="quOrders"
                                        title={() => selectList[0]["ticker"] + " past performance"}
                                        size="small"
                                        dataSource={isLong ? selectList.filter(item => item['longshort'] === 'long') : selectList.filter(item => item['longshort'] === 'short')}
                                        columns={datatableColumns}
                                        pagination={true}
                                    />
                                    : ""
                                }

                                {/* Short past perf table 4 */}
                                {!isLong && spyShortList && spyShortList.length ? 
                                    <Table
                                        className="quOrders"
                                        title={() => "SPY/QQQ/IWM/DIA past performance"}
                                        size="small"
                                        dataSource={isLong ? spyShortList.filter(item => item['longshort'] === 'long') : spyShortList.filter(item => item['longshort'] === 'short')}
                                        columns={datatableColumns}
                                        pagination={true}
                                    />
                                    : ""
                                }

                                {/* montly return     */}
                                {returnMap ?
                                    <div className="return-section">
                                        <h2>Monthly returns</h2>
                                        <table className="ant-table return-table">
                                            <tr>
                                                <th> </th> <th>Jan</th><th>Feb</th><th>Mar</th><th>Apr</th><th>May</th><th>Jun</th><th>Jul</th><th>Aug</th><th>Sep</th><th>Oct</th><th>Nov</th><th>Dec</th><th>Year</th>
                                            </tr>
                                            <tr>
                                                <td>2020</td>
                                                {returnMap[2020].map((value, index) =>{
                                                    if (index > 0) {
                                                        return <td>{value.toFixed(1)}%</td>
                                                    }
                                                })}
                                                <td>{returnMap[2020][0]}%</td>
                                            </tr>
                                            <tr>
                                                <td>2021</td>
                                                {returnMap[2021].map((value, index) =>{
                                                    if (index > 0) {
                                                        return <td>{value.toFixed(1)}%</td>
                                                    }
                                                })}
                                                <td>{returnMap[2021][0]}%</td>
                                            </tr>
                                            <tr>
                                                <td>2022</td>
                                                {returnMap[2022].map((value, index) =>{
                                                    if (index > 0) {
                                                        return <td>{value.toFixed(1)}%</td>
                                                    }
                                                })}
                                                <td>{returnMap[2022][0]}%</td>
                                            </tr>
                                            <tr>
                                                <td>2023</td>
                                                {returnMap[2023].map((value, index) =>{
                                                    if (index > 0) {
                                                        if (value != 0) {
                                                            return <td>{value.toFixed(1)}%</td>
                                                        } else {
                                                            return <td></td>
                                                        }
                                                    }
                                                })}
                                                <td>{returnMap[2023][0]}%</td>
                                            </tr>
                                            <tr>
                                                <td>2024</td>
                                                {returnMap[2024].map((value, index) =>{
                                                    if (index > 0) {
                                                        if (value != 0) {
                                                            return <td>{value.toFixed(1)}%</td>
                                                        } else {
                                                            return <td></td>
                                                        }
                                                    }
                                                })}
                                                <td>{returnMap[2024][0]}%</td>
                                            </tr>

                                        </table>
                                    </div> 
                                : ""}

                                {/* latest order */}
                                {list.length ?
                                    <Table className="quOrders" title={()=> "QU Algo Latest Orders"} size="small" dataSource={listLatestOrder} columns={datatableColumnsLatestOrder} pagination={false}/>
                                    : ""}   

                                                

                            </TabPane>
                        )
                    }
                </Tabs>
                : ""
            }           

            {/* { list && list.length ? 
                <div id="gaugeChart">
                     <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={2}
                        colors={["#24c537", "#e84e4e"]}
                        arcWidth={0.3}
                        percent={longShortValue}
                        textColor={'white'}
                        style={gaugeChartStyle}
                        animate={false}
                        hideText={true} // If you want to hide the text
                    />
                    <span id="gaugeChartLong">Bullish</span>
                    <span id="gaugeChartShort">Bearish</span>
                </div>
                : <Empty message={message} />
            } */}

            {/* { list && list.length ? 
                <div className="action-today">
                    <Table size="small" columns={columns} dataSource={list} pagination={false} />
                </div>
                : ""
            } */}
        </div>
    )
}

export default QUalgo;