import { Row, Col, Table } from "antd";
import React from "react";
import { getColor } from "../../../../utils/getColor";
import intl from 'react-intl-universal';
import './index.less';

const MultiFlowTable = ({ list,ticker }) => {
    const columns = [
        {
            title: intl.get('products.date'),
            dataIndex: 'label',
            width: '40%',
        },
        {
            title: intl.get('products.capitalFlowDirection'),
            dataIndex: 'sentiment',
            width: '15%',
            render: (text) => getColor(text),
        },
        {
            title: intl.get('products.long_short'),
            dataIndex: 'long_short',
            width: '30%',
        },
        {
            title: intl.get('products.rank'),
            dataIndex: 'rank',
            width: '35%',
            render: (_, record) => {
                return `${record.rank}/${record.total}`;
            }
        },
    ];

    return (list && list.daily && list.daily.length ?
        <Row className='table' gutter={16}>
            <Col span={12}>
                <div className='table-item'>
                    <div className='table-title'>{ticker} {intl.get('products.dailyRank')}</div>
                    <Table rowKey='label' size="small" columns={columns} dataSource={list?.daily || []} pagination={false} />
                </div>
            </Col>
            <Col className='form-content' span={12}>
                <div className='table-item'>
                    <div className='table-title'>{ticker} {intl.get('products.weeklyRank')}</div>
                    <Table rowKey='label' size="small" columns={columns} dataSource={list?.weekly || []} pagination={false} />
                </div>
            </Col>
        </Row>: null
    )
}

export default MultiFlowTable;