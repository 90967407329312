import React, { useCallback, useEffect, useState } from 'react';
import { Col, Layout, Row, Menu, Button, Dropdown, Badge, Alert, Space } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from '@/asset/layout/logo.png';
// import iconDiscord from '@/asset/home/discord-icon.svg';
import iconDiscord from '@/asset/layout/discord-logo-white.png';

import intl from 'react-intl-universal';
import { emit } from '@/utils/emit';
import { getBrowserLang } from '@/utils/getBrowserLang';
import { pageOutLet } from '@/utils/outLet';
import { DarkIcon } from '../component/darkIcon';
import { LightIcon } from '../component/lightIcon';
import { useStore } from "@/hooks/useStore";
import { STORE_USER } from "@/stores/createStores";
import LevelBlock from "@/components/level";
import { DownOutlined } from '@ant-design/icons';
import { gotoSignIn } from '@/utils/gotoSignIn';
import { userCenter } from '@/service/user';
import { observer } from 'mobx-react';

import './index.less';

const { Header, Footer, Content } = Layout;

const PageLayout = observer(() => {
    // 当前选中菜单
    const [current, setCurrent] = useState([]);
    // 是否显示sign in按钮
    // const [showSignIn, setShowSignIn] = useState(false);
    // 是否显示theme按钮
    const [showTheme, setShowTheme] = useState(false);
    // 主题
    const [theme, setTheme] = useState(window.localStorage.theme || 'dark');
    // 语言
    const [language, setLanguage] = useState(window.localStorage.language || getBrowserLang());
    const navigate = useNavigate();
    const location = useLocation();
    const user = useStore(STORE_USER);

    // banner for Ads ?lang=en 
    const [visibleBanner, setVisibleBanner] = useState(false);
    
    const menuFullSize = [
        {
            key: '/',
            name: intl.get('layout.menu.home'),            
            className: 'firstpage'
        },
        {
            key: '/#products',
            name: intl.get('layout.menu.products'),
            className: 'link-products'
        },
        {
            key: '/#tutorials',
            name: intl.get('layout.menu.tutorials'),
            className: 'link-tutorials'
        },

        {
            key: 'https://quantunicorn.medium.com/qu-%E8%B5%84%E9%87%91%E6%B5%81%E4%BF%A1%E5%8F%B7%E7%AE%80%E4%BB%8B-85d0a52d9e9e',
            name: intl.get('layout.menu.read'),
            className: 'mustread'
        },
        {
            key: '/#plans',
            name: intl.get('layout.menu.sets'),
        },
        {
            key: '/aboutus',
            name: intl.get('layout.menu.aboutUs'),
            className: 'link-aboutus'
        },
        // {
        //     key: '/overview',
        //     name: intl.get('layout.menu.product'),
        //     className: 'link-start'
        // },
        // {
        //     key: '/news',
        //     name: intl.get('layout.menu.news'),
        // },
    ];

    const menuSmallSize = [
        {
            key: '/products#overview',
            name: intl.get('layout.menu.product'),
            className: 'link-start'
        },
        
        {
            key: '/#products',
            name: intl.get('layout.menu.products'),
            className: 'link-products'
        },
        {
            key: '/#tutorials',
            name: intl.get('layout.menu.tutorials'),
            className: 'link-tutorials'
        },

        {
            key: 'https://quantunicorn.medium.com/qu-%E8%B5%84%E9%87%91%E6%B5%81%E4%BF%A1%E5%8F%B7%E7%AE%80%E4%BB%8B-85d0a52d9e9e',
            name: intl.get('layout.menu.read'),
            className: 'mustread'
        },
        {
            key: '/#plans',
            name: intl.get('layout.menu.sets'),
        },
        {
            key: '/aboutus',
            name: intl.get('layout.menu.aboutUs'),
            className: 'link-aboutus'
        },
    ];
    const menuSuperSmallSize = [
        {
            key: '/products',
            name: intl.get('layout.menu.product'),
            className: 'link-start'
        },
        
    ];

    const [menu, setMenu] = useState(menuFullSize);

    const footerMenu = [
        // {
        //     key: '/news',
        //     name: intl.get('layout.menu.news'),
        // },
        {
            key: '/aboutus',
            name: intl.get('layout.menu.aboutUs'),
        },
        {
            key: '/legal',
            name: intl.get('layout.menu.serviceAgreement'),
        },
        {
            key: '/privacy',
            name: intl.get('layout.menu.privacyPolicy'),
        },
    ]

    // 检查是否登录
    const checkSignIn = useCallback(async () => {
        await user.userInfo({ ignoreError: true });
    }, [user])
    useEffect(() => {
        checkSignIn()
    }, [checkSignIn]);

    // overview、products显示主题按钮
    useEffect(() => {
        if (location.pathname === '/overview' || location.pathname === '/products') {
            setShowTheme(true);
        } else {
            setShowTheme(false);
        }
        if (location.pathname === "/" && location.search === "?lang=en") {
            setVisibleBanner(true);
        } else {
            setVisibleBanner(false);
        }

        // on load set theme 
        if (window.localStorage.theme) {
            if (window.localStorage.theme == 'light') {
                document.body.className = 'desktop theme-light'
            } else if (window.localStorage.theme == 'dark') {
                document.body.className = 'desktop theme-dark'
            }
        } else {
            // default to dark
            document.body.className = 'desktop theme-dark'
        }

        // console.log(">>", location.pathname, location)

    }, [location.pathname])

    // 根据url选中默认菜单，并根据默认菜单滚动到指定锚点或顶部
    useEffect(() => {
        if (location.hash) {
            setCurrent([`/${location.hash}`]);
            scrollToAnchor(location.hash.slice(1));
        } else {
            setCurrent([location.pathname]);
            window.scrollTo(0, 0);
        }
    }, [location.hash, location.pathname]);

    // window resize
    useEffect(() => {
        function debounce(func){
            var timer;
            return function(event){
              if(timer) clearTimeout(timer);
              timer = setTimeout(func, 190, event);
            };
        }
        function handleResize() {
            const windowWidth = window.innerWidth;
            if (windowWidth < 1254) {
                setMenu(menuSmallSize);
                
                // // if mobile 
                // if (document.body.classList.contains('mobile')) {
                //     // setMenu(menuSuperSmallSize);
                // } else {
                //     setMenu(menuSmallSize);
                // }
            } 
            else {
                // large screen use fullsize
                setMenu(menuFullSize);
            }

        }
        // only resize on desktop 
        window.addEventListener('resize', debounce(handleResize));
        // if (document.body.classList.contains('desktop')) {
        //     window.addEventListener('resize', debounce(handleResize));
        // }         

    });

    const scrollToAnchor = (anchorName) => {
        if (!anchorName) {
            return;
        }
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
            const distance = anchorElement.offsetTop;
            window.scrollTo({
                top: distance - 100,
                behavior: "smooth"
            });
        }
    }

    // 菜单切换
    const handleMenuClick = value => {
        setCurrent([value.key]);
    };

    // 语言切换
    const handleLanguageChange = () => {
        const target = language === 'zh_CN' ? 'en_US' : 'zh_CN';
        // 发送消息
        // emit.emit('change_language', target);
        window.localStorage.language = target;
        // setLanguage(target);
        //reload page to handle header not able to change
        const currentUrl = window.location.href; 
        if (currentUrl.endsWith("?lang=en")) {
            const newUrl = currentUrl.replace("?lang=en", "")
            window.location.href = newUrl;
        } else {
            window.location.reload();
        }
    }

    // 个人中心
    const gotoUserCenter = async () => {
        // const res = await userCenter();
        // if (res && res.data) {
        //     window.location.href = res.data["redirect"];
        // }
        navigate('account')
    }

    // 退出登录
    const signOut = async () => {
        await user.signOut()
        const pathname = window.location.pathname;
        if (pathname === '/overview' || pathname === '/products') {
            gotoSignIn();
        }
    }

    const userMenu = (
        <Menu>
            <Menu.Item key='profile' onClick={gotoUserCenter}>
                {intl.get("layout.profile")}
            </Menu.Item>
            <Menu.Item key='signOut' onClick={signOut}>
                {intl.get("layout.signOut")}
            </Menu.Item>
        </Menu>
    );

     // 菜单切换
     const handleClickMenuKey = value => {
         if (value) {
             if (value.startsWith('https://')) {
                 window.open(value, '_blank');
             } else {
                navigate(value);
             }
         }
    };

    return (
        <Layout style={{ minHeight: '100vh' }} className={theme}>
            <Header style={{ position: 'fixed', width: '100%', minWidth: 800 }}>
                <Row>
                    <Col span={5} className='logoContainer'>
                        <img className='logo' src={logo} alt='QUANTUNICORN' onClick={() => navigate('/')} ></img>
                    </Col>
                    <Col span={13} className='menuContainer'>
                        <Menu onClick={handleMenuClick} selectedKeys={current} mode="horizontal">
                            {menu.map(v => <Menu.Item className={v.className} key={v.key} onClick={() => handleClickMenuKey(v.key)}>
                                {v.className === 'mustread' ? <Badge.Ribbon placement='start' text='Important' color='red'>{v.name}</Badge.Ribbon> : <span>{v.name}</span> } 
                                
                            </Menu.Item>)}
                        </Menu>
                    </Col>

                    <Col span={6} className='signinContainer'>
                        <div className='rightContent'>
                            <Button
                                className='discord-btn'
                                href="https://discord.gg/xkYSSGj7wq"
                                target="_blank"
                            >
                                <img width="90" alt="Discord" src={iconDiscord}/>
                                {/* <DiscordOutlined style={{ backgroundColor: '#6c4aff', color: '#fff' }}/> */}
                                {/* <DiscordFilled style={{ backgroundColor: '#6c4aff', color: '#fff'}} /> */}
                                {/* Discord */}
                            </Button>

                            {showTheme ?
                                theme === 'dark' ?
                                    <a className='themeChange' onClick={() => { window.localStorage.theme = 'light'; document.body.className = 'desktop theme-light'; setTheme('light') }}> <LightIcon className='theme' /> {intl.get("layout.themeLight")} </a>
                                     :
                                    <a className='themeChange' onClick={() => { window.localStorage.theme = 'dark'; document.body.className = 'desktop theme-dark'; setTheme('dark') }}> <DarkIcon className='theme' /> {intl.get("layout.themeDark")} </a> 
                                : null}
                            <Button type='link' className='language' onClick={handleLanguageChange}>{intl.get("layout.language")}</Button>
                            {user && user.level ?
                                <Dropdown overlay={userMenu}>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href='javascript(void(0))'>
                                        <LevelBlock />
                                        <DownOutlined style={{ marginLeft: 5 }} />
                                    </a>
                                </Dropdown> :
                                <Button
                                    type='primary'
                                    className='sign-in-btn'
                                    onClick={() => { setCurrent([]); gotoSignIn() }}
                                >
                                    {intl.get("layout.signUpSignIn")}
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
                
                {visibleBanner && (
                     <Alert
                        className='banner-en'
                        message="更多功能已在中文版抢先上线，coming soon to English."
                        type="info"
                        showIcon
                        closable
                    />
                )}
            </Header>
            
            <Content>
                {pageOutLet()}
            </Content>
            <Footer>
                <div className='footer-container'>
                    <img className='logo' src={logo} alt='QUANTUNICORN' onClick={() => navigate('/')} ></img>
                    <div className="line"></div>
                    <div>
                        <Button type='link' onClick={() => window.open('https://quantunicorn.medium.com', "_blank")}>最新动态</Button>
                        {footerMenu.map(v => <Button type='link' key={v.key} onClick={() => navigate(v.key)}>
                            {v.name}
                        </Button>)}
                    </div>
                    <div className='text'>All data provided on Quant Unicorn is provided for informational purposes only, and is not intended for trading or investing purposes. Quant Unicorn provides all information as is. You must not redistribute information displayed on or provided by Quant Unicorn.</div>
                </div>
            </Footer>
        </Layout>
    )
})

export default PageLayout;
