import React from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { notification } from 'antd';
import BaseRouters from './router';
import locales from './locales/locales';
import intl from 'react-intl-universal';
import { emit } from './utils/emit';
import { getBrowserLang } from './utils/getBrowserLang';
import { stores } from './stores/createStores';

let defaultLanguage = window.localStorage.language || getBrowserLang();

// TODO: code to enable lang=en for a given url 
// http://35.231.151.49:8888?lang=en
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get("lang");
if (lang === "en") {
    defaultLanguage = "en_US";
} 

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // antd组件国际化
            antdLang: locales[defaultLanguage],
            // 国际化加载
            initDone: false
        }
    }

    componentDidMount() {
        // 监听 lang change 
        emit.on('change_language', lang => this.loadLocales(lang));
        // 初始化语言
        this.loadLocales();

        window.localStorage.language = defaultLanguage;

        // detect mobile or desktop
        var isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);
        if (isMobile) {
            document.body.classList.add('mobile');
        } else {
            document.body.classList.add('desktop');
            
            // trigger resize once
            setTimeout(function() {
                window.dispatchEvent(new Event('resize'));
            }, 10)
        }
    }

    loadLocales(lang = defaultLanguage) {
        // 设置语音
        intl.init({
            currentLocale: lang,
            locales,
        }).then(() => {
            this.setState({
                antdLang: locales[defaultLanguage],
                initDone: true
            });
        });
    }

    componentDidCatch(error, info) {
        notification.error({
            message: 'Some thing was error',
            description: info.componentStack,
            duration: 5,
        })
    }

    render() {
        return (
            this.state.initDone &&
            <BrowserRouter>
                <Provider {...stores}>
                    <BaseRouters />
                </Provider>
            </BrowserRouter>
        )
    }
}

