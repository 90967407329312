import Icon from '@ant-design/icons';

const Product1Svg = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="概览-黑备份" transform="translate(-282.000000, -490.000000)">
                <g id="编组-2" transform="translate(264.000000, 224.000000)">
                    <g transform="translate(18.117074, 266.534832)">
                        <path d="M19.452054,15.1766883 L19.452054,5.10453284 C19.452054,4.55224809 19.0043388,4.10453284 18.452054,4.10453284 L4.72648436,4.10453284 C4.17419961,4.10453284 3.72648436,4.55224809 3.72648436,5.10453284 L3.72648436,15.1766883 C3.72648436,15.728973 4.17419961,16.1766883 4.72648436,16.1766883 L18.452054,16.1766883 C19.0043388,16.1766883 19.452054,15.728973 19.452054,15.1766883 Z" id="路径-2" strokeOpacity="0.85" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></path>
                        <line x1="7.78040985" y1="19.7983349" x2="15.3981285" y2="19.7983349" id="路径" strokeOpacity="0.85" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <polyline id="路径-3" strokeOpacity="0.85" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" points="6.7668911 12.9400774 10.2299728 9.47699567 12.2528841 11.499907 16.4116473 7.34114373"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

const Product2Svg = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.85">
            <g id="概览-黑备份" transform="translate(-751.000000, -490.000000)">
                <g id="编组-5" transform="translate(733.500000, 224.000000)">
                    <g id="编组-2" transform="translate(18.117074, 266.702426)">
                        <polygon id="矩形" stroke="#inherit" strokeLinejoin="round" points="3.72648436 13.8937069 7.72617946 13.8937069 7.72617946 18.3299882 3.72648436 18.3299882"></polygon>
                        <rect id="矩形备份" stroke="#inherit" strokeLinejoin="round" x="9.93578876" y="7.00185014" width="3.9996951" height="9.08453273"></rect>
                        <polygon id="矩形备份-2" stroke="#inherit" strokeLinejoin="round" points="16.1450932 4.64542199 20.1447883 4.64542199 20.1447883 13.3373739 16.1450932 13.3373739"></polygon>
                        <line x1="5.72633191" y1="20.8426235" x2="5.72633191" y2="18.3299882" id="路径备份" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="11.9356363" y1="20.1410558" x2="11.9356363" y2="16.5502643" id="路径备份" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="11.9356363" y1="6.68136201" x2="11.9356363" y2="4.06138432" id="路径" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="5.72633191" y1="13.8937069" x2="5.72633191" y2="11.7453584" id="路径" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="18.1449407" y1="4.64542199" x2="18.1449407" y2="2.00133701" id="路径备份-2" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <line x1="18.1449407" y1="16.3983255" x2="18.1449407" y2="13.7542405" id="路径备份-3" stroke="#inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

const Product3Svg = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="概览-黑备份" transform="translate(-1221.000000, -490.000000)">
                <g id="编组-2备份-3" transform="translate(1203.000000, 224.000000)">
                    <g id="编组-2" transform="translate(18.117074, 266.743310)">
                        <path d="M19.452054,14.8956479 L19.452054,5.0332241 C19.452054,4.48093935 19.0043388,4.0332241 18.452054,4.0332241 L4.72648436,4.0332241 C4.17419961,4.0332241 3.72648436,4.48093935 3.72648436,5.0332241 L3.72648436,14.8956479 C3.72648436,15.4479327 4.17419961,15.8956479 4.72648436,15.8956479 L18.452054,15.8956479 C19.0043388,15.8956479 19.452054,15.4479327 19.452054,14.8956479 Z" id="路径-2" strokeOpacity="0.85" stroke="inherit" strokeLinecap="round" strokeLinejoin="round"></path>
                        <line x1="7.78040985" y1="19.454375" x2="15.3981285" y2="19.454375" id="路径" strokeOpacity="0.85" stroke="inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                        <g id="编组-4" transform="translate(6.277521, 6.427488)" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0.85">
                            <line x1="2.84308691" y1="0.557362382" x2="10.3061311" y2="0.557362382" id="路径-3备份"></line>
                            <line x1="0.482886216" y1="0.557362382" x2="0.865770423" y2="0.557362382" id="路径-3"></line>
                        </g>
                        <g id="编组-4备份" transform="translate(6.277521, 9.417731)" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0.85">
                            <line x1="2.84308691" y1="0.557362382" x2="10.3061311" y2="0.557362382" id="路径-3备份"></line>
                            <line x1="0.482886216" y1="0.557362382" x2="0.865770423" y2="0.557362382" id="路径-3"></line>
                        </g>
                        <g id="编组-4备份-2" transform="translate(6.468963, 12.407975)" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0.85">
                            <line x1="2.65164481" y1="0.557362382" x2="10.114689" y2="0.557362382" id="路径-3备份"></line>
                            <line x1="0.291444113" y1="0.557362382" x2="0.67432832" y2="0.557362382" id="路径-3"></line>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

const Product4Svg = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.85" strokeLinecap="round" strokeLinejoin="round">
            <g id="GEX-未激活" transform="translate(-30.000000, -19.000000)" stroke="#inherit">
                <g id="编组-2" transform="translate(30.000000, 19.000000)">
                    <path d="M19.1414468,13.1115723 L19.1414468,13.1115723 L19.1414468,5.25 C19.1414468,4.69771525 18.6937315,4.25 18.1414468,4.25 L3.85855325,4.25 C3.3062685,4.25 2.85855325,4.69771525 2.85855325,5.25 L2.85855325,15.75 C2.85855325,16.3022847 3.3062685,16.75 3.85855325,16.75 L14.0759277,16.75 L14.0759277,16.75" id="路径"></path>
                    <circle id="椭圆形" cx="18" cy="16.75" r="3.71020508"></circle>
                    <polyline id="路径-3" points="6.00671387 13.3986816 9.5925293 9.81286621 11.6871338 11.9074707 15.9932861 7.60131836"></polyline>
                    <polyline id="路径" points="18 15.1912503 18 16.75 19.4872055 16.75"></polyline>
                </g>
            </g>
        </g>
    </svg>
)

const Product5Svg = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="概览-黑备份" transform="translate(-282.000000, -880.000000)">
                <g id="编组-2备份-2" transform="translate(264.000000, 612.000000)">
                    <g id="编组-2" transform="translate(18.117074, 268.370020)">
                        <polygon id="矩形" strokeOpacity="0.85" stroke="inherit" strokeLinejoin="round" points="3.72648436 3.91459986 12.9747377 3.91459986 12.9747377 11.3879269 3.72648436 11.3879269"></polygon>
                        <polygon id="矩形备份-2" strokeOpacity="0.85" stroke="inherit" strokeLinejoin="round" points="15.4172486 3.91459986 19.4636825 3.91459986 19.4636825 11.3879269 15.4172486 11.3879269"></polygon>
                        <polygon id="矩形备份" strokeOpacity="0.85" stroke="inherit" strokeLinejoin="round" points="3.72648436 13.8898093 12.9747377 13.8898093 12.9747377 18.738888 3.72648436 18.738888"></polygon>
                        <polygon id="矩形备份-3" strokeOpacity="0.85" stroke="inherit" strokeLinejoin="round" points="15.4172486 13.8898093 19.4636825 13.8898093 19.4636825 18.738888 15.4172486 18.738888"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

const Product6Svg = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.85" strokeLinecap="round" strokeLinejoin="round">
            <g id="GEX-未激活" transform="translate(-30.000000, -19.000000)" stroke="inherit">
                <g id="编组-2" transform="translate(30.000000, 19.000000)">
                    <path d="M19.1414468,13.1115723 L19.1414468,13.1115723 L19.1414468,5.25 C19.1414468,4.69771525 18.6937315,4.25 18.1414468,4.25 L3.85855325,4.25 C3.3062685,4.25 2.85855325,4.69771525 2.85855325,5.25 L2.85855325,15.75 C2.85855325,16.3022847 3.3062685,16.75 3.85855325,16.75 L14.0759277,16.75 L14.0759277,16.75" id="路径"></path>
                    <circle id="椭圆形" cx="18" cy="16.75" r="3.71020508"></circle>
                    <polyline id="路径-3" points="6.00671387 13.3986816 9.5925293 9.81286621 11.6871338 11.9074707 15.9932861 7.60131836"></polyline>
                    <polyline id="路径" points="18 15.1912503 18 16.75 19.4872055 16.75"></polyline>
                </g>
            </g>
        </g>
    </svg>
)

const UnableSvgIcon = () => (
    <svg width="22px" height="23px" viewBox="0 0 22 23" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.65">
            <g id="概览-黑备份" transform="translate(-1221.000000, -880.000000)">
                <g id="编组-12备份" transform="translate(1203.000000, 612.000000)">
                    <g id="锁定" transform="translate(18.117074, 268.970903)">
                        <rect id="矩形" stroke="inherit" x="4.38551732" y="9.2481004" width="12.9694542" height="9.12141093" rx="1"></rect>
                        <path d="M7.42331186,9.2481004 L7.42331186,6.85918937 C7.42331186,4.95550107 8.96655614,3.41225679 10.8702444,3.41225679 C12.7739327,3.41225679 14.317177,4.95550107 14.317177,6.85918937 L14.317177,9.2481004 L14.317177,9.2481004" id="路径-4" stroke="inherit"></path>
                        <line x1="11.0581169" y1="12.761784" x2="11.0581169" y2="14.8558277" id="路径-5" stroke="inherit" strokeLinecap="round" strokeLinejoin="round"></line>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)


export const Product1SvgIcon = (props) => <Icon component={Product1Svg} {...props}></Icon>
export const Product2SvgIcon = (props) => <Icon component={Product2Svg} {...props}></Icon>
export const Product3SvgIcon = (props) => <Icon component={Product3Svg} {...props}></Icon>
export const Product4SvgIcon = (props) => <Icon component={Product4Svg} {...props}></Icon>
export const Product5SvgIcon = (props) => <Icon component={Product5Svg} {...props}></Icon>
export const Product6SvgIcon = (props) => <Icon component={Product6Svg} {...props}></Icon>
export const ProductUnableSvgIcon = (props) => <Icon component={UnableSvgIcon} {...props}></Icon>