/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import intl from 'react-intl-universal';
import { useNavigate } from "react-router";
import Comments from "./component/comments";
import News from "./component/news";
import Products from './component/products'
import Plans from "./component/plans";
import Tutorials from "./component/tutorials";
import ReactECharts from 'echarts-for-react';

import { useStore } from "../../hooks/useStore";
import { STORE_USER } from "../../stores/createStores";
import { HttpGet } from "../../utils/request";

import perfImg  from '@/asset/component/qualgo-perf.png';

import './index.less';
import { WhatsAppOutlined } from "@ant-design/icons";

const Home = () => {
    const [message, setMessage] = useState('');
    const [list, setList] = useState([]);
    const [returnMap, setReturnMap] = useState();
    const [chartOption, setChartOption] = useState();

    const navigate = useNavigate();
    const user = useStore(STORE_USER);

    const datatableColumns = [
        {
            title: 'Date',
            dataIndex: 'opened',
            className:'opened'            
        },        
        {
            title: 'Ticker',
            dataIndex: 'ticker',            
            className:'ticker'
        },
        {
            title: 'Long/Short',
            dataIndex: 'longshort',            
            className:'longshort',            
        },
        // {
        //     title: 'Count',
        //     dataIndex: 'count',            
        //     className:'count'
        // },
        {
            title: 'Open Price',
            dataIndex: 'buyprice',            
            className:'buyprice'
        },
        {
            title: 'Close Price',
            dataIndex: 'sellprice',            
            className:'sellprice'
        },
        {
            title: 'Close Date',
            dataIndex: 'closed',            
            className:'closed'
        },
        {
            title: 'Profit/Loss %',
            dataIndex: 'percent',            
            className:'percent'
        },
    ]
    
    const chartStyle = {
        width: 1200,
        height: 500        
    }

    const gotoOverview = async () => {
        await user.userInfo();
        navigate('/products#overview');
    }

    const getMFengineOrdersData = (ticker, date) => {
        return HttpGet(`/api/mfengineorders`);
    }

    const getAlgoDataPlot = (topn=1) => {
        // topn =1,2,3
        let url = `/api/mfengineplot_public?topn=${topn}`;
        return HttpGet(url);
    }

    const getMonthReturn = (end, start, current, obj) => {
        // if both data exist = pass this month return end - start
        if (obj[end] && obj[start]) {
            return obj[end] - obj[start]
        } else {
            if (obj[start]) {
                // still in current month 
                return obj[current] - obj[start]
            } else {
                return 0
            }
        }
    }

    useEffect(async () => {
        // get data for qu algo latest trade
        const res = await getMFengineOrdersData();    
        if (res && res.data && res.data.data && res.data.data.orders) {
            let orderList = res.data.data.orders;
            // console.log(">>orderList", orderList)
            orderList.forEach(item => {
                if (item.gain) {
                    item.gain = item.gain.toFixed(2);
                }
                if (item.buyprice >= 0) {
                    item.longshort = "long"
                } else {
                    item.longshort = "short"
                }
                
            })
            setList(orderList);
        } else {
            setList([]);
            setMessage('');
        }

        // get return data 
        const resReturn = await getAlgoDataPlot();
        if (resReturn && resReturn.data && resReturn.data.data) {
            const chartData = resReturn.data.data;
            // convert array to {date: return%}
            const obj = {}; //return obj
            chartData.forEach( item => {
                // let value = item.normalizedMFprofit * 100;
                let value = item.pv * 100;
                obj[item.date] = value.toFixed(1);
            });
           
            const lastestData = chartData.slice(-1);
            const lastestPV = lastestData[0]["pv"];
            const lastestDate = lastestData[0]["date"];

            // console.log(">>chartData", chartData)            
            // console.log(">>obj", lastestDate, lastestDate > "2024-01-31");

            var qualgoMonthReturn = {
                2020: [
                    (obj["2020-12-31"] - obj["2020-01-15"]).toFixed(1),
                    obj["2020-01-31"]-obj["2020-01-15"],obj["2020-02-28"]-obj["2020-01-31"],obj["2020-03-31"]-obj["2020-02-28"],obj["2020-04-30"]-obj["2020-03-31"],obj["2020-05-29"]-obj["2020-04-30"],
                    obj["2020-06-30"]-obj["2020-05-29"],obj["2020-07-31"]-obj["2020-06-30"],obj["2020-08-28"]-obj["2020-07-31"],obj["2020-09-30"]-obj["2020-08-28"],obj["2020-10-30"]-obj["2020-09-30"],
                    obj["2020-11-30"]-obj["2020-10-30"],obj["2020-12-31"]-obj["2020-11-30"]
                ],
                2021: [
                    // 41.8,
                    (obj["2021-12-31"] - obj["2020-12-31"]).toFixed(1),
                    obj["2021-01-29"]-obj["2020-12-31"],obj["2021-02-26"]-obj["2021-01-29"],obj["2021-03-31"]-obj["2021-02-26"],obj["2021-04-30"]-obj["2021-03-31"],obj["2021-05-28"]-obj["2021-04-30"],
                    obj["2021-06-30"]-obj["2021-05-28"],obj["2021-07-30"]-obj["2021-06-30"],obj["2021-08-31"]-obj["2021-07-30"],obj["2021-09-30"]-obj["2021-08-31"],obj["2021-10-29"]-obj["2021-09-30"],
                    obj["2021-11-30"]-obj["2021-10-29"],obj["2021-12-31"]-obj["2021-11-30"]
                ],
                2022: [
                    // 61.3,
                    (obj["2022-12-30"] - obj["2021-12-31"]).toFixed(1),
                    obj["2022-01-31"]-obj["2021-12-31"],obj["2022-02-28"]-obj["2022-01-31"],obj["2022-03-31"]-obj["2022-02-28"],obj["2022-04-29"]-obj["2022-03-31"],obj["2022-05-31"]-obj["2022-04-29"],
                    obj["2022-06-30"]-obj["2022-05-31"],obj["2022-07-29"]-obj["2022-06-30"],obj["2022-08-31"]-obj["2022-07-29"],obj["2022-09-30"]-obj["2022-08-31"],obj["2022-10-31"]-obj["2022-09-30"],
                    obj["2022-11-30"]-obj["2022-10-31"],obj["2022-12-30"]-obj["2022-11-30"]
                ],
                2023: [
                    (obj["2023-12-29"] - obj["2022-12-30"]).toFixed(1), // YTD 13.5%
                    obj["2023-01-31"]-obj["2022-12-30"],obj["2023-02-28"]-obj["2023-01-31"],obj["2023-03-31"]-obj["2023-02-28"],obj["2023-04-28"]-obj["2023-03-31"],obj["2023-05-31"]-obj["2023-04-28"],
                    obj["2023-06-30"]-obj["2023-05-31"],obj["2023-07-31"]-obj["2023-06-30"],obj["2023-08-31"]-obj["2023-07-31"],obj["2023-09-29"]-obj["2023-08-31"], obj["2023-10-31"]-obj["2023-09-29"],obj["2023-11-30"]-obj["2023-10-31"],obj["2023-12-29"]-obj["2023-11-30"],
                ],
                2024: [
                    (lastestPV *100 - obj["2023-12-29"]).toFixed(1),
                    getMonthReturn("2024-01-31", "2023-12-29", lastestDate, obj),
                    getMonthReturn("2024-02-29", "2024-01-31", lastestDate, obj),
                    getMonthReturn("2024-03-28", "2024-02-29", lastestDate, obj),
                    getMonthReturn("2024-04-30", "2024-03-28", lastestDate, obj),
                    getMonthReturn("2024-05-31", "2024-04-30", lastestDate, obj),
                    getMonthReturn("2024-06-28", "2024-05-31", lastestDate, obj),
                    getMonthReturn("2024-07-31", "2024-06-28", lastestDate, obj),
                    getMonthReturn("2024-08-30", "2024-07-31", lastestDate, obj),
                    getMonthReturn("2024-09-30", "2024-08-30", lastestDate, obj),
                    getMonthReturn("2024-10-31", "2024-09-30", lastestDate, obj),
                    getMonthReturn("2024-11-29", "2024-10-31", lastestDate, obj),
                    getMonthReturn("2024-12-31", "2024-11-29", lastestDate, obj),
                ]
            }
            // console.log(">>qualgoMonthReturn", obj["2022-12-30"], qualgoMonthReturn, obj)
            // function give a month 1/31 12/29 & current month => if > 1/31 and both data exit return %; if same month return latest 1/4; if both date not exist Feb, then return 0             

            setReturnMap(qualgoMonthReturn);
            
            // TODO: montly return = {2022: [-3.1, 17.7]}
            const dateList = chartData.map( value => value.date);
            const presentValue = chartData.map( value => value.pv*100); // qu algo return
            const spyReturn = chartData.map( value => value.SPY*100); // spy return
            const qqqReturn = chartData.map( value => value.QQQ*100); // qqq return
            // convert array to object key for easy access object["2023-05-10"]
            // let longShortMap = []
            // chartData.forEach((item) => {
            //     longShortMap[item.date] = {long: item.long, short: item.short}
            // });
            const option = {
                title: {
                    text: "Cumulative Return",
                    left: 'center',
                    textStyle:{
                        color: "white",
                        fontSize: 28
                    }
                },
                grid: {
                    // backgroundColor: 'black',
                    left: "5%",
                    top: "15%"
                },  
                xAxis: {
                    type: 'category',
                    axisLine: {
                    lineStyle: {
                        color: 'gray',
                        opacity: 0.5,
                        width: 1,
                    },
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#99989D',
                        },
                    },
                    data: dateList
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { lineStyle: { color: '#777' } },
                    axisLabel: {
                    inside: false,
                    formatter: '{value} %'
                    },
                },
                series: [
                    {
                        name: 'QU algo',
                        data: presentValue,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        lineStyle: {
                            color: 'red',
                            // color: 'rgba(108, 74, 255, 1)',
                            width: 3,
                        },
                    },
                    {
                        name: 'SPY',
                        data: spyReturn,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        lineStyle: {
                            color: 'gray',
                            // color: 'rgba(197, 171, 153, 1)',
                            width: 3,
                        },
                    },
                    {
                        name: 'QQQ',
                        data: qqqReturn,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        lineStyle: {
                            color: 'orange',
                            // color: 'rgba(255,127,80, 1)',
                            width: 3,
                        },
                    }
                ],
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //         type: 'cross'
                //     },
                //     backgroundColor: 'rgba(0, 0, 0, 0.6)',
                //     borderWidth: '0',
                //     textStyle: {
                //         color: 'rgba(255, 255, 255, 0.85)',
                //     },
                //     shadowColor: 'rgba(0, 0, 0, 0.4)',
                //     formatter(params) {
                //         // console.log(">>", params)
                //         const date = params[0].name;
                //         const seriesName = params[0].seriesName;
                //         let result = "";
                //         if (params[0].data) {
                //             // present value
                //             result +=  seriesName + " " +parseInt(params[0].data) + "%";
                //         }
                //         if (date && longShortMap[date]) {
                //             if (longShortMap[date]["long"]) {
                //                 result += "<br/>Long: " + longShortMap[date]["long"].join(",")
                //             }
                //             if (longShortMap[date]["short"]) {
                //                 result += "<br/>Short: " + longShortMap[date]["short"].join(",")
                //             }
                //         }
                //         return result;
                //     }
                // },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: [0, 1, 2],
                        realtime: false,
                        start: 0,
                        end: 100,
                    //   top: 80,
                    //   bottom: 0,
                        height: 24,
                        handleIcon:
                        'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                        handleSize: '120%'
                    },
                    {
                        type: 'inside',
                        xAxisIndex: [0, 1, 2],
                        start: 40,
                        end: 70,
                    //   top: 30,
                        height: 24
                    }
                ],
                axisPointer: {
                    link: [
                    {
                        xAxisIndex: 'all'
                    }
                    ],
                    label: {
                        backgroundColor: '#777'
                    }
                },
                color: ['rgba(108, 74, 255, 1)', 'rgba(197, 171, 153, 1)'],       
                legend: {
                    itemHeight: 3,
                    itemWidth: 10,
                    icon: 'plain',
                    // orient: 'vertical',
                    left: 15,                        
                    top: 30,
                    bottom: 15,
                    textStyle: { color: '#99989D', fontSize: 12 },
                    // itemStyle: {
                    //     color: "orange"
                    // }
                    data:[
                        {name: 'QU algo', itemStyle: {color: "red"}},
                        {name: "SPY", itemStyle: {color: "grey"}},
                        {name: "QQQ", itemStyle: {color: "orange"}},
                    ]
                },
            };
            setChartOption(option);            
        }
        
    }, []);

    return (
        <div className='home'>
            <div className='banner'>
                <div className='container'>
                    <div className='title'>{intl.getHTML('home.banner.title')}</div>
                    <div className='sub-title'>{intl.get('home.banner.subTitle')}</div>
                    <Button className='button' onClick={gotoOverview}>
                        {user && user.level ? intl.get('layout.menu.product') : intl.get('home.banner.button') }
                    </Button>
                </div>
            </div>

            <div className='container content'>
                <News />

                <div className='products-block' id='products'>
                    <div className='title'>{intl.get('home.products.title')}</div>
                    <Products />
                </div>

                <div className='tutorials-block' id='tutorials'>
                    <div className='title'>{intl.get('home.tutorials.title')}</div>
                    <Tutorials />
                </div>

                <div className="container" id='qualgo'>
                    <div className="title">QU Algo</div>
                    {returnMap ?
                        <div className="sub-title">Performance: YTD={returnMap[2024][0]}%</div>
                    : ""}    
                    {/* <img className="img-perf" src={perfImg} alt="QU algo perf" width="1200"></img> */}
                    {chartOption ?
                        <ReactECharts
                            option={chartOption}                            
                            style={chartStyle}                    
                        />
                        : ""}
                    {returnMap ?
                        <div className="return-section">
                            <h2>Monthly returns</h2>
                            <table className="ant-table return-table">
                                <tr>
                                    <th> </th> <th>Jan</th><th>Feb</th><th>Mar</th><th>Apr</th><th>May</th><th>Jun</th><th>Jul</th><th>Aug</th><th>Sep</th><th>Oct</th><th>Nov</th><th>Dec</th><th>Year</th>
                                </tr>
                                <tr>
                                    <td>2020</td>
                                    {returnMap[2020].map((value, index) =>{
                                        if (index > 0) {
                                            return <td>{value.toFixed(1)}%</td>
                                        }
                                    })}
                                    <td>{returnMap[2020][0]}%</td>
                                </tr>
                                <tr>
                                    <td>2021</td>
                                    {returnMap[2021].map((value, index) =>{
                                        if (index > 0) {
                                            return <td>{value.toFixed(1)}%</td>
                                        }
                                    })}
                                    <td>{returnMap[2021][0]}%</td>
                                </tr>
                                <tr>
                                    <td>2022</td>
                                    {returnMap[2022].map((value, index) =>{
                                        if (index > 0) {
                                            return <td>{value.toFixed(1)}%</td>
                                        }
                                    })}
                                    <td>{returnMap[2022][0]}%</td>
                                </tr>
                                <tr>
                                    <td>2023</td>
                                    {returnMap[2023].map((value, index) =>{
                                        if (index > 0) {
                                            if (value != 0) {
                                                return <td>{value.toFixed(1)}%</td>
                                            } else {
                                                return <td></td>
                                            }
                                        }
                                    })}
                                    <td>{returnMap[2023][0]}%</td>
                                </tr>
                                <tr>
                                    <td>2024</td>
                                    {returnMap[2024].map((value, index) =>{
                                        if (index > 0) {
                                            if (value != 0) {
                                                return <td>{value.toFixed(1)}%</td>
                                            } else {
                                                return <td></td>
                                            }
                                        }
                                    })}
                                    <td>{returnMap[2024][0]}%</td>
                                </tr>

                            </table>
                        </div> 
                    : ""}                  

                    {list.length ?
                        <Table className="quOrders" title={()=> "QU Algo Latest Orders"} size="small" dataSource={list} columns={datatableColumns} pagination={false}/>
                        : ""}                                        

                </div> 
            </div>

            <div className='sets-block' id='plans'>
                <div className='title'>{intl.get('home.sets.title')}</div>
                <Plans />
            </div>
        </div>
    )
}

export default Home