import { Button, DatePicker, Row, Col, Table, Input } from "antd";
import React, { useState } from "react";
import Empty from '../empty'
import moment from 'moment';
import { qu100Data } from "@/service/qu100";
import ReactECharts from 'echarts-for-react';
import defaultOption from "./option";
import formatCharList from "@/utils/formatCharList";
import { useNavigate } from "react-router";
import intl from 'react-intl-universal';
import './index.less';

const Qu100 = ({ NProgress }) => {
    const navigator = useNavigate();
    // frequency
    const [frequency, setFrequency] = useState('daily');
    // date
    const [date, setDate] = useState(window.SERVER_DATA ? moment(window.SERVER_DATA['MF_LAST_DAY']):moment());
    // top
    const [top, setTop] = useState(true);
    // 返回list数据
    const [list, setList] = useState([]);
    // chart数据
    const [chartList, setChartList] = useState([]);
    // 返回信息
    const [message, setMessage] = useState('');
    // list搜索信息
    const [selectList, setSelectList] = useState([]);

    const generateColumn = (frequency) => {
        let changeTitle = ''
        if (frequency === 'daily') {
            changeTitle = intl.get('products.product5.dailyChange');
        } else if (frequency === 'weekly') {
            changeTitle = intl.get('products.product5.weeklyChange');
        }
                
        const columns = [
            {
                title: intl.get('products.rank'),
                dataIndex: 'rank',
                width: '5%',
                sorter: (a, b) => a.rank - b.rank,
            },
            {
                title: intl.get('products.ticker'),
                dataIndex: 'ticker',
                width: '20%',
                render: (ticker) => {
                    return <div className='purple' onClick={() => { 
                        window.open(`/products#capitalflow?ticker=${ticker}&date=${date.format('YYYY-MM-DD')}`, '_blank')
                         }}>{ticker}</div>;
                }
            },
            {
                title: changeTitle,
                dataIndex: 'change',
                width: '20%',
                render: (change) => {
                    if (parseInt(change) < 0) {
                        return <>
                            <span className='green'>&#9650;</span>
                            <span>{Math.abs(change)}</span>
                        </>
                    } else if (parseInt(change) > 0) {
                        return <>
                            <span className='red'>&#9660;</span>
                            <span>{change}</span>
                        </>;
                    } else {
                        return change;
                    }
    
                },
            },
            {
                title: intl.get('products.product5.sector'),
                dataIndex: 'sector',
                width: '20%',
            },
            {
                title: intl.get('products.product5.industry'),
                dataIndex: 'industry',
                width: '20%',
            },
            {
                title: intl.get('products.product5.long_short'),
                dataIndex: 'long_short',
                width: '15%',
            }
        ];
        return columns;
    
    }
    // 查询
    const search = async () => {
        if (frequency && date) {
            if (NProgress.isStarted()) {
                NProgress.remove();
            }

            NProgress.start();
            const res = await qu100Data(date.format('YYYY-MM-DD'), top, frequency);
            if (res && res.data && res.data.data) {
                setList(res.data.data);
                setSelectList(res.data.data)
                // 处理chart数据
                setChartList(formatCharList(res.data.data));
            } else {
                setList([]);
                setSelectList([])
                setChartList([]);
            }
            if (res && res.data && res.data.message) setMessage(res.data.message);
        } else {
            setList([]);
            setSelectList([])
            setChartList([]);
            setMessage([])
        }
        NProgress.done();
    };

    // 搜索
    const handleInputChange = (target) => {
        let newList = list;
        if (target && list) {
            newList = list.filter(v => v.ticker?.indexOf(target.toLocaleUpperCase()) > -1 || v.sector?.indexOf(target) > -1 || v.industry?.indexOf(target) > -1)
        }
        setSelectList(newList)
    }

    return (
        <div className='qu100'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={7}>{intl.get('products.product5.title')}</Col>
                        <Col className='form-content' span={17}>
                            <div className='content-item'>
                                <span className='label'>{intl.get('products.date')}</span>
                                <DatePicker
                                    allowClear={false}
                                    format='YYYY-MM-DD'
                                    defaultValue={date}
                                    onChange={date => setDate(date)}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                ></DatePicker>
                            </div>

                            <div className='select-button'>
                                <span className={frequency === 'daily' ? 'active' : ''} onClick={() => { setFrequency('daily'); } }>
                                    {intl.get('products.daily')}</span>
                                <span className={frequency === 'weekly' ? 'active' : ''} onClick={() => {setFrequency('weekly'); }}>
                                    {intl.get('products.weekly')}</span>
                            </div>

                            <div className='select-button'>
                                <span className={top ? 'active' : ''} onClick={() => setTop(true)}>{intl.get('products.top100')}</span>
                                <span className={top ? '' : 'active'} onClick={() => setTop(false)}>{intl.get('products.bottom100')}</span>
                            </div>


                            <Button className='button' onClick={search}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://quantunicorn.medium.com/qu100-%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-734d80b697b2' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product5.subTitle')}</Col>
                    </Row>
                </Col>
            </div>

            {list && list.length ?
                <Row className='table' gutter={[16, 16]}>
                    <Col span={24} className='update-time'>{message}</Col>
                    <Col span={24} className='chart-treemap'>
                        <ReactECharts option={{ ...defaultOption, series: [{ ...defaultOption.series[0], data: chartList }] }} />
                    </Col>
                    <Col span={24}>
                        <div className='table-item'>
                            <div className='table-header'>
                                <div className='table-input'>
                                    <span>{intl.get('products.product5.globalSearch')}</span>
                                    <Input placeholder={`${intl.get('products.ticker')}/${intl.get('products.product5.sector')}/${intl.get('products.product5.industry')}`} onChange={e => handleInputChange(e.target.value)} />
                                </div>
                            </div>
                            <Table rowKey='rank' size="small" columns={generateColumn(frequency)} dataSource={selectList} pagination={false} />
                        </div>
                    </Col>
                </Row> : <Empty message={message} />
            }
        </div>
    )
}

export default Qu100;