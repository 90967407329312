import { Suspense } from "react"
import { Route, Routes } from "react-router"
import SignInLayout from "../layout/signInLayout"
import PageLayout from "../layout/pageLayout"
import NotFound from "../page/exception/404"
import Forgot from "../page/forgot"
import Home from "../page/home"
import SignIn from "../page/signIn"
import SignUp from "../page/signUp"
import Reset from "../page/reset"
import { Config } from "./config"

const BaseRouters = () => {    
    return (
        <Routes>
            <Route path='/signin' exact element={<SignInLayout />}>
                <Route index element={<SignIn />}></Route>
            </Route> 

            <Route path='/signup' exact element={<SignInLayout />}>
                <Route index element={<SignUp />}></Route>
            </Route> 

            <Route path='/forgot' exact element={<SignInLayout />}>
                <Route index element={<Forgot />}></Route>
            </Route> 

            <Route path='/reset' exact element={<SignInLayout />}>
                <Route index element={<Reset />}></Route>
            </Route>

            <Route path='/' element={<PageLayout />}>
                <Route index element={<Home />}></Route>
                {Config.map(v => <Route key={v.title} path={v.path} element={
                    <Suspense fallback={<>...</>}>
                        {v.element}
                    </Suspense>
                }></Route>)}
            </Route>           

            <Route path="*" element={<PageLayout />}>
                <Route index element={<NotFound />}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Route>
        </Routes>
    )
}

export default BaseRouters;