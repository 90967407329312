import Icon from '@ant-design/icons';

const LightSvg = () => (
    <svg width="25px" height="24px" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="导航-已登录-后台日间" transform="translate(-1505.000000, -31.000000)">
                <g id="编组-4" transform="translate(1505.080594, 31.000000)">
                    <rect id="矩形" x="0" y="0" width="24" height="24" rx="2"></rect>
                    <g id="系统" transform="translate(3.413833, 2.467285)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0.85" strokeWidth="1.3">
                        <g id="晴（夜间）">
                            <path d="M8.50557322,15.0517578 C11.5536565,15.0517578 14.0246162,12.5807981 14.0246162,9.53271484 C14.0246162,6.48463158 11.5536565,4.01367188 8.50557322,4.01367188 C5.45748996,4.01367188 2.98653025,6.48463158 2.98653025,9.53271484 C2.98653025,12.5807981 5.45748996,15.0517578 8.50557322,15.0517578 Z" id="椭圆形"></path>
                            <line x1="8.50557322" y1="0" x2="8.50557322" y2="1.73291016" id="路径-16"></line>
                            <line x1="16.0107743" y1="4.33312988" x2="16.0107743" y2="6.06604004" id="路径-16" transform="translate(16.010774, 5.199585) rotate(60.000000) translate(-16.010774, -5.199585) "></line>
                            <line x1="16.0107743" y1="12.9993896" x2="16.0107743" y2="14.7322998" id="路径-16" transform="translate(16.010774, 13.865845) rotate(120.000000) translate(-16.010774, -13.865845) "></line>
                            <line x1="8.50557322" y1="17.3325195" x2="8.50557322" y2="19.0654297" id="路径-16" transform="translate(8.505573, 18.198975) rotate(180.000000) translate(-8.505573, -18.198975) "></line>
                            <line x1="1.00037211" y1="12.9993896" x2="1.00037211" y2="14.7322998" id="路径-16" transform="translate(1.000372, 13.865845) rotate(240.000000) translate(-1.000372, -13.865845) "></line>
                            <line x1="1.00037211" y1="4.33312988" x2="1.00037211" y2="6.06604004" id="路径-16" transform="translate(1.000372, 5.199585) rotate(300.000000) translate(-1.000372, -5.199585) "></line>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const LightIcon = (props) => <Icon component={LightSvg} {...props}></Icon>