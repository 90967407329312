import { Button, Row, Col, Table, Input } from "antd";
import React, { useState } from "react";
import { marketHistoryData } from "@/service/marketHistory";
import { getColor } from "@/utils/getColor";
import Empty from '../empty';
import intl from 'react-intl-universal';
import './index.less';

const MarketHistory = ({ NProgress }) => {
    // 返回list数据
    const [list, setList] = useState([]);
    // list搜索信息
    const [selectList, setSelectList] = useState([]);
    // 返回信息
    const [message, setMessage] = useState('');

    const columns = [
        {
            title: intl.get('products.date'),
            dataIndex: 'date',
            width: '20%',
            sorter: (a, b) => a.date > b.date,
        },
        {
            title: 'SPY',
            dataIndex: 'SPY',
            width: '20%',
            render: (text) => getColor(text),
        },
        {
            title: 'QQQ',
            dataIndex: 'QQQ',
            width: '20%',
            render: (text) => getColor(text),
        },
        {
            title: 'IWM',
            dataIndex: 'IWM',
            width: '20%',
            render: (text) => getColor(text),
        },
        {
            title: 'DIA',
            dataIndex: 'DIA',
            width: '20%',
            render: (text) => getColor(text),
        }
    ];

    // 查询
    const search = async () => {
        if (NProgress.isStarted()) {
            NProgress.done();
            NProgress.remove();
        }

        NProgress.start();
        const res = await marketHistoryData();
        if (res && res.data) {
            if (res.data.data) {
                let dataList = []
                for (let key in res.data.data) {
                    if (JSON.stringify(res.data.data[key]) !== "{}") {
                        dataList.push({ date: key, ...res.data.data[key] });
                    }
                }
                setList([...dataList].reverse());
                setSelectList([...dataList].reverse());
            } else {
                setMessage(res.data.message || '');
            }
        } else {
            setList([]);
            setSelectList([]);
            message('');
        }
        NProgress.done();
    }

    // 搜索
    const handleInputChange = (target) => {
        let newList = list;
        if (target && list) {
            newList = list.filter(v => v.date?.indexOf(target) > -1)
        }
        setSelectList(newList)
    }
    return (
        <div className='market-history'>
            <div className='form' id='product-header'>
                <Col className='form-container-otter' >
                    <Row className='form-container'>
                        <Col className='form-title' span={10}>{intl.get('products.product4.title')}</Col>
                        <Col className='form-content' span={14}>
                            <Button className='button' onClick={search}>{intl.get('products.search')}</Button>
                        </Col>
                    </Row>
                    <Row className='form-subTitle'>
                        <Col className='form-subTitle' span={2}>
                            <div >
                                <a href='https://quantunicorn.medium.com/%E5%A4%9A%E6%97%A5%E5%A4%A7%E7%9B%98%E8%B5%84%E9%87%91%E6%B5%81%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97-4487f1af28ae' target='_blank'>{intl.get('products.userGuide')}</a>
                            </div>
                        </Col>
                        <Col >{intl.get('products.product4.subTitle')}</Col>
                    </Row>
                </Col>
            </div>

            {list && list.length ?
                <Row className='table' gutter={16}>
                    <Col span={24}>
                        <div className='table-item'>
                            <div className='table-header'>
                                <div className='table-title'>{intl.get('products.product4.title')}</div>
                                <div className='table-input'>
                                    <span>{intl.get('products.date')}</span>
                                    <Input onChange={e => handleInputChange(e.target.value)} />
                                </div>
                            </div>
                            <Table key='date' size="small" columns={columns} dataSource={selectList} />
                        </div>
                    </Col>
                </Row>
                : <Empty message={message} />
            }
        </div>
    )
}

export default MarketHistory;