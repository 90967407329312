import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router';
import successImg  from '@/asset/component/success.png';
import './success.less';

const Success = ({ text }) => {
    const [countDown, setCountDown] = useState(5);
    const navigate = useNavigate();

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setCountDown(countDown - 1)
    //         if (countDown === 1) {
    //             navigate('/')
    //         }
    //     }, 1000);
    //     // 清除计时器
    //     return () => clearTimeout(timer);
    // });

    return (
        <div className='success'>
            <div className='success-img'>
                <img src={successImg} alt='success'></img>
            </div>

            <div className='text'>
                <div>{text}</div>
                {/* <div>{intl.get('signInLayout.success.text', { sec: countDown, unit: countDown > 1 ? 'seconds.' : 'second.' })}</div> */}
            </div>

            <Button className='back-btn' onClick={() => navigate('/')}>{intl.get('notFound.btnText', { sec: countDown })}</Button>
        </div>
    );

}

export default Success;