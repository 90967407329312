import Icon from '@ant-design/icons';

const OverviewSvg = () => (
    <svg width="24px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>首页</title>
        <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeOpacity="0.85">
            <g id="概览" transform="translate(-33.000000, -22.000000)" stroke="inherit">
                <g id="编组-3" transform="translate(30.000000, 19.000000)">
                    <g id="首页" transform="translate(4.273490, 3.500000)">
                        <path d="M15.574924,7.84855353 L8.43347728,0.707106781 C8.04295299,0.316582489 7.40978801,0.316582489 7.01926372,0.707106781 L0.292893219,7.43347728 C0.10535684,7.62101366 8.55698767e-16,7.87536757 0,8.14058406 L0,16.137207 C6.76353751e-17,16.6894918 0.44771525,17.137207 1,17.137207 L4.86377355,17.137207 C5.4160583,17.137207 5.86377355,16.6894918 5.86377355,16.137207 L5.86377355,13.8192001 C5.86377355,13.2669154 6.3114888,12.8192001 6.86377355,12.8192001 L9.08472803,12.8192001 C9.63701278,12.8192001 10.084728,13.2669154 10.084728,13.8192001 L10.084728,16.137207 C10.084728,16.6894918 10.5324433,17.137207 11.084728,17.137207 L14.8678173,17.137207 C15.420102,17.137207 15.8678173,16.6894918 15.8678173,16.137207 L15.8678173,8.55566031 C15.8678173,8.29044382 15.7624604,8.03608991 15.574924,7.84855353 Z" id="路径-2"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export const OverviewIcon = (props) => <Icon component={OverviewSvg} {...props}></Icon>