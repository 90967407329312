import { HttpGet, HttpPost } from "../utils/request";

export function createCheckoutSession(priceId, referenceId="") {
    return HttpPost('/create-checkout-session', { priceId, referenceId }, { postType: 'json' })
}

export function getPublicKey() {
    return HttpGet('/payments/setup')
}

