// 热力图
const formatCharList = (list) => {
    var DataMap = new Map();
    var data = [];
    var sector = [];
    var tootipTable = '<table> <thead> <tr> <th>Rank</th> <th>Ticker</th> <th>Change</th> <th>Industry</th> </tr> </thead> <tbody>';
    list.forEach((val) => {
        var changTd;
        if (val['change'] < 0) {
            changTd = '<td><span class=\'green\'>&#9650;</span>' + Math.abs(val['change']) + '</td>'
        } else if (val['change'] > 0) {
            changTd = '<td><span class=\'red\'>&#9660;</span>' + Math.abs(val['change']) + '</td>'
        } else {
            changTd = '<td >' + val['change'] + '</td>'
        }
        if (!val['sector']) {
            sector = 'Other';
        } else {
            sector = val['sector'];
        }
        if (!DataMap.has(sector)) {
            DataMap.set(sector, data.length);
            data[DataMap.get(sector)] = {};
            data[DataMap.get(sector)]['name'] = sector;
            data[DataMap.get(sector)]['value'] = 0;
            data[DataMap.get(sector)]['tickers'] = [];
            data[DataMap.get(sector)]['tickers'].push(tootipTable);
        }
        data[DataMap.get(sector)]['value'] += 1;
        data[DataMap.get(sector)]['tickers'].push('<tr><td>' + val['rank'] + '</td><td>' + val['ticker'] + '</td>' + changTd + '<td>' + (val['industry'] || '--') + '</td></tr>');
    });
    return data;
}

export default formatCharList;